.section {
    top: 0;
    font-family: monospace !important;
    font-size: 18px;
    line-height: 1.7;
    margin-bottom: 5%;
    text-align:justify;
    position: absolute;
}
.card-body {
    padding-right: 5%;
    padding-left: 5%;
}
.card-header {
    padding-right: 5%;
    padding-left: 5%;
}

.title {
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 10%;
    padding-left: 10%;
}

.profile {
    width: 70px;
    float: left
}

.profile img {
    display: block;
}

.meta {
    margin-left: 83px;
    margin-top: 2%;
    padding-top: 1%;
}
.icon {
    padding-right: 15px;
}
.requirements {
    padding-top: 2%;
}