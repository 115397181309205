body {
    margin-top:20px;
}
.text {
    font-family: monospace !important;
    font-size: 1.3em !important;
    line-height: 1.7 !important;
}

.btn.btn-outline-primary:hover, .bg-primary {
    background-color: #E0E0E0 !important;
}
.section-medium {
    padding: 25px 0px;
    background-color: #E0E0E0 !important;
}
.section-arrow-primary-color.section-arrow--bottom-center:after {
    background-color: #E0E0E0;
    border-right-color: #E0E0E0;
    border-bottom-color: #E0E0E0;
}
.section-arrow--bottom-center:after {
    z-index: 1;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    content: "";
    position: absolute;
    bottom: -15px;
    width: 30px;
    height: 30px;
    border-right: 1px solid #262626;
    border-bottom: 1px solid #262626;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #262626;
}

.section-title {
    color: black;
    font-family: monospace;
    letter-spacing: -0.02em;
}

.section-arrow-primary-color.section-arrow--bottom-center:after {
    border-bottom-color: #E0E0E0;
}
.section-arrow-primary-color.section-arrow--bottom-center:after{
    background-color: #E0E0E0;
}
.special-heading.line span:before, .special-heading.line span:after, .footer.footer-minimal, .t-bordered {
    border-top-color: #eaeaea;
}
.t-bordered {
    border-top: 1px solid #eaeaea;
}
.section-primary {
    padding: 75px 0px;
}
section {
    position: relative;
}

.testimonial-three .testimonial-image {
    float: left;
    margin: 0 20px 0 0;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    overflow: hidden;
}

.testimonial-three .testimonial-content {
    overflow: hidden;
}

.testimonial-three .testimonial-meta {
    position: relative;
    overflow: hidden;
    margin-left: 100px;
    margin-top: 5%;
}


.testimonial-three .testimonial-name {
    display: block;
}


.testimonial-three .testimonial-three-col {
    padding-bottom: 35px;
}

.testimonial-three .testimonial-three-col {
    border-right-style: dashed;
    border-right-width: 1px;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    border-right-color: #ddd;
    border-bottom-color: #ddd;
    padding: 25px;
}

.testimonial-three .testimonial-three-col:hover {
   background-color:#f7f7f7;
}

.alternate-color .testimonial-three .testimonial-three-col:hover {
   background-color:white;
}

.testimonial-three.testimonial-three--col-two .testimonial-three-col:nth-child(2n) {
    border-right: none;
}

.testimonial-three.testimonial-three--col-two .testimonial-three-col:nth-last-child(-n+2),
.testimonial-three.testimonial-three--col-two .testimonial-three-col:last-child {
    border-bottom: none;
}

.testimonial-three.testimonial-three--col-three .testimonial-three-col:nth-child(3n) {
    border-right: none;
}

.testimonial-three.testimonial-three--col-three .testimonial-three-col:nth-last-child(-n+3),
.testimonial-three.testimonial-three--col-three .testimonial-three-col:nth-last-child(-n+2),
.testimonial-three.testimonial-three--col-three .testimonial-three-col:last-child {
    border-bottom: none;
}

img {
    max-width: 100%;
    height: auto;
}
.styleBtn{
    color: blue !important;
    border: none !important;
    background-color: transparent !important;
    font-family: monospace;
    font-size: 1.3em !important;
    line-height: 1.7 !important;
    
}

