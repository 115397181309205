.intro {
    font-family: monospace;
    font-size: 18px;
    line-height: 1.7;
    background: url("../assets/images/whiteVector.png") no-repeat center;
    background-size: cover;
    background-color: white;
    min-height: 100%;   
}

.introcard {
    margin: auto;
    width: 60%;
    padding: 10px;
    margin-top: 5%;

}
.tech {
    font-family: monospace;
    font-size: 18px;
    line-height: 1.7;
    margin-top: 2%;
}
.point {
    color:transparent
}

.with-margin {
    margin-bottom: 20px;
}

.education {
    margin-top: 0px;
    font-family: monospace;
    font-size: 18px;
    line-height: 1.7;
    width:100%;
}

.edHead {
    width: 92%;
    z-index: 1;
    margin-left: 4%;
}
.edBinary {
    width: 100%;
    z-index: 2;
    background: url("../assets/images/binary.png") no-repeat center;
    background-size: cover;
    background-color: white;
    min-height: 100%;
}

.techHeader {
    margin-top: 2%;
}

.table-headers {
    background-color: #73a3f0;
}

.table-rows {
    background-color: #EEF2FE;
}
.download {
    margin-left: 48%;
}
.downloadText {
    text-align: center;
}
.card {
    padding-bottom: 50px;
}

.bullet_rows_ed_tech{
    margin-right: 4%;
    margin-left: 0;
    padding-left: 1%;
}
.date_row_tech_ed{
    font-size: 12px;
}

