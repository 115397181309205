.wrap {
    font-family: monospace;
    font-size: 18px;
    line-height: 1.7;
    margin-bottom: 10%;
}
img {
    cursor: pointer;
}
.zoom {
    transition: transform .2s; /* Animation */
    margin-top: 7%;;
    /* margin-bottom: 10%; */
  }
  
  .zoom:hover {
    transform: scale(1.3); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  h6 {
    text-align: center;
  }
  .buttons {
    margin-bottom: 10%;
  }
  .btn {
    font-size: 12px !important; 
  }

  p {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  