
.typist {
    width:50%;
    margin-top: 10%;
    margin-left: 10%;
    z-index: 1;
    font-family: monospace;
    font-size: 1.5em;
    line-height: 1.7;
}


