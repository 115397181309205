
.App {
    font-family: sans-serif;
    text-align: center;
    transform: rotate(180deg) scaleX(-1);
    z-index: 0;
    position: fixed;
    margin-right: 30%;
    margin-left: 2%;
  }
  g {
    will-change: transform;  
  }

  svg {
    width:100vw;
    height:100vh; 
  }
  
  .reverse g {
    will-change: transform; 
  
  }
  .cl-0 {
  
        transform: translate3d(25px, -458px, -89px) rotate3d(-0.4,0.8,-0.4,39deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-0.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-0.reverse {
  
        transform: translate3d(-431px, 267px, -497px) rotate3d(-0.4,0.4,-1.3,-74deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-1 {
  
        transform: translate3d(-172px, -50px, -97px) rotate3d(-1.4,-0.3,0.3,-237deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-1.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-1.reverse {
  
        transform: translate3d(-447px, 0px, -59px) rotate3d(1.3,0,-1.9,-251deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-2 {
  
        transform: translate3d(178px, 438px, -189px) rotate3d(1.6,-0.8,-0.8,-217deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-2.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-2.reverse {
  
        transform: translate3d(346px, -117px, -373px) rotate3d(2,0.4,-1.4,-18deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-3 {
  
        transform: translate3d(-83px, -466px, 342px) rotate3d(-0.3,0.7,1.2,336deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-3.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-3.reverse {
  
        transform: translate3d(-407px, -248px, -158px) rotate3d(-1.7,-0.6,1.5,131deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-4 {
  
        transform: translate3d(-350px, -393px, -184px) rotate3d(1,1.4,0,-319deg);
           transition: transform 117ms ease-in-out;  
  
  }  .cl-4.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-4.reverse {
  
        transform: translate3d(466px, 280px, 328px) rotate3d(-0.1,1.5,0.8,333deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-5 {
  
        transform: translate3d(214px, -461px, 176px) rotate3d(1.7,0,0.5,-21deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-5.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-5.reverse {
  
        transform: translate3d(455px, 310px, 117px) rotate3d(1.2,0,-1.7,-70deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-6 {
  
        transform: translate3d(-166px, 82px, 175px) rotate3d(1.9,1.3,1.2,248deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-6.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 141ms ease-in-out;
  
  }
  .cl-6.reverse {
  
        transform: translate3d(-459px, -185px, -34px) rotate3d(-1.1,1.7,-2,-302deg);
  
           transition: transform 157ms ease-in-out;
  }.cl-7 {
  
        transform: translate3d(184px, -94px, 138px) rotate3d(0.7,-1.4,-0.5,151deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-7.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-7.reverse {
  
        transform: translate3d(-147px, -185px, -37px) rotate3d(1.7,2,-0.1,-148deg);
  
           transition: transform 148ms ease-in-out;
  }.cl-8 {
  
        transform: translate3d(298px, -137px, -29px) rotate3d(1.7,-1.7,-1.3,158deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-8.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-8.reverse {
  
        transform: translate3d(-225px, -417px, 99px) rotate3d(-0.4,0.9,-1.9,187deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-9 {
  
        transform: translate3d(-249px, -228px, 137px) rotate3d(0.6,-0.5,-0.4,309deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-9.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 247ms ease-in-out;
  
  }
  .cl-9.reverse {
  
        transform: translate3d(78px, -466px, -253px) rotate3d(-2,-1.6,-0.1,-152deg);
  
           transition: transform 221ms ease-in-out;
  }.cl-10 {
  
        transform: translate3d(-387px, 217px, 461px) rotate3d(-1.1,-1.4,1.3,289deg);
           transition: transform 252ms ease-in-out;  
  
  }  .cl-10.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 161ms ease-in-out;
  
  }
  .cl-10.reverse {
  
        transform: translate3d(-246px, 302px, -149px) rotate3d(1.5,2,0.2,-120deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-11 {
  
        transform: translate3d(232px, -239px, 461px) rotate3d(-0.5,-1.3,0.6,-161deg);
           transition: transform 112ms ease-in-out;  
  
  }  .cl-11.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-11.reverse {
  
        transform: translate3d(-211px, 217px, 15px) rotate3d(-0.2,0.9,0.7,2deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-12 {
  
        transform: translate3d(77px, -341px, 215px) rotate3d(1.2,-1.8,-1.8,223deg);
           transition: transform 266ms ease-in-out;  
  
  }  .cl-12.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-12.reverse {
  
        transform: translate3d(-68px, 462px, -231px) rotate3d(-0.1,-1.7,0.2,-199deg);
  
           transition: transform 205ms ease-in-out;
  }.cl-13 {
  
        transform: translate3d(-285px, -486px, 273px) rotate3d(-0.5,1.9,0.1,209deg);
           transition: transform 149ms ease-in-out;  
  
  }  .cl-13.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 180ms ease-in-out;
  
  }
  .cl-13.reverse {
  
        transform: translate3d(481px, -267px, 346px) rotate3d(0,-0.9,0.2,101deg);
  
           transition: transform 351ms ease-in-out;
  }.cl-14 {
  
        transform: translate3d(349px, -440px, -291px) rotate3d(1.8,-0.6,-1.8,58deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-14.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 401ms ease-in-out;
  
  }
  .cl-14.reverse {
  
        transform: translate3d(345px, 329px, -87px) rotate3d(-0.8,-0.2,0.2,32deg);
  
           transition: transform 158ms ease-in-out;
  }.cl-15 {
  
        transform: translate3d(-381px, 142px, 396px) rotate3d(-1.5,-1.3,-1.1,146deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-15.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 297ms ease-in-out;
  
  }
  .cl-15.reverse {
  
        transform: translate3d(412px, -301px, 49px) rotate3d(0.1,-0.4,0.1,-338deg);
  
           transition: transform 326ms ease-in-out;
  }.cl-16 {
  
        transform: translate3d(204px, 407px, -182px) rotate3d(-1.4,-1.1,-0.9,270deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-16.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 286ms ease-in-out;
  
  }
  .cl-16.reverse {
  
        transform: translate3d(137px, -35px, -393px) rotate3d(0.8,-2,1.5,277deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-17 {
  
        transform: translate3d(-466px, 63px, 418px) rotate3d(0.4,0.1,1.3,-296deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-17.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 418ms ease-in-out;
  
  }
  .cl-17.reverse {
  
        transform: translate3d(-367px, -440px, -277px) rotate3d(0.1,-0.4,0,-138deg);
  
           transition: transform 168ms ease-in-out;
  }.cl-18 {
  
        transform: translate3d(-368px, 98px, -245px) rotate3d(0.2,0.5,0.5,-275deg);
           transition: transform 284ms ease-in-out;  
  
  }  .cl-18.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 234ms ease-in-out;
  
  }
  .cl-18.reverse {
  
        transform: translate3d(133px, -155px, -434px) rotate3d(-1.6,0.8,-1.4,-161deg);
  
           transition: transform 513ms ease-in-out;
  }.cl-19 {
  
        transform: translate3d(-20px, 20px, 415px) rotate3d(1,1.6,-1.6,308deg);
           transition: transform 562ms ease-in-out;  
  
  }  .cl-19.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 429ms ease-in-out;
  
  }
  .cl-19.reverse {
  
        transform: translate3d(-437px, -80px, 126px) rotate3d(-1.8,0.5,-0.2,-185deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-20 {
  
        transform: translate3d(-149px, 294px, 429px) rotate3d(0.4,1.9,1.1,238deg);
           transition: transform 212ms ease-in-out;  
  
  }  .cl-20.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 266ms ease-in-out;
  
  }
  .cl-20.reverse {
  
        transform: translate3d(-200px, 31px, -130px) rotate3d(-1.3,0.7,-0.1,-129deg);
  
           transition: transform 424ms ease-in-out;
  }.cl-21 {
  
        transform: translate3d(-336px, 488px, -304px) rotate3d(1.1,-0.1,-1.6,-117deg);
           transition: transform 557ms ease-in-out;  
  
  }  .cl-21.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-21.reverse {
  
        transform: translate3d(23px, -340px, 404px) rotate3d(0.8,-1.7,1.1,-279deg);
  
           transition: transform 250ms ease-in-out;
  }.cl-22 {
  
        transform: translate3d(-411px, 464px, 235px) rotate3d(-0.4,-1.1,-0.7,-341deg);
           transition: transform 330ms ease-in-out;  
  
  }  .cl-22.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 371ms ease-in-out;
  
  }
  .cl-22.reverse {
  
        transform: translate3d(-367px, -142px, 369px) rotate3d(-0.4,0.7,0.8,295deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-23 {
  
        transform: translate3d(-111px, 234px, -342px) rotate3d(-1.9,-0.2,-1.4,110deg);
           transition: transform 492ms ease-in-out;  
  
  }  .cl-23.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 242ms ease-in-out;
  
  }
  .cl-23.reverse {
  
        transform: translate3d(-292px, 346px, -172px) rotate3d(1.4,1.6,1.7,147deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-24 {
  
        transform: translate3d(262px, 255px, -15px) rotate3d(-0.5,-1.5,-1.6,-252deg);
           transition: transform 457ms ease-in-out;  
  
  }  .cl-24.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 445ms ease-in-out;
  
  }
  .cl-24.reverse {
  
        transform: translate3d(389px, 401px, -283px) rotate3d(-0.7,-0.2,0.8,-236deg);
  
           transition: transform 664ms ease-in-out;
  }.cl-25 {
  
        transform: translate3d(-151px, -34px, 330px) rotate3d(1.1,1,0.9,98deg);
           transition: transform 510ms ease-in-out;  
  
  }  .cl-25.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 545ms ease-in-out;
  
  }
  .cl-25.reverse {
  
        transform: translate3d(90px, -179px, 484px) rotate3d(-1.4,1.9,-0.5,88deg);
  
           transition: transform 439ms ease-in-out;
  }.cl-26 {
  
        transform: translate3d(-65px, -199px, -167px) rotate3d(1.9,-1.3,0.5,-125deg);
           transition: transform 391ms ease-in-out;  
  
  }  .cl-26.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 488ms ease-in-out;
  
  }
  .cl-26.reverse {
  
        transform: translate3d(-460px, 131px, 374px) rotate3d(-0.4,0,-0.6,-155deg);
  
           transition: transform 517ms ease-in-out;
  }.cl-27 {
  
        transform: translate3d(41px, -85px, -36px) rotate3d(1.4,-0.9,-0.8,94deg);
           transition: transform 374ms ease-in-out;  
  
  }  .cl-27.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 223ms ease-in-out;
  
  }
  .cl-27.reverse {
  
        transform: translate3d(-142px, -450px, -371px) rotate3d(0.3,-1.1,1.8,74deg);
  
           transition: transform 455ms ease-in-out;
  }.cl-28 {
  
        transform: translate3d(-73px, 375px, 63px) rotate3d(-0.1,-0.4,-1.3,-122deg);
           transition: transform 837ms ease-in-out;  
  
  }  .cl-28.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 259ms ease-in-out;
  
  }
  .cl-28.reverse {
  
        transform: translate3d(-470px, -84px, 390px) rotate3d(0.9,0.4,-1.4,-117deg);
  
           transition: transform 620ms ease-in-out;
  }.cl-29 {
  
        transform: translate3d(182px, -58px, -24px) rotate3d(1.5,0.3,-1.2,115deg);
           transition: transform 315ms ease-in-out;  
  
  }  .cl-29.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 690ms ease-in-out;
  
  }
  .cl-29.reverse {
  
        transform: translate3d(394px, -251px, -89px) rotate3d(-1.9,0.1,-1,-183deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-30 {
  
        transform: translate3d(-496px, 101px, 326px) rotate3d(-1.7,-1.9,1.7,-108deg);
           transition: transform 316ms ease-in-out;  
  
  }  .cl-30.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 763ms ease-in-out;
  
  }
  .cl-30.reverse {
  
        transform: translate3d(-158px, -208px, -441px) rotate3d(-0.5,0.4,-1.8,340deg);
  
           transition: transform 452ms ease-in-out;
  }.cl-31 {
  
        transform: translate3d(-416px, 125px, -328px) rotate3d(0.5,1.9,-0.9,-200deg);
           transition: transform 145ms ease-in-out;  
  
  }  .cl-31.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 207ms ease-in-out;
  
  }
  .cl-31.reverse {
  
        transform: translate3d(-446px, -224px, -303px) rotate3d(-1.9,1.1,0.9,38deg);
  
           transition: transform 284ms ease-in-out;
  }.cl-32 {
  
        transform: translate3d(232px, -264px, 469px) rotate3d(-1.6,1.2,0,-9deg);
           transition: transform 264ms ease-in-out;  
  
  }  .cl-32.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 639ms ease-in-out;
  
  }
  .cl-32.reverse {
  
        transform: translate3d(-124px, 318px, 170px) rotate3d(1.9,0.1,-1.9,-54deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-33 {
  
        transform: translate3d(281px, 279px, 15px) rotate3d(0.7,-0.8,-0.7,215deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-33.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 757ms ease-in-out;
  
  }
  .cl-33.reverse {
  
        transform: translate3d(-240px, -214px, -334px) rotate3d(0.2,1.3,1.5,298deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-34 {
  
        transform: translate3d(-200px, 59px, 362px) rotate3d(-0.3,-1.9,1.3,73deg);
           transition: transform 589ms ease-in-out;  
  
  }  .cl-34.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 843ms ease-in-out;
  
  }
  .cl-34.reverse {
  
        transform: translate3d(396px, -366px, -245px) rotate3d(-0.7,0.6,1.4,-41deg);
  
           transition: transform 463ms ease-in-out;
  }.cl-35 {
  
        transform: translate3d(282px, 88px, 239px) rotate3d(-0.2,-1.9,-1.4,153deg);
           transition: transform 795ms ease-in-out;  
  
  }  .cl-35.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 214ms ease-in-out;
  
  }
  .cl-35.reverse {
  
        transform: translate3d(36px, 239px, 283px) rotate3d(1.3,-1.2,0,-49deg);
  
           transition: transform 564ms ease-in-out;
  }.cl-36 {
  
        transform: translate3d(277px, -192px, -60px) rotate3d(0.9,0.2,-1.1,-127deg);
           transition: transform 441ms ease-in-out;  
  
  }  .cl-36.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 583ms ease-in-out;
  
  }
  .cl-36.reverse {
  
        transform: translate3d(31px, -52px, 273px) rotate3d(0.3,-1.9,-0.8,-2deg);
  
           transition: transform 531ms ease-in-out;
  }.cl-37 {
  
        transform: translate3d(-11px, -442px, 393px) rotate3d(-1.1,0.3,-0.4,138deg);
           transition: transform 654ms ease-in-out;  
  
  }  .cl-37.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1064ms ease-in-out;
  
  }
  .cl-37.reverse {
  
        transform: translate3d(-160px, -194px, -401px) rotate3d(1.6,1.4,-1.1,137deg);
  
           transition: transform 233ms ease-in-out;
  }.cl-38 {
  
        transform: translate3d(194px, -176px, 360px) rotate3d(-0.1,-0.8,1.6,-2deg);
           transition: transform 242ms ease-in-out;  
  
  }  .cl-38.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 858ms ease-in-out;
  
  }
  .cl-38.reverse {
  
        transform: translate3d(485px, 199px, 93px) rotate3d(0.9,1.8,1.9,-72deg);
  
           transition: transform 458ms ease-in-out;
  }.cl-39 {
  
        transform: translate3d(-35px, -490px, 221px) rotate3d(1.5,0.2,0.5,74deg);
           transition: transform 783ms ease-in-out;  
  
  }  .cl-39.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 767ms ease-in-out;
  
  }
  .cl-39.reverse {
  
        transform: translate3d(100px, 113px, -413px) rotate3d(1.5,1.3,-1.7,158deg);
  
           transition: transform 416ms ease-in-out;
  }.cl-40 {
  
        transform: translate3d(-138px, -17px, -363px) rotate3d(-1,0.6,-1.3,-288deg);
           transition: transform 338ms ease-in-out;  
  
  }  .cl-40.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 132ms ease-in-out;
  
  }
  .cl-40.reverse {
  
        transform: translate3d(70px, -439px, 147px) rotate3d(0.3,-0.1,-1,-111deg);
  
           transition: transform 1005ms ease-in-out;
  }.cl-41 {
  
        transform: translate3d(-157px, -164px, 59px) rotate3d(1.6,1,-0.2,-344deg);
           transition: transform 314ms ease-in-out;  
  
  }  .cl-41.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 827ms ease-in-out;
  
  }
  .cl-41.reverse {
  
        transform: translate3d(255px, 41px, -478px) rotate3d(0.4,1.1,-1.3,-95deg);
  
           transition: transform 1032ms ease-in-out;
  }.cl-42 {
  
        transform: translate3d(-211px, 24px, 279px) rotate3d(1.7,-1.8,1.5,58deg);
           transition: transform 884ms ease-in-out;  
  
  }  .cl-42.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 816ms ease-in-out;
  
  }
  .cl-42.reverse {
  
        transform: translate3d(-245px, -165px, -49px) rotate3d(1,-0.5,-0.5,230deg);
  
           transition: transform 920ms ease-in-out;
  }.cl-43 {
  
        transform: translate3d(17px, -289px, 198px) rotate3d(-1.2,-1.6,0.4,-243deg);
           transition: transform 504ms ease-in-out;  
  
  }  .cl-43.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1251ms ease-in-out;
  
  }
  .cl-43.reverse {
  
        transform: translate3d(457px, -106px, -97px) rotate3d(-0.4,-1,0.5,-5deg);
  
           transition: transform 190ms ease-in-out;
  }.cl-44 {
  
        transform: translate3d(444px, -231px, 154px) rotate3d(-1.4,2,0.4,281deg);
           transition: transform 1173ms ease-in-out;  
  
  }  .cl-44.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 105ms ease-in-out;
  
  }
  .cl-44.reverse {
  
        transform: translate3d(491px, -116px, -250px) rotate3d(-1.4,-0.5,1.4,-296deg);
  
           transition: transform 152ms ease-in-out;
  }.cl-45 {
  
        transform: translate3d(-392px, 47px, -26px) rotate3d(0.2,1.8,-1.7,2deg);
           transition: transform 848ms ease-in-out;  
  
  }  .cl-45.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 711ms ease-in-out;
  
  }
  .cl-45.reverse {
  
        transform: translate3d(-328px, -337px, -110px) rotate3d(-0.6,-1.8,-1.7,-17deg);
  
           transition: transform 861ms ease-in-out;
  }.cl-46 {
  
        transform: translate3d(227px, -97px, -125px) rotate3d(1.6,1,1.1,-187deg);
           transition: transform 148ms ease-in-out;  
  
  }  .cl-46.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 850ms ease-in-out;
  
  }
  .cl-46.reverse {
  
        transform: translate3d(133px, 37px, 142px) rotate3d(-1.7,-1,1,48deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-47 {
  
        transform: translate3d(319px, -233px, 17px) rotate3d(1.8,1.8,0.6,12deg);
           transition: transform 1203ms ease-in-out;  
  
  }  .cl-47.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 537ms ease-in-out;
  
  }
  .cl-47.reverse {
  
        transform: translate3d(85px, 364px, 340px) rotate3d(0.1,-1.4,-0.7,-113deg);
  
           transition: transform 180ms ease-in-out;
  }.cl-48 {
  
        transform: translate3d(-319px, -427px, 313px) rotate3d(-1.1,0.9,-1.3,48deg);
           transition: transform 1106ms ease-in-out;  
  
  }  .cl-48.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1000ms ease-in-out;
  
  }
  .cl-48.reverse {
  
        transform: translate3d(-365px, 51px, 108px) rotate3d(1.7,0.4,-0.4,187deg);
  
           transition: transform 155ms ease-in-out;
  }.cl-49 {
  
        transform: translate3d(261px, -489px, 29px) rotate3d(0.6,1.7,-2,16deg);
           transition: transform 1194ms ease-in-out;  
  
  }  .cl-49.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 770ms ease-in-out;
  
  }
  .cl-49.reverse {
  
        transform: translate3d(-315px, -114px, -35px) rotate3d(0.2,-0.3,-1.4,288deg);
  
           transition: transform 417ms ease-in-out;
  }.cl-50 {
  
        transform: translate3d(-245px, 207px, -314px) rotate3d(-0.7,2,-1,-348deg);
           transition: transform 518ms ease-in-out;  
  
  }  .cl-50.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 242ms ease-in-out;
  
  }
  .cl-50.reverse {
  
        transform: translate3d(-256px, -339px, 367px) rotate3d(-1.3,-1.1,0.1,308deg);
  
           transition: transform 932ms ease-in-out;
  }.cl-51 {
  
        transform: translate3d(395px, 372px, 34px) rotate3d(-0.5,-0.5,-1.8,-10deg);
           transition: transform 1339ms ease-in-out;  
  
  }  .cl-51.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-51.reverse {
  
        transform: translate3d(-360px, 449px, 488px) rotate3d(-0.8,-0.8,1.1,178deg);
  
           transition: transform 789ms ease-in-out;
  }.cl-52 {
  
        transform: translate3d(-233px, -259px, 125px) rotate3d(0.2,-1.2,1.6,187deg);
           transition: transform 1539ms ease-in-out;  
  
  }  .cl-52.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 461ms ease-in-out;
  
  }
  .cl-52.reverse {
  
        transform: translate3d(170px, 179px, -332px) rotate3d(-0.6,-0.5,1,278deg);
  
           transition: transform 923ms ease-in-out;
  }.cl-53 {
  
        transform: translate3d(-22px, -488px, 216px) rotate3d(-0.2,-0.7,-0.8,150deg);
           transition: transform 550ms ease-in-out;  
  
  }  .cl-53.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1514ms ease-in-out;
  
  }
  .cl-53.reverse {
  
        transform: translate3d(-345px, -132px, -245px) rotate3d(-0.2,-0.9,-1.8,66deg);
  
           transition: transform 874ms ease-in-out;
  }.cl-54 {
  
        transform: translate3d(-213px, -354px, -93px) rotate3d(0.4,-1.7,-1.1,-27deg);
           transition: transform 426ms ease-in-out;  
  
  }  .cl-54.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 481ms ease-in-out;
  
  }
  .cl-54.reverse {
  
        transform: translate3d(481px, -386px, 83px) rotate3d(-1.8,-0.1,1.1,-248deg);
  
           transition: transform 1321ms ease-in-out;
  }.cl-55 {
  
        transform: translate3d(483px, 187px, -81px) rotate3d(0.6,1.2,-0.9,341deg);
           transition: transform 1142ms ease-in-out;  
  
  }  .cl-55.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1338ms ease-in-out;
  
  }
  .cl-55.reverse {
  
        transform: translate3d(128px, -213px, 282px) rotate3d(-1.7,-1,1.1,81deg);
  
           transition: transform 1136ms ease-in-out;
  }.cl-56 {
  
        transform: translate3d(225px, -411px, 159px) rotate3d(-1.9,-1.6,0.4,132deg);
           transition: transform 500ms ease-in-out;  
  
  }  .cl-56.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 893ms ease-in-out;
  
  }
  .cl-56.reverse {
  
        transform: translate3d(-254px, 286px, 287px) rotate3d(0.3,-1.8,1.6,166deg);
  
           transition: transform 149ms ease-in-out;
  }.cl-57 {
  
        transform: translate3d(-20px, 93px, -244px) rotate3d(-1.1,-0.3,-0.2,206deg);
           transition: transform 155ms ease-in-out;  
  
  }  .cl-57.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1460ms ease-in-out;
  
  }
  .cl-57.reverse {
  
        transform: translate3d(-446px, -105px, -406px) rotate3d(1.2,1.3,-0.7,-35deg);
  
           transition: transform 1700ms ease-in-out;
  }.cl-58 {
  
        transform: translate3d(380px, 86px, -120px) rotate3d(-2,-0.8,0.8,293deg);
           transition: transform 827ms ease-in-out;  
  
  }  .cl-58.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1086ms ease-in-out;
  
  }
  .cl-58.reverse {
  
        transform: translate3d(131px, 136px, -159px) rotate3d(-1.6,-0.7,-0.3,84deg);
  
           transition: transform 1639ms ease-in-out;
  }.cl-59 {
  
        transform: translate3d(495px, 465px, -84px) rotate3d(1.9,-0.8,-1.2,272deg);
           transition: transform 847ms ease-in-out;  
  
  }  .cl-59.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 923ms ease-in-out;
  
  }
  .cl-59.reverse {
  
        transform: translate3d(-228px, -280px, -175px) rotate3d(-1.5,-1.6,-0.5,121deg);
  
           transition: transform 362ms ease-in-out;
  }.cl-60 {
  
        transform: translate3d(160px, 162px, 40px) rotate3d(0.6,-1.7,-0.3,327deg);
           transition: transform 531ms ease-in-out;  
  
  }  .cl-60.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 166ms ease-in-out;
  
  }
  .cl-60.reverse {
  
        transform: translate3d(259px, 150px, 472px) rotate3d(0.6,-0.8,-1.1,123deg);
  
           transition: transform 1595ms ease-in-out;
  }.cl-61 {
  
        transform: translate3d(-247px, -266px, 31px) rotate3d(-1.2,0.8,-1.7,-283deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-61.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 630ms ease-in-out;
  
  }
  .cl-61.reverse {
  
        transform: translate3d(441px, 207px, 72px) rotate3d(-0.1,-1.8,1.1,154deg);
  
           transition: transform 1494ms ease-in-out;
  }.cl-62 {
  
        transform: translate3d(495px, 24px, -133px) rotate3d(1.6,1.4,1.3,144deg);
           transition: transform 1704ms ease-in-out;  
  
  }  .cl-62.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 523ms ease-in-out;
  
  }
  .cl-62.reverse {
  
        transform: translate3d(-352px, 240px, -406px) rotate3d(-1.1,-1.7,0.8,-58deg);
  
           transition: transform 1106ms ease-in-out;
  }.cl-63 {
  
        transform: translate3d(-150px, -441px, -18px) rotate3d(-0.9,0.6,1.4,-329deg);
           transition: transform 1275ms ease-in-out;  
  
  }  .cl-63.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 840ms ease-in-out;
  
  }
  .cl-63.reverse {
  
        transform: translate3d(60px, 37px, 39px) rotate3d(0,0.2,-0.4,115deg);
  
           transition: transform 382ms ease-in-out;
  }.cl-64 {
  
        transform: translate3d(197px, -278px, -473px) rotate3d(1.7,0.1,1,85deg);
           transition: transform 1786ms ease-in-out;  
  
  }  .cl-64.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 213ms ease-in-out;
  
  }
  .cl-64.reverse {
  
        transform: translate3d(82px, -399px, 59px) rotate3d(0.3,-1.8,0.1,285deg);
  
           transition: transform 1075ms ease-in-out;
  }.cl-65 {
  
        transform: translate3d(141px, 148px, 451px) rotate3d(-0.3,-0.8,0.5,-293deg);
           transition: transform 724ms ease-in-out;  
  
  }  .cl-65.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1710ms ease-in-out;
  
  }
  .cl-65.reverse {
  
        transform: translate3d(-25px, -366px, -57px) rotate3d(-0.3,2,1.1,16deg);
  
           transition: transform 1493ms ease-in-out;
  }.cl-66 {
  
        transform: translate3d(-381px, 181px, -483px) rotate3d(0.7,-0.6,1.5,-56deg);
           transition: transform 826ms ease-in-out;  
  
  }  .cl-66.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 617ms ease-in-out;
  
  }
  .cl-66.reverse {
  
        transform: translate3d(-195px, -263px, 101px) rotate3d(-0.1,-0.6,0.9,260deg);
  
           transition: transform 518ms ease-in-out;
  }.cl-67 {
  
        transform: translate3d(-198px, 80px, 138px) rotate3d(-1.5,0.9,-1.1,-84deg);
           transition: transform 689ms ease-in-out;  
  
  }  .cl-67.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1449ms ease-in-out;
  
  }
  .cl-67.reverse {
  
        transform: translate3d(393px, -351px, -480px) rotate3d(1,-0.4,-0.5,-194deg);
  
           transition: transform 211ms ease-in-out;
  }.cl-68 {
  
        transform: translate3d(-26px, 255px, 361px) rotate3d(-1.3,-1.5,-1.3,359deg);
           transition: transform 522ms ease-in-out;  
  
  }  .cl-68.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1181ms ease-in-out;
  
  }
  .cl-68.reverse {
  
        transform: translate3d(55px, 61px, 137px) rotate3d(-1.5,0.2,-1.3,122deg);
  
           transition: transform 418ms ease-in-out;
  }.cl-69 {
  
        transform: translate3d(-264px, 276px, 474px) rotate3d(-0.4,-1.5,0.8,297deg);
           transition: transform 275ms ease-in-out;  
  
  }  .cl-69.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 672ms ease-in-out;
  
  }
  .cl-69.reverse {
  
        transform: translate3d(123px, 139px, -303px) rotate3d(1.5,0.1,0.5,-19deg);
  
           transition: transform 908ms ease-in-out;
  }.cl-70 {
  
        transform: translate3d(-409px, -424px, -413px) rotate3d(-0.4,0.9,0.7,-42deg);
           transition: transform 1920ms ease-in-out;  
  
  }  .cl-70.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 379ms ease-in-out;
  
  }
  .cl-70.reverse {
  
        transform: translate3d(-320px, 388px, 446px) rotate3d(-1.6,1,1.2,-297deg);
  
           transition: transform 1299ms ease-in-out;
  }.cl-71 {
  
        transform: translate3d(412px, 250px, 460px) rotate3d(-1.7,0.9,-0.4,179deg);
           transition: transform 1887ms ease-in-out;  
  
  }  .cl-71.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 245ms ease-in-out;
  
  }
  .cl-71.reverse {
  
        transform: translate3d(223px, -225px, 257px) rotate3d(0.8,1.5,-0.1,-27deg);
  
           transition: transform 1725ms ease-in-out;
  }.cl-72 {
  
        transform: translate3d(11px, 282px, 264px) rotate3d(1.1,0.3,0.6,319deg);
           transition: transform 1433ms ease-in-out;  
  
  }  .cl-72.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2057ms ease-in-out;
  
  }
  .cl-72.reverse {
  
        transform: translate3d(279px, 378px, -433px) rotate3d(-0.2,-0.1,-1.1,-323deg);
  
           transition: transform 1943ms ease-in-out;
  }.cl-73 {
  
        transform: translate3d(409px, -296px, 402px) rotate3d(-0.8,-1.4,-0.8,-216deg);
           transition: transform 2151ms ease-in-out;  
  
  }  .cl-73.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-73.reverse {
  
        transform: translate3d(-326px, -412px, 140px) rotate3d(0.4,-1.1,-1.3,-262deg);
  
           transition: transform 1139ms ease-in-out;
  }.cl-74 {
  
        transform: translate3d(95px, 162px, 73px) rotate3d(1,-1.3,-0.2,174deg);
           transition: transform 880ms ease-in-out;  
  
  }  .cl-74.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1732ms ease-in-out;
  
  }
  .cl-74.reverse {
  
        transform: translate3d(-130px, 316px, 208px) rotate3d(0.3,-1.2,-1.1,-343deg);
  
           transition: transform 1420ms ease-in-out;
  }.cl-75 {
  
        transform: translate3d(224px, 270px, 306px) rotate3d(-2,-0.8,0.2,41deg);
           transition: transform 964ms ease-in-out;  
  
  }  .cl-75.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1396ms ease-in-out;
  
  }
  .cl-75.reverse {
  
        transform: translate3d(460px, -272px, -401px) rotate3d(-0.5,-1.8,1.1,99deg);
  
           transition: transform 1456ms ease-in-out;
  }.cl-76 {
  
        transform: translate3d(-341px, 201px, -60px) rotate3d(1.7,-0.6,-0.5,341deg);
           transition: transform 2054ms ease-in-out;  
  
  }  .cl-76.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 209ms ease-in-out;
  
  }
  .cl-76.reverse {
  
        transform: translate3d(233px, 146px, 309px) rotate3d(-1.7,-0.1,-1.5,-90deg);
  
           transition: transform 1312ms ease-in-out;
  }.cl-77 {
  
        transform: translate3d(-304px, 150px, 9px) rotate3d(-0.2,-1.7,1.4,-348deg);
           transition: transform 260ms ease-in-out;  
  
  }  .cl-77.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1623ms ease-in-out;
  
  }
  .cl-77.reverse {
  
        transform: translate3d(-456px, 475px, 386px) rotate3d(1.7,-1.2,-1.9,297deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-78 {
  
        transform: translate3d(-85px, -470px, -12px) rotate3d(1,1.9,1.3,343deg);
           transition: transform 2296ms ease-in-out;  
  
  }  .cl-78.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1096ms ease-in-out;
  
  }
  .cl-78.reverse {
  
        transform: translate3d(307px, 341px, -319px) rotate3d(0.1,-1.7,0.9,-60deg);
  
           transition: transform 1654ms ease-in-out;
  }.cl-79 {
  
        transform: translate3d(-121px, 291px, -116px) rotate3d(-0.3,0.8,0,-95deg);
           transition: transform 2121ms ease-in-out;  
  
  }  .cl-79.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 142ms ease-in-out;
  
  }
  .cl-79.reverse {
  
        transform: translate3d(-413px, -475px, -3px) rotate3d(1.9,1.8,0.6,306deg);
  
           transition: transform 2241ms ease-in-out;
  }.cl-80 {
  
        transform: translate3d(-345px, 385px, 270px) rotate3d(1.2,-0.8,-1.1,296deg);
           transition: transform 438ms ease-in-out;  
  
  }  .cl-80.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1246ms ease-in-out;
  
  }
  .cl-80.reverse {
  
        transform: translate3d(-258px, 315px, 149px) rotate3d(0.1,-1.2,-1.2,337deg);
  
           transition: transform 1873ms ease-in-out;
  }.cl-81 {
  
        transform: translate3d(184px, -242px, -47px) rotate3d(-0.4,-0.5,2,191deg);
           transition: transform 2429ms ease-in-out;  
  
  }  .cl-81.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 742ms ease-in-out;
  
  }
  .cl-81.reverse {
  
        transform: translate3d(27px, -424px, 125px) rotate3d(1.1,1.2,0.6,-309deg);
  
           transition: transform 1619ms ease-in-out;
  }.cl-82 {
  
        transform: translate3d(309px, -285px, -45px) rotate3d(0,0.7,-1.7,74deg);
           transition: transform 741ms ease-in-out;  
  
  }  .cl-82.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1593ms ease-in-out;
  
  }
  .cl-82.reverse {
  
        transform: translate3d(72px, 389px, 52px) rotate3d(-0.5,-1.7,-1.7,281deg);
  
           transition: transform 1109ms ease-in-out;
  }.cl-83 {
  
        transform: translate3d(-453px, 228px, 270px) rotate3d(0.2,1.8,-1.9,52deg);
           transition: transform 682ms ease-in-out;  
  
  }  .cl-83.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1442ms ease-in-out;
  
  }
  .cl-83.reverse {
  
        transform: translate3d(243px, -2px, -121px) rotate3d(-1.4,-1.3,-0.5,19deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-84 {
  
        transform: translate3d(-244px, 325px, 94px) rotate3d(0.2,-1.7,1.7,-261deg);
           transition: transform 1265ms ease-in-out;  
  
  }  .cl-84.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1729ms ease-in-out;
  
  }
  .cl-84.reverse {
  
        transform: translate3d(-237px, -286px, 85px) rotate3d(0.6,0.4,-1.9,-106deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-85 {
  
        transform: translate3d(-466px, -217px, -87px) rotate3d(1.5,0.3,-0.6,-84deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-85.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 812ms ease-in-out;
  
  }
  .cl-85.reverse {
  
        transform: translate3d(359px, -340px, 308px) rotate3d(-0.5,1,-1.2,-197deg);
  
           transition: transform 2436ms ease-in-out;
  }.cl-86 {
  
        transform: translate3d(0px, 182px, 259px) rotate3d(1.8,-0.9,0.1,95deg);
           transition: transform 1029ms ease-in-out;  
  
  }  .cl-86.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2121ms ease-in-out;
  
  }
  .cl-86.reverse {
  
        transform: translate3d(9px, 187px, -183px) rotate3d(-0.3,1.6,0.3,-193deg);
  
           transition: transform 2181ms ease-in-out;
  }.cl-87 {
  
        transform: translate3d(-343px, -33px, 469px) rotate3d(-0.2,1.3,0.5,-58deg);
           transition: transform 2568ms ease-in-out;  
  
  }  .cl-87.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2032ms ease-in-out;
  
  }
  .cl-87.reverse {
  
        transform: translate3d(29px, 328px, -197px) rotate3d(0,-0.6,-1.7,96deg);
  
           transition: transform 1480ms ease-in-out;
  }.cl-88 {
  
        transform: translate3d(124px, -395px, 452px) rotate3d(-1,-1.1,-0.8,72deg);
           transition: transform 1750ms ease-in-out;  
  
  }  .cl-88.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1131ms ease-in-out;
  
  }
  .cl-88.reverse {
  
        transform: translate3d(-499px, -86px, -113px) rotate3d(-1.1,-1.9,0.7,196deg);
  
           transition: transform 2013ms ease-in-out;
  }.cl-89 {
  
        transform: translate3d(323px, 204px, 224px) rotate3d(0.2,0.6,-1.5,-176deg);
           transition: transform 1403ms ease-in-out;  
  
  }  .cl-89.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 357ms ease-in-out;
  
  }
  .cl-89.reverse {
  
        transform: translate3d(249px, -41px, 120px) rotate3d(-0.9,0.4,-1.7,-317deg);
  
           transition: transform 506ms ease-in-out;
  }.cl-90 {
  
        transform: translate3d(-162px, 374px, -265px) rotate3d(-1.3,1.6,1.1,-72deg);
           transition: transform 2499ms ease-in-out;  
  
  }  .cl-90.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1782ms ease-in-out;
  
  }
  .cl-90.reverse {
  
        transform: translate3d(134px, -160px, -39px) rotate3d(-1.7,1.3,-0.2,-85deg);
  
           transition: transform 1731ms ease-in-out;
  }.cl-91 {
  
        transform: translate3d(189px, 430px, 219px) rotate3d(1.9,0.3,-1.5,351deg);
           transition: transform 2038ms ease-in-out;  
  
  }  .cl-91.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1324ms ease-in-out;
  
  }
  .cl-91.reverse {
  
        transform: translate3d(41px, 213px, 122px) rotate3d(1.6,0.5,0.3,-259deg);
  
           transition: transform 2169ms ease-in-out;
  }.cl-92 {
  
        transform: translate3d(-135px, -447px, 183px) rotate3d(1,0.4,0.4,265deg);
           transition: transform 2253ms ease-in-out;  
  
  }  .cl-92.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2268ms ease-in-out;
  
  }
  .cl-92.reverse {
  
        transform: translate3d(283px, -176px, -230px) rotate3d(1,-0.6,-0.1,55deg);
  
           transition: transform 2127ms ease-in-out;
  }.cl-93 {
  
        transform: translate3d(104px, 136px, -338px) rotate3d(-0.7,-1.2,1,3deg);
           transition: transform 2712ms ease-in-out;  
  
  }  .cl-93.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1505ms ease-in-out;
  
  }
  .cl-93.reverse {
  
        transform: translate3d(163px, 371px, 156px) rotate3d(1.2,0.1,-1.6,-122deg);
  
           transition: transform 1536ms ease-in-out;
  }.cl-94 {
  
        transform: translate3d(408px, 412px, -308px) rotate3d(0.4,1.3,-1.8,-91deg);
           transition: transform 377ms ease-in-out;  
  
  }  .cl-94.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2115ms ease-in-out;
  
  }
  .cl-94.reverse {
  
        transform: translate3d(231px, 104px, 454px) rotate3d(-0.9,1.5,-0.6,-1deg);
  
           transition: transform 1142ms ease-in-out;
  }.cl-95 {
  
        transform: translate3d(-222px, -467px, 181px) rotate3d(1.5,1.4,0.1,-341deg);
           transition: transform 1441ms ease-in-out;  
  
  }  .cl-95.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 929ms ease-in-out;
  
  }
  .cl-95.reverse {
  
        transform: translate3d(-340px, -396px, 123px) rotate3d(1.5,1.7,-1.1,8deg);
  
           transition: transform 1969ms ease-in-out;
  }.cl-96 {
  
        transform: translate3d(-97px, 400px, 315px) rotate3d(0,-1.1,0.6,169deg);
           transition: transform 1124ms ease-in-out;  
  
  }  .cl-96.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2322ms ease-in-out;
  
  }
  .cl-96.reverse {
  
        transform: translate3d(-111px, -432px, -78px) rotate3d(-1.9,-1.1,-1.9,-237deg);
  
           transition: transform 741ms ease-in-out;
  }.cl-97 {
  
        transform: translate3d(-30px, -446px, 26px) rotate3d(0.8,-1,1.1,14deg);
           transition: transform 756ms ease-in-out;  
  
  }  .cl-97.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1556ms ease-in-out;
  
  }
  .cl-97.reverse {
  
        transform: translate3d(-72px, -13px, -445px) rotate3d(0.6,-0.9,1.7,218deg);
  
           transition: transform 823ms ease-in-out;
  }.cl-98 {
  
        transform: translate3d(-397px, -119px, -334px) rotate3d(-0.4,-0.9,1.8,146deg);
           transition: transform 1235ms ease-in-out;  
  
  }  .cl-98.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1621ms ease-in-out;
  
  }
  .cl-98.reverse {
  
        transform: translate3d(-117px, 403px, -180px) rotate3d(1.7,-0.6,-1.2,156deg);
  
           transition: transform 1594ms ease-in-out;
  }.cl-99 {
  
        transform: translate3d(-474px, 374px, 263px) rotate3d(0.2,-1.9,-0.3,112deg);
           transition: transform 536ms ease-in-out;  
  
  }  .cl-99.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2302ms ease-in-out;
  
  }
  .cl-99.reverse {
  
        transform: translate3d(-221px, 187px, 54px) rotate3d(1.7,-0.7,-1.4,-49deg);
  
           transition: transform 127ms ease-in-out;
  }.cl-100 {
  
        transform: translate3d(425px, 339px, -397px) rotate3d(-0.1,1.7,1.3,-72deg);
           transition: transform 1983ms ease-in-out;  
  
  }  .cl-100.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1848ms ease-in-out;
  
  }
  .cl-100.reverse {
  
        transform: translate3d(47px, 372px, 176px) rotate3d(0.2,1.2,0.3,20deg);
  
           transition: transform 2145ms ease-in-out;
  }.cl-101 {
  
        transform: translate3d(59px, 446px, -429px) rotate3d(-0.1,-1.3,-1.1,-347deg);
           transition: transform 510ms ease-in-out;  
  
  }  .cl-101.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1369ms ease-in-out;
  
  }
  .cl-101.reverse {
  
        transform: translate3d(-477px, -481px, 444px) rotate3d(0,-1.4,-0.7,-148deg);
  
           transition: transform 2243ms ease-in-out;
  }.cl-102 {
  
        transform: translate3d(455px, 498px, -333px) rotate3d(-0.4,-1.4,0.7,85deg);
           transition: transform 1868ms ease-in-out;  
  
  }  .cl-102.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 159ms ease-in-out;
  
  }
  .cl-102.reverse {
  
        transform: translate3d(357px, 378px, -80px) rotate3d(-0.6,-1.7,0.8,-360deg);
  
           transition: transform 1456ms ease-in-out;
  }.cl-103 {
  
        transform: translate3d(306px, -7px, 109px) rotate3d(1.2,-1.8,-0.2,-92deg);
           transition: transform 3085ms ease-in-out;  
  
  }  .cl-103.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 148ms ease-in-out;
  
  }
  .cl-103.reverse {
  
        transform: translate3d(-432px, -3px, -246px) rotate3d(-0.6,-1.9,0.3,-329deg);
  
           transition: transform 2211ms ease-in-out;
  }.cl-104 {
  
        transform: translate3d(-131px, 66px, 145px) rotate3d(1.5,-1.9,-1.9,-255deg);
           transition: transform 1429ms ease-in-out;  
  
  }  .cl-104.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3047ms ease-in-out;
  
  }
  .cl-104.reverse {
  
        transform: translate3d(-81px, -307px, 474px) rotate3d(1.9,-0.3,1.8,-200deg);
  
           transition: transform 1570ms ease-in-out;
  }.cl-105 {
  
        transform: translate3d(287px, -177px, -187px) rotate3d(-1.7,0.3,0.9,-296deg);
           transition: transform 1249ms ease-in-out;  
  
  }  .cl-105.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-105.reverse {
  
        transform: translate3d(158px, 137px, -1px) rotate3d(-1.5,0.2,0.1,-267deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-106 {
  
        transform: translate3d(-433px, 119px, -69px) rotate3d(0.5,-0.5,-0.3,-358deg);
           transition: transform 505ms ease-in-out;  
  
  }  .cl-106.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1189ms ease-in-out;
  
  }
  .cl-106.reverse {
  
        transform: translate3d(119px, 60px, 235px) rotate3d(0.9,1.6,-0.7,-203deg);
  
           transition: transform 562ms ease-in-out;
  }.cl-107 {
  
        transform: translate3d(-330px, 129px, 39px) rotate3d(1.8,-1.3,-1.1,87deg);
           transition: transform 402ms ease-in-out;  
  
  }  .cl-107.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 977ms ease-in-out;
  
  }
  .cl-107.reverse {
  
        transform: translate3d(210px, -159px, -302px) rotate3d(0.1,0.6,1.4,-105deg);
  
           transition: transform 2297ms ease-in-out;
  }.cl-108 {
  
        transform: translate3d(269px, 235px, -442px) rotate3d(-1.8,-1.8,-0.7,-201deg);
           transition: transform 1152ms ease-in-out;  
  
  }  .cl-108.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3094ms ease-in-out;
  
  }
  .cl-108.reverse {
  
        transform: translate3d(-413px, -446px, -189px) rotate3d(0.2,-1.3,-2,219deg);
  
           transition: transform 569ms ease-in-out;
  }.cl-109 {
  
        transform: translate3d(-323px, 231px, 209px) rotate3d(-1,0,1.3,30deg);
           transition: transform 1566ms ease-in-out;  
  
  }  .cl-109.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 590ms ease-in-out;
  
  }
  .cl-109.reverse {
  
        transform: translate3d(4px, 124px, 191px) rotate3d(-0.7,1,-2,200deg);
  
           transition: transform 1345ms ease-in-out;
  }.cl-110 {
  
        transform: translate3d(252px, 420px, 23px) rotate3d(0.4,-0.8,-0.8,-107deg);
           transition: transform 2413ms ease-in-out;  
  
  }  .cl-110.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1653ms ease-in-out;
  
  }
  .cl-110.reverse {
  
        transform: translate3d(72px, 387px, -286px) rotate3d(-1.7,-0.7,1.4,-242deg);
  
           transition: transform 1987ms ease-in-out;
  }.cl-111 {
  
        transform: translate3d(-193px, -20px, -180px) rotate3d(-1.9,0.6,1.8,-306deg);
           transition: transform 1160ms ease-in-out;  
  
  }  .cl-111.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 136ms ease-in-out;
  
  }
  .cl-111.reverse {
  
        transform: translate3d(-11px, 124px, -241px) rotate3d(0.1,0,-1,0deg);
  
           transition: transform 2681ms ease-in-out;
  }.cl-112 {
  
        transform: translate3d(-398px, -166px, 370px) rotate3d(-0.8,0.9,1.1,57deg);
           transition: transform 1778ms ease-in-out;  
  
  }  .cl-112.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2949ms ease-in-out;
  
  }
  .cl-112.reverse {
  
        transform: translate3d(-242px, 72px, -33px) rotate3d(-1.1,1.7,-1.4,99deg);
  
           transition: transform 2373ms ease-in-out;
  }.cl-113 {
  
        transform: translate3d(-417px, -353px, 431px) rotate3d(-1.6,1.5,-1.7,13deg);
           transition: transform 385ms ease-in-out;  
  
  }  .cl-113.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2705ms ease-in-out;
  
  }
  .cl-113.reverse {
  
        transform: translate3d(49px, -57px, 263px) rotate3d(0.1,-1.6,0.6,9deg);
  
           transition: transform 3371ms ease-in-out;
  }.cl-114 {
  
        transform: translate3d(15px, 175px, 296px) rotate3d(-0.5,-1.8,-1.2,207deg);
           transition: transform 1146ms ease-in-out;  
  
  }  .cl-114.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2314ms ease-in-out;
  
  }
  .cl-114.reverse {
  
        transform: translate3d(107px, 199px, -191px) rotate3d(-1.5,1.9,0.3,5deg);
  
           transition: transform 3412ms ease-in-out;
  }.cl-115 {
  
        transform: translate3d(339px, 496px, -115px) rotate3d(1.7,-1.6,1.6,26deg);
           transition: transform 3082ms ease-in-out;  
  
  }  .cl-115.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2892ms ease-in-out;
  
  }
  .cl-115.reverse {
  
        transform: translate3d(-409px, 86px, -387px) rotate3d(-1.3,1.6,0.7,34deg);
  
           transition: transform 961ms ease-in-out;
  }.cl-116 {
  
        transform: translate3d(9px, 241px, -252px) rotate3d(1.1,-0.5,-0.8,242deg);
           transition: transform 564ms ease-in-out;  
  
  }  .cl-116.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2596ms ease-in-out;
  
  }
  .cl-116.reverse {
  
        transform: translate3d(-266px, 73px, 194px) rotate3d(-0.2,0.7,1.7,168deg);
  
           transition: transform 1714ms ease-in-out;
  }.cl-117 {
  
        transform: translate3d(58px, 119px, 293px) rotate3d(-1.8,-0.5,0.8,357deg);
           transition: transform 3114ms ease-in-out;  
  
  }  .cl-117.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 478ms ease-in-out;
  
  }
  .cl-117.reverse {
  
        transform: translate3d(-14px, -359px, 22px) rotate3d(0.7,-1.3,-0.4,85deg);
  
           transition: transform 3337ms ease-in-out;
  }.cl-118 {
  
        transform: translate3d(208px, -311px, -318px) rotate3d(1.3,1.9,-0.3,315deg);
           transition: transform 928ms ease-in-out;  
  
  }  .cl-118.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2203ms ease-in-out;
  
  }
  .cl-118.reverse {
  
        transform: translate3d(-133px, 406px, -68px) rotate3d(0.2,1.5,0.9,131deg);
  
           transition: transform 626ms ease-in-out;
  }.cl-119 {
  
        transform: translate3d(-205px, -337px, 202px) rotate3d(0.2,1.5,0.4,334deg);
           transition: transform 3124ms ease-in-out;  
  
  }  .cl-119.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-119.reverse {
  
        transform: translate3d(4px, -218px, 387px) rotate3d(1.8,-0.9,-1.5,205deg);
  
           transition: transform 238ms ease-in-out;
  }.cl-120 {
  
        transform: translate3d(-241px, -57px, -18px) rotate3d(-0.5,0.3,1.5,-290deg);
           transition: transform 2723ms ease-in-out;  
  
  }  .cl-120.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2072ms ease-in-out;
  
  }
  .cl-120.reverse {
  
        transform: translate3d(-455px, -332px, 373px) rotate3d(-0.3,-0.6,-0.5,-137deg);
  
           transition: transform 3018ms ease-in-out;
  }.cl-121 {
  
        transform: translate3d(412px, 16px, 124px) rotate3d(1.2,-1.4,1.5,-287deg);
           transition: transform 2136ms ease-in-out;  
  
  }  .cl-121.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 345ms ease-in-out;
  
  }
  .cl-121.reverse {
  
        transform: translate3d(135px, 329px, -42px) rotate3d(1.8,-0.8,1.4,-299deg);
  
           transition: transform 2594ms ease-in-out;
  }.cl-122 {
  
        transform: translate3d(183px, 373px, -57px) rotate3d(-1.8,-0.8,1,-126deg);
           transition: transform 565ms ease-in-out;  
  
  }  .cl-122.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1861ms ease-in-out;
  
  }
  .cl-122.reverse {
  
        transform: translate3d(-310px, -64px, -263px) rotate3d(-1.6,1.1,-1.3,229deg);
  
           transition: transform 526ms ease-in-out;
  }.cl-123 {
  
        transform: translate3d(45px, -250px, -439px) rotate3d(0.1,-1.2,-1.6,-27deg);
           transition: transform 127ms ease-in-out;  
  
  }  .cl-123.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2100ms ease-in-out;
  
  }
  .cl-123.reverse {
  
        transform: translate3d(-371px, -358px, 97px) rotate3d(1.1,1.1,-1.3,-157deg);
  
           transition: transform 2834ms ease-in-out;
  }.cl-124 {
  
        transform: translate3d(280px, -380px, -42px) rotate3d(-1.1,0.9,1,350deg);
           transition: transform 3161ms ease-in-out;  
  
  }  .cl-124.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2416ms ease-in-out;
  
  }
  .cl-124.reverse {
  
        transform: translate3d(-176px, 323px, -285px) rotate3d(1.7,1.8,-1.5,-349deg);
  
           transition: transform 1484ms ease-in-out;
  }.cl-125 {
  
        transform: translate3d(-134px, -28px, -494px) rotate3d(0.5,-1.8,1.3,247deg);
           transition: transform 304ms ease-in-out;  
  
  }  .cl-125.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3566ms ease-in-out;
  
  }
  .cl-125.reverse {
  
        transform: translate3d(313px, 40px, -189px) rotate3d(1.6,0.5,-0.6,-178deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-126 {
  
        transform: translate3d(-479px, 365px, -291px) rotate3d(1.8,-1.5,0.1,156deg);
           transition: transform 691ms ease-in-out;  
  
  }  .cl-126.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 352ms ease-in-out;
  
  }
  .cl-126.reverse {
  
        transform: translate3d(-428px, -7px, -399px) rotate3d(-1.5,-1.6,0,-118deg);
  
           transition: transform 1579ms ease-in-out;
  }.cl-127 {
  
        transform: translate3d(382px, 392px, 157px) rotate3d(-0.3,-1.1,-1.4,-296deg);
           transition: transform 635ms ease-in-out;  
  
  }  .cl-127.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 597ms ease-in-out;
  
  }
  .cl-127.reverse {
  
        transform: translate3d(439px, -280px, -409px) rotate3d(1,-1.3,0.3,206deg);
  
           transition: transform 2605ms ease-in-out;
  }.cl-128 {
  
        transform: translate3d(173px, 390px, 440px) rotate3d(-0.8,-1.5,-0.1,293deg);
           transition: transform 1464ms ease-in-out;  
  
  }  .cl-128.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1439ms ease-in-out;
  
  }
  .cl-128.reverse {
  
        transform: translate3d(112px, -172px, -255px) rotate3d(1,-0.8,1.1,-100deg);
  
           transition: transform 3662ms ease-in-out;
  }.cl-129 {
  
        transform: translate3d(399px, 203px, 19px) rotate3d(1.7,-1.5,-1.5,-114deg);
           transition: transform 2951ms ease-in-out;  
  
  }  .cl-129.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3060ms ease-in-out;
  
  }
  .cl-129.reverse {
  
        transform: translate3d(248px, -3px, -140px) rotate3d(-1.6,0,-1.8,345deg);
  
           transition: transform 148ms ease-in-out;
  }.cl-130 {
  
        transform: translate3d(383px, -53px, 64px) rotate3d(-1.9,1.1,0.7,329deg);
           transition: transform 395ms ease-in-out;  
  
  }  .cl-130.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 964ms ease-in-out;
  
  }
  .cl-130.reverse {
  
        transform: translate3d(473px, 451px, 178px) rotate3d(-2,-0.5,-0.7,-245deg);
  
           transition: transform 2869ms ease-in-out;
  }.cl-131 {
  
        transform: translate3d(-9px, -150px, 468px) rotate3d(-1,1.4,-0.2,-165deg);
           transition: transform 203ms ease-in-out;  
  
  }  .cl-131.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3879ms ease-in-out;
  
  }
  .cl-131.reverse {
  
        transform: translate3d(-127px, 110px, 333px) rotate3d(1.8,-0.4,0.2,-52deg);
  
           transition: transform 3265ms ease-in-out;
  }.cl-132 {
  
        transform: translate3d(-238px, 357px, -221px) rotate3d(-0.8,1.2,-1.3,-219deg);
           transition: transform 3939ms ease-in-out;  
  
  }  .cl-132.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1300ms ease-in-out;
  
  }
  .cl-132.reverse {
  
        transform: translate3d(479px, -485px, 18px) rotate3d(1.5,0.5,-1.2,348deg);
  
           transition: transform 319ms ease-in-out;
  }.cl-133 {
  
        transform: translate3d(233px, -412px, 291px) rotate3d(1.5,0,1.8,-49deg);
           transition: transform 3430ms ease-in-out;  
  
  }  .cl-133.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3560ms ease-in-out;
  
  }
  .cl-133.reverse {
  
        transform: translate3d(-80px, 126px, -136px) rotate3d(0.8,-0.3,1.3,322deg);
  
           transition: transform 3908ms ease-in-out;
  }.cl-134 {
  
        transform: translate3d(-325px, -448px, 123px) rotate3d(-2,-0.5,0.9,-39deg);
           transition: transform 2432ms ease-in-out;  
  
  }  .cl-134.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1224ms ease-in-out;
  
  }
  .cl-134.reverse {
  
        transform: translate3d(374px, -196px, -123px) rotate3d(-1,-0.2,1.2,231deg);
  
           transition: transform 2408ms ease-in-out;
  }.cl-135 {
  
        transform: translate3d(115px, -430px, -70px) rotate3d(1.5,1.6,0.9,-297deg);
           transition: transform 1299ms ease-in-out;  
  
  }  .cl-135.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1458ms ease-in-out;
  
  }
  .cl-135.reverse {
  
        transform: translate3d(-198px, -324px, 404px) rotate3d(-1.5,-1.8,-1.8,325deg);
  
           transition: transform 2395ms ease-in-out;
  }.cl-136 {
  
        transform: translate3d(354px, 6px, 195px) rotate3d(-0.9,-1.3,-1,35deg);
           transition: transform 1148ms ease-in-out;  
  
  }  .cl-136.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3290ms ease-in-out;
  
  }
  .cl-136.reverse {
  
        transform: translate3d(385px, -44px, -62px) rotate3d(1.7,-0.6,0,-305deg);
  
           transition: transform 3736ms ease-in-out;
  }.cl-137 {
  
        transform: translate3d(420px, 180px, 414px) rotate3d(-1.4,1.6,-1.3,-203deg);
           transition: transform 768ms ease-in-out;  
  
  }  .cl-137.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 659ms ease-in-out;
  
  }
  .cl-137.reverse {
  
        transform: translate3d(240px, 236px, 56px) rotate3d(-1.1,0.5,-0.9,323deg);
  
           transition: transform 2031ms ease-in-out;
  }.cl-138 {
  
        transform: translate3d(-122px, -428px, 22px) rotate3d(0,-0.3,1.1,-99deg);
           transition: transform 2356ms ease-in-out;  
  
  }  .cl-138.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1364ms ease-in-out;
  
  }
  .cl-138.reverse {
  
        transform: translate3d(-84px, 282px, 214px) rotate3d(0.7,-1.6,0.5,272deg);
  
           transition: transform 992ms ease-in-out;
  }.cl-139 {
  
        transform: translate3d(-461px, 248px, 69px) rotate3d(1.2,-1.2,-1.9,-28deg);
           transition: transform 1264ms ease-in-out;  
  
  }  .cl-139.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3511ms ease-in-out;
  
  }
  .cl-139.reverse {
  
        transform: translate3d(319px, 375px, 409px) rotate3d(1.7,0.3,-1.4,162deg);
  
           transition: transform 3150ms ease-in-out;
  }.cl-140 {
  
        transform: translate3d(-417px, -274px, -366px) rotate3d(0.4,-0.3,-0.8,281deg);
           transition: transform 1572ms ease-in-out;  
  
  }  .cl-140.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1509ms ease-in-out;
  
  }
  .cl-140.reverse {
  
        transform: translate3d(-451px, -127px, -466px) rotate3d(0.5,-0.1,-1.4,155deg);
  
           transition: transform 2025ms ease-in-out;
  }.cl-141 {
  
        transform: translate3d(500px, -26px, -477px) rotate3d(-0.3,0.1,0.9,-341deg);
           transition: transform 1531ms ease-in-out;  
  
  }  .cl-141.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1015ms ease-in-out;
  
  }
  .cl-141.reverse {
  
        transform: translate3d(78px, -256px, 306px) rotate3d(-1.1,1.7,1.2,-267deg);
  
           transition: transform 4124ms ease-in-out;
  }.cl-142 {
  
        transform: translate3d(39px, 177px, 94px) rotate3d(-0.3,-0.6,1.2,35deg);
           transition: transform 4220ms ease-in-out;  
  
  }  .cl-142.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 929ms ease-in-out;
  
  }
  .cl-142.reverse {
  
        transform: translate3d(-261px, -228px, 267px) rotate3d(0,1,1.1,212deg);
  
           transition: transform 2867ms ease-in-out;
  }.cl-143 {
  
        transform: translate3d(-115px, -288px, -359px) rotate3d(-1.3,-1.4,1.8,222deg);
           transition: transform 3428ms ease-in-out;  
  
  }  .cl-143.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 385ms ease-in-out;
  
  }
  .cl-143.reverse {
  
        transform: translate3d(210px, 322px, -75px) rotate3d(-0.6,-0.6,1.7,112deg);
  
           transition: transform 161ms ease-in-out;
  }.cl-144 {
  
        transform: translate3d(61px, 211px, -288px) rotate3d(0.4,-0.1,0.5,-136deg);
           transition: transform 1377ms ease-in-out;  
  
  }  .cl-144.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 648ms ease-in-out;
  
  }
  .cl-144.reverse {
  
        transform: translate3d(-46px, -291px, 323px) rotate3d(1.1,0.9,0.5,-71deg);
  
           transition: transform 553ms ease-in-out;
  }.cl-145 {
  
        transform: translate3d(82px, 11px, -85px) rotate3d(1.7,1.7,1,-337deg);
           transition: transform 2232ms ease-in-out;  
  
  }  .cl-145.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2492ms ease-in-out;
  
  }
  .cl-145.reverse {
  
        transform: translate3d(-76px, -458px, -291px) rotate3d(0.5,1.3,2,248deg);
  
           transition: transform 3702ms ease-in-out;
  }.cl-146 {
  
        transform: translate3d(424px, 74px, -281px) rotate3d(0.5,-0.9,-1.1,-112deg);
           transition: transform 1692ms ease-in-out;  
  
  }  .cl-146.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1296ms ease-in-out;
  
  }
  .cl-146.reverse {
  
        transform: translate3d(-201px, -70px, 21px) rotate3d(-0.5,-1.9,-0.6,-258deg);
  
           transition: transform 4257ms ease-in-out;
  }.cl-147 {
  
        transform: translate3d(198px, 299px, -6px) rotate3d(-1,-2,2,199deg);
           transition: transform 936ms ease-in-out;  
  
  }  .cl-147.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1779ms ease-in-out;
  
  }
  .cl-147.reverse {
  
        transform: translate3d(303px, -152px, 97px) rotate3d(-0.8,0.3,-0.4,84deg);
  
           transition: transform 3359ms ease-in-out;
  }.cl-148 {
  
        transform: translate3d(-9px, -175px, 490px) rotate3d(1.3,0.5,1.4,150deg);
           transition: transform 2060ms ease-in-out;  
  
  }  .cl-148.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3497ms ease-in-out;
  
  }
  .cl-148.reverse {
  
        transform: translate3d(200px, -331px, 472px) rotate3d(0.7,1.7,0.6,80deg);
  
           transition: transform 1689ms ease-in-out;
  }.cl-149 {
  
        transform: translate3d(1px, 349px, -416px) rotate3d(-0.5,1.4,1.8,245deg);
           transition: transform 1509ms ease-in-out;  
  
  }  .cl-149.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1950ms ease-in-out;
  
  }
  .cl-149.reverse {
  
        transform: translate3d(480px, -272px, -186px) rotate3d(1.3,0.6,1,194deg);
  
           transition: transform 647ms ease-in-out;
  }.cl-150 {
  
        transform: translate3d(431px, 17px, -351px) rotate3d(-1.2,0.4,2,-295deg);
           transition: transform 3173ms ease-in-out;  
  
  }  .cl-150.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2239ms ease-in-out;
  
  }
  .cl-150.reverse {
  
        transform: translate3d(298px, -179px, -430px) rotate3d(-1,-1.3,-0.7,221deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-151 {
  
        transform: translate3d(402px, -275px, -375px) rotate3d(1.7,1.8,-2,141deg);
           transition: transform 3156ms ease-in-out;  
  
  }  .cl-151.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2387ms ease-in-out;
  
  }
  .cl-151.reverse {
  
        transform: translate3d(229px, -498px, -63px) rotate3d(-1.4,-0.7,-1.9,-239deg);
  
           transition: transform 800ms ease-in-out;
  }.cl-152 {
  
        transform: translate3d(-455px, 419px, 480px) rotate3d(0.9,1.2,1.6,260deg);
           transition: transform 556ms ease-in-out;  
  
  }  .cl-152.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1892ms ease-in-out;
  
  }
  .cl-152.reverse {
  
        transform: translate3d(336px, -476px, -150px) rotate3d(0.3,-1.8,-1,254deg);
  
           transition: transform 998ms ease-in-out;
  }.cl-153 {
  
        transform: translate3d(-438px, 322px, -456px) rotate3d(1.7,1.9,1.3,99deg);
           transition: transform 686ms ease-in-out;  
  
  }  .cl-153.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3438ms ease-in-out;
  
  }
  .cl-153.reverse {
  
        transform: translate3d(-400px, 248px, -455px) rotate3d(-1.6,0.1,1.4,156deg);
  
           transition: transform 3212ms ease-in-out;
  }.cl-154 {
  
        transform: translate3d(399px, -375px, 446px) rotate3d(-1.5,0.5,1.1,240deg);
           transition: transform 1345ms ease-in-out;  
  
  }  .cl-154.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2309ms ease-in-out;
  
  }
  .cl-154.reverse {
  
        transform: translate3d(-412px, 482px, 159px) rotate3d(0.1,-1.5,0.2,-38deg);
  
           transition: transform 2965ms ease-in-out;
  }.cl-155 {
  
        transform: translate3d(-60px, -386px, 282px) rotate3d(-2,1.5,1.5,289deg);
           transition: transform 3518ms ease-in-out;  
  
  }  .cl-155.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2257ms ease-in-out;
  
  }
  .cl-155.reverse {
  
        transform: translate3d(236px, 381px, -403px) rotate3d(0.7,0.6,-1.3,-122deg);
  
           transition: transform 2127ms ease-in-out;
  }.cl-156 {
  
        transform: translate3d(192px, -173px, 479px) rotate3d(0.6,1.9,-1,-222deg);
           transition: transform 4322ms ease-in-out;  
  
  }  .cl-156.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3327ms ease-in-out;
  
  }
  .cl-156.reverse {
  
        transform: translate3d(-312px, 229px, -65px) rotate3d(0.9,1.6,-1.1,-326deg);
  
           transition: transform 111ms ease-in-out;
  }.cl-157 {
  
        transform: translate3d(-273px, -38px, 448px) rotate3d(0.6,1.6,-0.6,41deg);
           transition: transform 2028ms ease-in-out;  
  
  }  .cl-157.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3173ms ease-in-out;
  
  }
  .cl-157.reverse {
  
        transform: translate3d(394px, 335px, 94px) rotate3d(1,0,-1,-47deg);
  
           transition: transform 3877ms ease-in-out;
  }.cl-158 {
  
        transform: translate3d(33px, 4px, -173px) rotate3d(-1.5,-0.6,-1.9,-160deg);
           transition: transform 4287ms ease-in-out;  
  
  }  .cl-158.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1701ms ease-in-out;
  
  }
  .cl-158.reverse {
  
        transform: translate3d(366px, -288px, 131px) rotate3d(1,-1.3,1.1,312deg);
  
           transition: transform 1190ms ease-in-out;
  }.cl-159 {
  
        transform: translate3d(224px, 456px, -317px) rotate3d(-1.4,-0.5,-0.8,-284deg);
           transition: transform 1412ms ease-in-out;  
  
  }  .cl-159.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4264ms ease-in-out;
  
  }
  .cl-159.reverse {
  
        transform: translate3d(-409px, 456px, 157px) rotate3d(-0.7,-1.1,0,-15deg);
  
           transition: transform 3841ms ease-in-out;
  }.cl-160 {
  
        transform: translate3d(-343px, 267px, 461px) rotate3d(0.9,-0.1,-0.8,-198deg);
           transition: transform 3442ms ease-in-out;  
  
  }  .cl-160.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2584ms ease-in-out;
  
  }
  .cl-160.reverse {
  
        transform: translate3d(-71px, 395px, -500px) rotate3d(-0.2,0.7,-0.2,34deg);
  
           transition: transform 3193ms ease-in-out;
  }.cl-161 {
  
        transform: translate3d(17px, 277px, -255px) rotate3d(-1.5,0.2,0.7,19deg);
           transition: transform 4153ms ease-in-out;  
  
  }  .cl-161.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1228ms ease-in-out;
  
  }
  .cl-161.reverse {
  
        transform: translate3d(69px, 394px, -439px) rotate3d(0.5,-0.5,1.1,219deg);
  
           transition: transform 3751ms ease-in-out;
  }.cl-162 {
  
        transform: translate3d(-422px, 60px, 429px) rotate3d(-1.2,1.3,-1.5,-65deg);
           transition: transform 3029ms ease-in-out;  
  
  }  .cl-162.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3676ms ease-in-out;
  
  }
  .cl-162.reverse {
  
        transform: translate3d(-4px, -464px, 139px) rotate3d(-1.6,0.8,-1.9,-77deg);
  
           transition: transform 1847ms ease-in-out;
  }.cl-163 {
  
        transform: translate3d(-225px, -465px, -243px) rotate3d(1.3,-0.5,0.9,284deg);
           transition: transform 2603ms ease-in-out;  
  
  }  .cl-163.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2128ms ease-in-out;
  
  }
  .cl-163.reverse {
  
        transform: translate3d(25px, 160px, 117px) rotate3d(-0.7,-0.5,-1.2,-319deg);
  
           transition: transform 4404ms ease-in-out;
  }.cl-164 {
  
        transform: translate3d(358px, -278px, 316px) rotate3d(-1.6,0,-1.2,149deg);
           transition: transform 826ms ease-in-out;  
  
  }  .cl-164.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3331ms ease-in-out;
  
  }
  .cl-164.reverse {
  
        transform: translate3d(311px, -265px, -293px) rotate3d(0.6,-1.4,-1.8,154deg);
  
           transition: transform 1578ms ease-in-out;
  }.cl-165 {
  
        transform: translate3d(294px, -261px, 421px) rotate3d(0.7,1.8,0.1,-193deg);
           transition: transform 1132ms ease-in-out;  
  
  }  .cl-165.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4853ms ease-in-out;
  
  }
  .cl-165.reverse {
  
        transform: translate3d(244px, 329px, 119px) rotate3d(-0.5,1.4,-1.7,133deg);
  
           transition: transform 654ms ease-in-out;
  }.cl-166 {
  
        transform: translate3d(-244px, 151px, -460px) rotate3d(1.3,1.6,-1.4,84deg);
           transition: transform 4404ms ease-in-out;  
  
  }  .cl-166.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3394ms ease-in-out;
  
  }
  .cl-166.reverse {
  
        transform: translate3d(255px, 7px, -432px) rotate3d(-1.4,0.6,-0.1,-130deg);
  
           transition: transform 3057ms ease-in-out;
  }.cl-167 {
  
        transform: translate3d(-270px, -262px, 388px) rotate3d(1.4,0.5,1.9,-171deg);
           transition: transform 1736ms ease-in-out;  
  
  }  .cl-167.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2275ms ease-in-out;
  
  }
  .cl-167.reverse {
  
        transform: translate3d(123px, -293px, -264px) rotate3d(-1.6,-0.1,0.7,-203deg);
  
           transition: transform 1953ms ease-in-out;
  }.cl-168 {
  
        transform: translate3d(235px, -241px, -39px) rotate3d(-1,-1,-1.6,-123deg);
           transition: transform 466ms ease-in-out;  
  
  }  .cl-168.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4137ms ease-in-out;
  
  }
  .cl-168.reverse {
  
        transform: translate3d(-174px, 0px, 20px) rotate3d(0.6,1.4,0.2,-157deg);
  
           transition: transform 2388ms ease-in-out;
  }.cl-169 {
  
        transform: translate3d(435px, 134px, 157px) rotate3d(0.4,-0.5,1.6,207deg);
           transition: transform 4625ms ease-in-out;  
  
  }  .cl-169.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 755ms ease-in-out;
  
  }
  .cl-169.reverse {
  
        transform: translate3d(-55px, -408px, 322px) rotate3d(-1.4,0.7,-1.5,290deg);
  
           transition: transform 232ms ease-in-out;
  }.cl-170 {
  
        transform: translate3d(-493px, -9px, 455px) rotate3d(-0.2,0.6,-1.8,165deg);
           transition: transform 1435ms ease-in-out;  
  
  }  .cl-170.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1214ms ease-in-out;
  
  }
  .cl-170.reverse {
  
        transform: translate3d(37px, -183px, -199px) rotate3d(-0.2,2,0.6,-253deg);
  
           transition: transform 1731ms ease-in-out;
  }.cl-171 {
  
        transform: translate3d(299px, -58px, 47px) rotate3d(1.8,0.9,0.3,-112deg);
           transition: transform 290ms ease-in-out;  
  
  }  .cl-171.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 129ms ease-in-out;
  
  }
  .cl-171.reverse {
  
        transform: translate3d(138px, 205px, -139px) rotate3d(1.4,-1.1,1.5,196deg);
  
           transition: transform 2405ms ease-in-out;
  }.cl-172 {
  
        transform: translate3d(-245px, -50px, -338px) rotate3d(-0.1,0.6,-1.4,197deg);
           transition: transform 4161ms ease-in-out;  
  
  }  .cl-172.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1231ms ease-in-out;
  
  }
  .cl-172.reverse {
  
        transform: translate3d(44px, 343px, 100px) rotate3d(1.4,-0.1,0.3,23deg);
  
           transition: transform 4796ms ease-in-out;
  }.cl-173 {
  
        transform: translate3d(175px, 387px, 31px) rotate3d(-1.5,-1.2,-1.8,-9deg);
           transition: transform 4259ms ease-in-out;  
  
  }  .cl-173.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1306ms ease-in-out;
  
  }
  .cl-173.reverse {
  
        transform: translate3d(-132px, -427px, 55px) rotate3d(0,-1.6,-0.5,-286deg);
  
           transition: transform 699ms ease-in-out;
  }.cl-174 {
  
        transform: translate3d(357px, -379px, 136px) rotate3d(-0.3,1.7,0.7,338deg);
           transition: transform 3423ms ease-in-out;  
  
  }  .cl-174.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4080ms ease-in-out;
  
  }
  .cl-174.reverse {
  
        transform: translate3d(406px, 219px, -225px) rotate3d(1.9,-0.7,0.3,-217deg);
  
           transition: transform 2343ms ease-in-out;
  }.cl-175 {
  
        transform: translate3d(44px, 237px, 429px) rotate3d(0.3,0.6,-0.9,253deg);
           transition: transform 575ms ease-in-out;  
  
  }  .cl-175.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 118ms ease-in-out;
  
  }
  .cl-175.reverse {
  
        transform: translate3d(373px, -305px, 352px) rotate3d(1.1,-1,-0.2,-305deg);
  
           transition: transform 1546ms ease-in-out;
  }.cl-176 {
  
        transform: translate3d(499px, 380px, 165px) rotate3d(1.5,-0.6,-1.1,-1deg);
           transition: transform 3899ms ease-in-out;  
  
  }  .cl-176.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2234ms ease-in-out;
  
  }
  .cl-176.reverse {
  
        transform: translate3d(138px, -43px, 153px) rotate3d(-1.9,0.8,1.2,15deg);
  
           transition: transform 4621ms ease-in-out;
  }.cl-177 {
  
        transform: translate3d(332px, 50px, -2px) rotate3d(-0.6,-1.1,0.2,-322deg);
           transition: transform 4540ms ease-in-out;  
  
  }  .cl-177.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4419ms ease-in-out;
  
  }
  .cl-177.reverse {
  
        transform: translate3d(236px, -43px, 180px) rotate3d(0.9,-0.8,1.4,-229deg);
  
           transition: transform 2389ms ease-in-out;
  }.cl-178 {
  
        transform: translate3d(-203px, -106px, -73px) rotate3d(1.9,-0.2,0.4,-291deg);
           transition: transform 478ms ease-in-out;  
  
  }  .cl-178.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1958ms ease-in-out;
  
  }
  .cl-178.reverse {
  
        transform: translate3d(266px, -12px, -64px) rotate3d(-0.4,0.4,-0.3,-174deg);
  
           transition: transform 302ms ease-in-out;
  }.cl-179 {
  
        transform: translate3d(309px, -181px, -78px) rotate3d(-0.9,1.8,1.8,-176deg);
           transition: transform 1541ms ease-in-out;  
  
  }  .cl-179.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 905ms ease-in-out;
  
  }
  .cl-179.reverse {
  
        transform: translate3d(208px, -184px, 380px) rotate3d(-1.7,-0.4,1.5,346deg);
  
           transition: transform 528ms ease-in-out;
  }.cl-180 {
  
        transform: translate3d(173px, 46px, -419px) rotate3d(0.6,0.1,0.8,152deg);
           transition: transform 230ms ease-in-out;  
  
  }  .cl-180.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4514ms ease-in-out;
  
  }
  .cl-180.reverse {
  
        transform: translate3d(-334px, -406px, -135px) rotate3d(-0.7,1.7,0.6,42deg);
  
           transition: transform 3697ms ease-in-out;
  }.cl-181 {
  
        transform: translate3d(372px, -439px, -51px) rotate3d(-0.8,1.2,-1.7,-138deg);
           transition: transform 4272ms ease-in-out;  
  
  }  .cl-181.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3720ms ease-in-out;
  
  }
  .cl-181.reverse {
  
        transform: translate3d(7px, -256px, 471px) rotate3d(0.5,0.9,-0.5,-289deg);
  
           transition: transform 4955ms ease-in-out;
  }.cl-182 {
  
        transform: translate3d(-124px, -357px, -42px) rotate3d(-0.7,-0.6,-0.3,-229deg);
           transition: transform 190ms ease-in-out;  
  
  }  .cl-182.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3776ms ease-in-out;
  
  }
  .cl-182.reverse {
  
        transform: translate3d(-396px, -455px, 33px) rotate3d(-0.4,-0.9,0.6,271deg);
  
           transition: transform 2831ms ease-in-out;
  }.cl-183 {
  
        transform: translate3d(308px, -369px, -193px) rotate3d(1.5,1.8,1.9,-241deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-183.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 971ms ease-in-out;
  
  }
  .cl-183.reverse {
  
        transform: translate3d(237px, -248px, 454px) rotate3d(-0.7,-0.3,-0.2,-164deg);
  
           transition: transform 532ms ease-in-out;
  }.cl-184 {
  
        transform: translate3d(-134px, 226px, -332px) rotate3d(1.1,1.5,-0.5,-222deg);
           transition: transform 2475ms ease-in-out;  
  
  }  .cl-184.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 133ms ease-in-out;
  
  }
  .cl-184.reverse {
  
        transform: translate3d(141px, 180px, 141px) rotate3d(-0.7,-1.8,-1.4,-247deg);
  
           transition: transform 2891ms ease-in-out;
  }.cl-185 {
  
        transform: translate3d(358px, 61px, 251px) rotate3d(1.8,-0.2,0.2,-154deg);
           transition: transform 2609ms ease-in-out;  
  
  }  .cl-185.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4477ms ease-in-out;
  
  }
  .cl-185.reverse {
  
        transform: translate3d(-420px, 10px, 290px) rotate3d(-1.6,0.4,-1.5,-45deg);
  
           transition: transform 3201ms ease-in-out;
  }.cl-186 {
  
        transform: translate3d(-389px, -496px, -403px) rotate3d(-1.2,1.3,-0.3,141deg);
           transition: transform 4047ms ease-in-out;  
  
  }  .cl-186.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1556ms ease-in-out;
  
  }
  .cl-186.reverse {
  
        transform: translate3d(-23px, 34px, 265px) rotate3d(-1.6,0.9,-0.6,-333deg);
  
           transition: transform 4085ms ease-in-out;
  }.cl-187 {
  
        transform: translate3d(-32px, -30px, 457px) rotate3d(-1.9,-0.2,0.5,294deg);
           transition: transform 1690ms ease-in-out;  
  
  }  .cl-187.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2168ms ease-in-out;
  
  }
  .cl-187.reverse {
  
        transform: translate3d(318px, 204px, -327px) rotate3d(1.2,-0.8,0.5,245deg);
  
           transition: transform 1164ms ease-in-out;
  }.cl-188 {
  
        transform: translate3d(-142px, 477px, -358px) rotate3d(-1.2,-1.3,0.5,322deg);
           transition: transform 1999ms ease-in-out;  
  
  }  .cl-188.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3485ms ease-in-out;
  
  }
  .cl-188.reverse {
  
        transform: translate3d(446px, -471px, -491px) rotate3d(1.1,-0.5,1.9,299deg);
  
           transition: transform 4656ms ease-in-out;
  }.cl-189 {
  
        transform: translate3d(432px, 138px, -385px) rotate3d(-0.1,-1.7,0.1,234deg);
           transition: transform 2995ms ease-in-out;  
  
  }  .cl-189.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 782ms ease-in-out;
  
  }
  .cl-189.reverse {
  
        transform: translate3d(-223px, 342px, -449px) rotate3d(1.5,-1.6,-1.4,356deg);
  
           transition: transform 3087ms ease-in-out;
  }.cl-190 {
  
        transform: translate3d(-291px, -389px, -189px) rotate3d(1.6,1.8,-1.4,-251deg);
           transition: transform 1032ms ease-in-out;  
  
  }  .cl-190.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 298ms ease-in-out;
  
  }
  .cl-190.reverse {
  
        transform: translate3d(195px, -485px, -81px) rotate3d(0.4,-1.8,1.1,-313deg);
  
           transition: transform 4556ms ease-in-out;
  }.cl-191 {
  
        transform: translate3d(330px, -221px, -44px) rotate3d(-0.1,-0.6,1.9,199deg);
           transition: transform 5136ms ease-in-out;  
  
  }  .cl-191.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1429ms ease-in-out;
  
  }
  .cl-191.reverse {
  
        transform: translate3d(324px, 475px, -44px) rotate3d(1.1,-1,-0.3,-91deg);
  
           transition: transform 4852ms ease-in-out;
  }.cl-192 {
  
        transform: translate3d(136px, 139px, 142px) rotate3d(-1.6,1.2,-1.3,-11deg);
           transition: transform 4492ms ease-in-out;  
  
  }  .cl-192.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3444ms ease-in-out;
  
  }
  .cl-192.reverse {
  
        transform: translate3d(481px, 243px, -20px) rotate3d(1.7,-0.3,-0.8,173deg);
  
           transition: transform 914ms ease-in-out;
  }.cl-193 {
  
        transform: translate3d(47px, -173px, 440px) rotate3d(1.3,-1.8,0.6,30deg);
           transition: transform 3800ms ease-in-out;  
  
  }  .cl-193.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3600ms ease-in-out;
  
  }
  .cl-193.reverse {
  
        transform: translate3d(-494px, 464px, 398px) rotate3d(-0.4,-1.5,0.6,49deg);
  
           transition: transform 3341ms ease-in-out;
  }.cl-194 {
  
        transform: translate3d(-132px, 118px, 216px) rotate3d(0.5,-0.9,-1.6,-185deg);
           transition: transform 4419ms ease-in-out;  
  
  }  .cl-194.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2856ms ease-in-out;
  
  }
  .cl-194.reverse {
  
        transform: translate3d(96px, 193px, 151px) rotate3d(-0.9,-0.9,-1.7,-3deg);
  
           transition: transform 3903ms ease-in-out;
  }.cl-195 {
  
        transform: translate3d(-55px, 429px, -200px) rotate3d(1.6,-0.9,-1.6,-355deg);
           transition: transform 1483ms ease-in-out;  
  
  }  .cl-195.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1675ms ease-in-out;
  
  }
  .cl-195.reverse {
  
        transform: translate3d(-407px, 203px, 326px) rotate3d(-1.3,0.5,-0.2,209deg);
  
           transition: transform 1245ms ease-in-out;
  }.cl-196 {
  
        transform: translate3d(-412px, -500px, 51px) rotate3d(-1.4,0.3,0.9,-293deg);
           transition: transform 5284ms ease-in-out;  
  
  }  .cl-196.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5120ms ease-in-out;
  
  }
  .cl-196.reverse {
  
        transform: translate3d(-261px, 20px, -28px) rotate3d(-0.8,0.9,-1.6,79deg);
  
           transition: transform 886ms ease-in-out;
  }.cl-197 {
  
        transform: translate3d(-350px, -406px, -170px) rotate3d(-1,1.8,0.4,105deg);
           transition: transform 442ms ease-in-out;  
  
  }  .cl-197.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2664ms ease-in-out;
  
  }
  .cl-197.reverse {
  
        transform: translate3d(267px, -432px, 266px) rotate3d(0.7,1.1,-0.4,124deg);
  
           transition: transform 5194ms ease-in-out;
  }.cl-198 {
  
        transform: translate3d(135px, 378px, 98px) rotate3d(0.8,0.4,1.8,241deg);
           transition: transform 661ms ease-in-out;  
  
  }  .cl-198.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4410ms ease-in-out;
  
  }
  .cl-198.reverse {
  
        transform: translate3d(143px, 4px, 41px) rotate3d(0.6,0,-1.3,37deg);
  
           transition: transform 3542ms ease-in-out;
  }.cl-199 {
  
        transform: translate3d(-267px, 9px, 393px) rotate3d(-1.5,1,1.6,-149deg);
           transition: transform 3941ms ease-in-out;  
  
  }  .cl-199.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 529ms ease-in-out;
  
  }
  .cl-199.reverse {
  
        transform: translate3d(-323px, -247px, 161px) rotate3d(1.3,0.5,1.3,84deg);
  
           transition: transform 5029ms ease-in-out;
  }.cl-200 {
  
        transform: translate3d(195px, -203px, 15px) rotate3d(-0.5,1.1,1.1,-87deg);
           transition: transform 2089ms ease-in-out;  
  
  }  .cl-200.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2723ms ease-in-out;
  
  }
  .cl-200.reverse {
  
        transform: translate3d(-275px, 87px, -445px) rotate3d(0.5,-0.9,-1.6,53deg);
  
           transition: transform 3425ms ease-in-out;
  }.cl-201 {
  
        transform: translate3d(25px, -398px, 57px) rotate3d(1.3,-0.3,-1.4,232deg);
           transition: transform 2493ms ease-in-out;  
  
  }  .cl-201.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1970ms ease-in-out;
  
  }
  .cl-201.reverse {
  
        transform: translate3d(-381px, 245px, -77px) rotate3d(0.7,1.4,-1.9,70deg);
  
           transition: transform 3329ms ease-in-out;
  }.cl-202 {
  
        transform: translate3d(-268px, 218px, -75px) rotate3d(0.8,-1.1,0.7,16deg);
           transition: transform 297ms ease-in-out;  
  
  }  .cl-202.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1325ms ease-in-out;
  
  }
  .cl-202.reverse {
  
        transform: translate3d(498px, 233px, -246px) rotate3d(0.2,1.6,0.2,-274deg);
  
           transition: transform 2341ms ease-in-out;
  }.cl-203 {
  
        transform: translate3d(-406px, 164px, 59px) rotate3d(-1.4,-0.3,-0.8,-327deg);
           transition: transform 2267ms ease-in-out;  
  
  }  .cl-203.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5940ms ease-in-out;
  
  }
  .cl-203.reverse {
  
        transform: translate3d(277px, -276px, 237px) rotate3d(-2,-1.5,-1.1,79deg);
  
           transition: transform 4678ms ease-in-out;
  }.cl-204 {
  
        transform: translate3d(-123px, 485px, 171px) rotate3d(-1.7,0.8,1.2,-168deg);
           transition: transform 3669ms ease-in-out;  
  
  }  .cl-204.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1456ms ease-in-out;
  
  }
  .cl-204.reverse {
  
        transform: translate3d(-209px, 134px, 331px) rotate3d(-0.7,0.2,-1.9,325deg);
  
           transition: transform 5388ms ease-in-out;
  }.cl-205 {
  
        transform: translate3d(-195px, 491px, -123px) rotate3d(1,-1.5,1.9,-286deg);
           transition: transform 5921ms ease-in-out;  
  
  }  .cl-205.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5106ms ease-in-out;
  
  }
  .cl-205.reverse {
  
        transform: translate3d(-188px, 358px, -330px) rotate3d(1.8,1.2,-0.6,68deg);
  
           transition: transform 780ms ease-in-out;
  }.cl-206 {
  
        transform: translate3d(-282px, 99px, -236px) rotate3d(1,-0.1,-0.1,117deg);
           transition: transform 2917ms ease-in-out;  
  
  }  .cl-206.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2176ms ease-in-out;
  
  }
  .cl-206.reverse {
  
        transform: translate3d(-381px, -370px, -241px) rotate3d(-0.1,1.4,1.3,113deg);
  
           transition: transform 428ms ease-in-out;
  }.cl-207 {
  
        transform: translate3d(-222px, -426px, 191px) rotate3d(0.7,-1.4,0.5,114deg);
           transition: transform 3987ms ease-in-out;  
  
  }  .cl-207.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1414ms ease-in-out;
  
  }
  .cl-207.reverse {
  
        transform: translate3d(80px, -272px, -447px) rotate3d(0.6,1.2,-1.4,98deg);
  
           transition: transform 386ms ease-in-out;
  }.cl-208 {
  
        transform: translate3d(78px, -386px, 381px) rotate3d(1.4,1,-0.8,-211deg);
           transition: transform 3725ms ease-in-out;  
  
  }  .cl-208.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2866ms ease-in-out;
  
  }
  .cl-208.reverse {
  
        transform: translate3d(163px, 92px, 437px) rotate3d(-1.5,-1.4,1.4,345deg);
  
           transition: transform 708ms ease-in-out;
  }.cl-209 {
  
        transform: translate3d(-54px, -255px, -257px) rotate3d(0.5,-1.1,-1.5,133deg);
           transition: transform 2085ms ease-in-out;  
  
  }  .cl-209.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5427ms ease-in-out;
  
  }
  .cl-209.reverse {
  
        transform: translate3d(-471px, -494px, 107px) rotate3d(1.6,1.3,0.7,-239deg);
  
           transition: transform 708ms ease-in-out;
  }.cl-210 {
  
        transform: translate3d(268px, -189px, -427px) rotate3d(1.8,-0.3,-1.7,356deg);
           transition: transform 3244ms ease-in-out;  
  
  }  .cl-210.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3509ms ease-in-out;
  
  }
  .cl-210.reverse {
  
        transform: translate3d(-43px, 417px, 29px) rotate3d(1.2,0.7,2,-119deg);
  
           transition: transform 1719ms ease-in-out;
  }.cl-211 {
  
        transform: translate3d(-484px, -182px, -318px) rotate3d(-0.9,-1.1,0.6,-261deg);
           transition: transform 1213ms ease-in-out;  
  
  }  .cl-211.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1175ms ease-in-out;
  
  }
  .cl-211.reverse {
  
        transform: translate3d(222px, 159px, 435px) rotate3d(-1.8,-0.9,0.1,-21deg);
  
           transition: transform 3978ms ease-in-out;
  }.cl-212 {
  
        transform: translate3d(283px, -14px, -433px) rotate3d(-1.1,-1.5,1.8,-236deg);
           transition: transform 1358ms ease-in-out;  
  
  }  .cl-212.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5910ms ease-in-out;
  
  }
  .cl-212.reverse {
  
        transform: translate3d(-156px, 9px, -478px) rotate3d(1.3,-0.3,-1.3,337deg);
  
           transition: transform 2155ms ease-in-out;
  }.cl-213 {
  
        transform: translate3d(265px, -109px, -228px) rotate3d(-1.7,-1.6,-1.1,178deg);
           transition: transform 1406ms ease-in-out;  
  
  }  .cl-213.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3612ms ease-in-out;
  
  }
  .cl-213.reverse {
  
        transform: translate3d(-266px, -347px, -214px) rotate3d(1.1,0.3,1.8,-217deg);
  
           transition: transform 2727ms ease-in-out;
  }.cl-214 {
  
        transform: translate3d(411px, -58px, 84px) rotate3d(0.5,1.2,1.5,-47deg);
           transition: transform 2929ms ease-in-out;  
  
  }  .cl-214.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4143ms ease-in-out;
  
  }
  .cl-214.reverse {
  
        transform: translate3d(-187px, -281px, 37px) rotate3d(1,1.1,1.9,235deg);
  
           transition: transform 1484ms ease-in-out;
  }.cl-215 {
  
        transform: translate3d(-404px, 55px, -15px) rotate3d(0.4,1.4,-0.6,332deg);
           transition: transform 4837ms ease-in-out;  
  
  }  .cl-215.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2159ms ease-in-out;
  
  }
  .cl-215.reverse {
  
        transform: translate3d(-321px, 471px, -15px) rotate3d(-0.6,1.8,1.4,60deg);
  
           transition: transform 3717ms ease-in-out;
  }.cl-216 {
  
        transform: translate3d(-159px, -436px, 376px) rotate3d(-0.4,0.5,-0.8,292deg);
           transition: transform 2377ms ease-in-out;  
  
  }  .cl-216.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2734ms ease-in-out;
  
  }
  .cl-216.reverse {
  
        transform: translate3d(-148px, -229px, -231px) rotate3d(0.3,0.5,1.1,333deg);
  
           transition: transform 4659ms ease-in-out;
  }.cl-217 {
  
        transform: translate3d(140px, 167px, -293px) rotate3d(1.6,-1.3,-1.2,-3deg);
           transition: transform 836ms ease-in-out;  
  
  }  .cl-217.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2744ms ease-in-out;
  
  }
  .cl-217.reverse {
  
        transform: translate3d(300px, -342px, 400px) rotate3d(0.7,-1.1,0.1,-38deg);
  
           transition: transform 151ms ease-in-out;
  }.cl-218 {
  
        transform: translate3d(306px, 47px, 158px) rotate3d(-0.2,-0.6,1.4,16deg);
           transition: transform 2926ms ease-in-out;  
  
  }  .cl-218.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2872ms ease-in-out;
  
  }
  .cl-218.reverse {
  
        transform: translate3d(-447px, -332px, -393px) rotate3d(1.2,0.7,0.4,84deg);
  
           transition: transform 4080ms ease-in-out;
  }.cl-219 {
  
        transform: translate3d(235px, -100px, 29px) rotate3d(0.1,-1.9,-0.9,-266deg);
           transition: transform 1627ms ease-in-out;  
  
  }  .cl-219.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2857ms ease-in-out;
  
  }
  .cl-219.reverse {
  
        transform: translate3d(-32px, 64px, -92px) rotate3d(0.4,-1.4,-0.8,-27deg);
  
           transition: transform 1818ms ease-in-out;
  }.cl-220 {
  
        transform: translate3d(240px, -217px, -404px) rotate3d(-0.5,1.2,0,126deg);
           transition: transform 2255ms ease-in-out;  
  
  }  .cl-220.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1933ms ease-in-out;
  
  }
  .cl-220.reverse {
  
        transform: translate3d(476px, -162px, 406px) rotate3d(-1.2,-1.6,1.4,-144deg);
  
           transition: transform 6406ms ease-in-out;
  }.cl-221 {
  
        transform: translate3d(39px, -390px, 357px) rotate3d(0.7,1.9,-0.6,292deg);
           transition: transform 2915ms ease-in-out;  
  
  }  .cl-221.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3396ms ease-in-out;
  
  }
  .cl-221.reverse {
  
        transform: translate3d(16px, -9px, -127px) rotate3d(-0.6,-0.7,1.3,73deg);
  
           transition: transform 6078ms ease-in-out;
  }.cl-222 {
  
        transform: translate3d(480px, -312px, 153px) rotate3d(0.7,0.2,0.5,-61deg);
           transition: transform 821ms ease-in-out;  
  
  }  .cl-222.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4913ms ease-in-out;
  
  }
  .cl-222.reverse {
  
        transform: translate3d(-50px, 368px, -257px) rotate3d(-1,-0.3,-1.4,56deg);
  
           transition: transform 2825ms ease-in-out;
  }.cl-223 {
  
        transform: translate3d(-156px, -491px, 139px) rotate3d(-1.1,-1.1,-0.9,61deg);
           transition: transform 781ms ease-in-out;  
  
  }  .cl-223.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4232ms ease-in-out;
  
  }
  .cl-223.reverse {
  
        transform: translate3d(-51px, 498px, -39px) rotate3d(-1.2,-1.8,1.2,-197deg);
  
           transition: transform 2297ms ease-in-out;
  }.cl-224 {
  
        transform: translate3d(241px, 177px, -34px) rotate3d(0.4,1.3,1.7,232deg);
           transition: transform 2893ms ease-in-out;  
  
  }  .cl-224.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4784ms ease-in-out;
  
  }
  .cl-224.reverse {
  
        transform: translate3d(-253px, 234px, -70px) rotate3d(1.7,-1.8,1.3,-258deg);
  
           transition: transform 1666ms ease-in-out;
  }.cl-225 {
  
        transform: translate3d(-309px, -205px, -417px) rotate3d(1.4,-1.9,0.5,-227deg);
           transition: transform 1158ms ease-in-out;  
  
  }  .cl-225.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5064ms ease-in-out;
  
  }
  .cl-225.reverse {
  
        transform: translate3d(-253px, -94px, -384px) rotate3d(-1.7,0.7,0.4,-336deg);
  
           transition: transform 1287ms ease-in-out;
  }.cl-226 {
  
        transform: translate3d(-195px, 324px, -378px) rotate3d(1.9,-1.2,0.9,26deg);
           transition: transform 5492ms ease-in-out;  
  
  }  .cl-226.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1233ms ease-in-out;
  
  }
  .cl-226.reverse {
  
        transform: translate3d(353px, -245px, 57px) rotate3d(0.3,0,0.1,13deg);
  
           transition: transform 3713ms ease-in-out;
  }.cl-227 {
  
        transform: translate3d(25px, -434px, 158px) rotate3d(-0.9,0.8,-2,14deg);
           transition: transform 2461ms ease-in-out;  
  
  }  .cl-227.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6119ms ease-in-out;
  
  }
  .cl-227.reverse {
  
        transform: translate3d(-263px, 384px, 100px) rotate3d(-0.9,0,-1,-26deg);
  
           transition: transform 5228ms ease-in-out;
  }.cl-228 {
  
        transform: translate3d(475px, 338px, -440px) rotate3d(0.7,1.2,0.7,-227deg);
           transition: transform 4560ms ease-in-out;  
  
  }  .cl-228.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3586ms ease-in-out;
  
  }
  .cl-228.reverse {
  
        transform: translate3d(29px, 121px, -408px) rotate3d(-0.5,0.5,-1.2,-137deg);
  
           transition: transform 6303ms ease-in-out;
  }.cl-229 {
  
        transform: translate3d(379px, -58px, 416px) rotate3d(-0.9,1.7,1,174deg);
           transition: transform 288ms ease-in-out;  
  
  }  .cl-229.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 317ms ease-in-out;
  
  }
  .cl-229.reverse {
  
        transform: translate3d(-73px, -260px, -30px) rotate3d(0.3,-1.1,-1.2,303deg);
  
           transition: transform 5220ms ease-in-out;
  }.cl-230 {
  
        transform: translate3d(33px, -467px, -179px) rotate3d(-0.1,-1.2,0.4,262deg);
           transition: transform 4329ms ease-in-out;  
  
  }  .cl-230.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-230.reverse {
  
        transform: translate3d(-254px, -20px, -185px) rotate3d(-0.8,-1.4,-1.9,-318deg);
  
           transition: transform 2975ms ease-in-out;
  }.cl-231 {
  
        transform: translate3d(496px, 448px, -63px) rotate3d(-1.8,0.5,-1.7,226deg);
           transition: transform 187ms ease-in-out;  
  
  }  .cl-231.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2497ms ease-in-out;
  
  }
  .cl-231.reverse {
  
        transform: translate3d(-417px, 213px, -470px) rotate3d(-1.2,-0.5,1,47deg);
  
           transition: transform 1058ms ease-in-out;
  }.cl-232 {
  
        transform: translate3d(-255px, 162px, 141px) rotate3d(1.1,1.3,1.8,-233deg);
           transition: transform 731ms ease-in-out;  
  
  }  .cl-232.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-232.reverse {
  
        transform: translate3d(257px, -208px, -320px) rotate3d(-2,1.3,-0.1,-251deg);
  
           transition: transform 5016ms ease-in-out;
  }.cl-233 {
  
        transform: translate3d(319px, 101px, -32px) rotate3d(0.1,1.1,0.7,-351deg);
           transition: transform 6020ms ease-in-out;  
  
  }  .cl-233.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5624ms ease-in-out;
  
  }
  .cl-233.reverse {
  
        transform: translate3d(-465px, -215px, 18px) rotate3d(1.7,0.3,0.5,-106deg);
  
           transition: transform 4390ms ease-in-out;
  }.cl-234 {
  
        transform: translate3d(126px, 209px, 449px) rotate3d(1.7,-0.5,0.8,-64deg);
           transition: transform 295ms ease-in-out;  
  
  }  .cl-234.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2010ms ease-in-out;
  
  }
  .cl-234.reverse {
  
        transform: translate3d(-48px, 237px, 230px) rotate3d(-2,-0.9,1.3,319deg);
  
           transition: transform 1677ms ease-in-out;
  }.cl-235 {
  
        transform: translate3d(-78px, 297px, 107px) rotate3d(-0.3,1.7,-0.8,-40deg);
           transition: transform 2112ms ease-in-out;  
  
  }  .cl-235.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 409ms ease-in-out;
  
  }
  .cl-235.reverse {
  
        transform: translate3d(-194px, -358px, 213px) rotate3d(1.5,-0.1,0.5,-64deg);
  
           transition: transform 4469ms ease-in-out;
  }.cl-236 {
  
        transform: translate3d(-348px, 459px, 362px) rotate3d(0.1,0.1,-1.8,-289deg);
           transition: transform 1321ms ease-in-out;  
  
  }  .cl-236.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6783ms ease-in-out;
  
  }
  .cl-236.reverse {
  
        transform: translate3d(-27px, 478px, 437px) rotate3d(-0.4,0,-1.7,106deg);
  
           transition: transform 2755ms ease-in-out;
  }.cl-237 {
  
        transform: translate3d(-305px, 448px, 281px) rotate3d(-1.6,-0.3,-1.4,-317deg);
           transition: transform 6651ms ease-in-out;  
  
  }  .cl-237.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1402ms ease-in-out;
  
  }
  .cl-237.reverse {
  
        transform: translate3d(39px, -268px, -297px) rotate3d(1.2,-0.3,-0.1,263deg);
  
           transition: transform 4745ms ease-in-out;
  }.cl-238 {
  
        transform: translate3d(-499px, 266px, -468px) rotate3d(0.1,-1.1,1.1,-38deg);
           transition: transform 478ms ease-in-out;  
  
  }  .cl-238.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3533ms ease-in-out;
  
  }
  .cl-238.reverse {
  
        transform: translate3d(-79px, -498px, -10px) rotate3d(0.3,-0.8,0.2,60deg);
  
           transition: transform 1767ms ease-in-out;
  }.cl-239 {
  
        transform: translate3d(-92px, 416px, 9px) rotate3d(1.1,-0.9,0.2,-277deg);
           transition: transform 362ms ease-in-out;  
  
  }  .cl-239.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3178ms ease-in-out;
  
  }
  .cl-239.reverse {
  
        transform: translate3d(438px, 30px, -485px) rotate3d(0.4,1.5,0.8,-282deg);
  
           transition: transform 7099ms ease-in-out;
  }.cl-240 {
  
        transform: translate3d(462px, 319px, 183px) rotate3d(1.6,0.9,0.5,-216deg);
           transition: transform 4931ms ease-in-out;  
  
  }  .cl-240.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5603ms ease-in-out;
  
  }
  .cl-240.reverse {
  
        transform: translate3d(-354px, -12px, 0px) rotate3d(1.7,-0.7,0.7,-224deg);
  
           transition: transform 6830ms ease-in-out;
  }.cl-241 {
  
        transform: translate3d(492px, 410px, 229px) rotate3d(-1.7,-0.6,1.8,94deg);
           transition: transform 6898ms ease-in-out;  
  
  }  .cl-241.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1904ms ease-in-out;
  
  }
  .cl-241.reverse {
  
        transform: translate3d(-284px, -413px, 291px) rotate3d(1.8,1.7,-0.1,-146deg);
  
           transition: transform 4102ms ease-in-out;
  }.cl-242 {
  
        transform: translate3d(438px, 408px, -279px) rotate3d(0.6,0.3,1.6,20deg);
           transition: transform 597ms ease-in-out;  
  
  }  .cl-242.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1317ms ease-in-out;
  
  }
  .cl-242.reverse {
  
        transform: translate3d(200px, -384px, 199px) rotate3d(1.1,0,0.8,-90deg);
  
           transition: transform 2748ms ease-in-out;
  }.cl-243 {
  
        transform: translate3d(-122px, -202px, -171px) rotate3d(-0.5,1.8,-1.7,-34deg);
           transition: transform 5325ms ease-in-out;  
  
  }  .cl-243.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5949ms ease-in-out;
  
  }
  .cl-243.reverse {
  
        transform: translate3d(-484px, -119px, 332px) rotate3d(0.1,1.4,-1.4,-197deg);
  
           transition: transform 1210ms ease-in-out;
  }.cl-244 {
  
        transform: translate3d(162px, 34px, 240px) rotate3d(0,1.8,-1.4,193deg);
           transition: transform 756ms ease-in-out;  
  
  }  .cl-244.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4195ms ease-in-out;
  
  }
  .cl-244.reverse {
  
        transform: translate3d(-244px, 166px, -191px) rotate3d(-1.1,-1.8,0,208deg);
  
           transition: transform 5611ms ease-in-out;
  }.cl-245 {
  
        transform: translate3d(-326px, 206px, 404px) rotate3d(-0.4,-0.7,-1.9,73deg);
           transition: transform 712ms ease-in-out;  
  
  }  .cl-245.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1873ms ease-in-out;
  
  }
  .cl-245.reverse {
  
        transform: translate3d(-169px, -179px, 265px) rotate3d(0,1.2,-1.1,-196deg);
  
           transition: transform 6962ms ease-in-out;
  }.cl-246 {
  
        transform: translate3d(446px, 159px, -195px) rotate3d(0.7,-1.3,1.6,-9deg);
           transition: transform 5974ms ease-in-out;  
  
  }  .cl-246.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6776ms ease-in-out;
  
  }
  .cl-246.reverse {
  
        transform: translate3d(-204px, -371px, 116px) rotate3d(1.1,0.8,0.1,93deg);
  
           transition: transform 7030ms ease-in-out;
  }.cl-247 {
  
        transform: translate3d(414px, -471px, -135px) rotate3d(0,0.3,0.7,-219deg);
           transition: transform 669ms ease-in-out;  
  
  }  .cl-247.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2514ms ease-in-out;
  
  }
  .cl-247.reverse {
  
        transform: translate3d(188px, -345px, -182px) rotate3d(1.3,-1.3,0.4,132deg);
  
           transition: transform 3782ms ease-in-out;
  }.cl-248 {
  
        transform: translate3d(395px, -213px, -146px) rotate3d(0.8,1.6,-0.2,-273deg);
           transition: transform 6497ms ease-in-out;  
  
  }  .cl-248.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2369ms ease-in-out;
  
  }
  .cl-248.reverse {
  
        transform: translate3d(427px, -208px, -203px) rotate3d(-1.6,-0.2,-1.3,-342deg);
  
           transition: transform 3227ms ease-in-out;
  }.cl-249 {
  
        transform: translate3d(54px, 224px, 63px) rotate3d(-0.5,1.3,1.1,46deg);
           transition: transform 3736ms ease-in-out;  
  
  }  .cl-249.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1239ms ease-in-out;
  
  }
  .cl-249.reverse {
  
        transform: translate3d(-489px, 76px, 147px) rotate3d(-1.8,-0.1,0.7,12deg);
  
           transition: transform 1938ms ease-in-out;
  }.cl-250 {
  
        transform: translate3d(-455px, -362px, 58px) rotate3d(-1.9,-1.1,-0.9,-326deg);
           transition: transform 6168ms ease-in-out;  
  
  }  .cl-250.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3322ms ease-in-out;
  
  }
  .cl-250.reverse {
  
        transform: translate3d(-237px, -428px, -469px) rotate3d(1,-0.6,-1.9,319deg);
  
           transition: transform 4054ms ease-in-out;
  }.cl-251 {
  
        transform: translate3d(394px, 485px, -351px) rotate3d(2,-1.3,0.7,64deg);
           transition: transform 1838ms ease-in-out;  
  
  }  .cl-251.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4199ms ease-in-out;
  
  }
  .cl-251.reverse {
  
        transform: translate3d(357px, 292px, 8px) rotate3d(-0.3,0.3,-1.4,181deg);
  
           transition: transform 6839ms ease-in-out;
  }.cl-252 {
  
        transform: translate3d(5px, 168px, 471px) rotate3d(0.4,0.4,-0.3,-278deg);
           transition: transform 1909ms ease-in-out;  
  
  }  .cl-252.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 921ms ease-in-out;
  
  }
  .cl-252.reverse {
  
        transform: translate3d(-167px, -381px, -175px) rotate3d(0.8,1,-1,59deg);
  
           transition: transform 7154ms ease-in-out;
  }.cl-253 {
  
        transform: translate3d(23px, -114px, 335px) rotate3d(1,-0.1,-1.3,-180deg);
           transition: transform 7198ms ease-in-out;  
  
  }  .cl-253.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5654ms ease-in-out;
  
  }
  .cl-253.reverse {
  
        transform: translate3d(169px, -373px, -332px) rotate3d(0.7,-0.9,-1.6,109deg);
  
           transition: transform 970ms ease-in-out;
  }.cl-254 {
  
        transform: translate3d(402px, -27px, 146px) rotate3d(-0.7,-1.2,1.8,-176deg);
           transition: transform 5282ms ease-in-out;  
  
  }  .cl-254.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6629ms ease-in-out;
  
  }
  .cl-254.reverse {
  
        transform: translate3d(-345px, -402px, -225px) rotate3d(1.2,-1.3,1.4,70deg);
  
           transition: transform 6391ms ease-in-out;
  }.cl-255 {
  
        transform: translate3d(421px, -68px, 475px) rotate3d(0.3,0.7,1.1,-59deg);
           transition: transform 5793ms ease-in-out;  
  
  }  .cl-255.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 603ms ease-in-out;
  
  }
  .cl-255.reverse {
  
        transform: translate3d(498px, -90px, 459px) rotate3d(-0.8,-0.4,0.4,317deg);
  
           transition: transform 1241ms ease-in-out;
  }.cl-256 {
  
        transform: translate3d(-425px, 456px, -254px) rotate3d(-1.5,1,-1.1,334deg);
           transition: transform 1016ms ease-in-out;  
  
  }  .cl-256.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6412ms ease-in-out;
  
  }
  .cl-256.reverse {
  
        transform: translate3d(-477px, 279px, 255px) rotate3d(-1.2,-1.4,2,74deg);
  
           transition: transform 2618ms ease-in-out;
  }.cl-257 {
  
        transform: translate3d(427px, 260px, -418px) rotate3d(-0.1,0.4,-0.9,-23deg);
           transition: transform 3700ms ease-in-out;  
  
  }  .cl-257.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4664ms ease-in-out;
  
  }
  .cl-257.reverse {
  
        transform: translate3d(-279px, -486px, 152px) rotate3d(0,-1.5,-0.4,-145deg);
  
           transition: transform 3675ms ease-in-out;
  }.cl-258 {
  
        transform: translate3d(167px, 52px, 243px) rotate3d(1.6,-1.5,0.6,312deg);
           transition: transform 6380ms ease-in-out;  
  
  }  .cl-258.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6926ms ease-in-out;
  
  }
  .cl-258.reverse {
  
        transform: translate3d(-294px, 132px, 459px) rotate3d(-1.1,1,-0.2,2deg);
  
           transition: transform 7600ms ease-in-out;
  }.cl-259 {
  
        transform: translate3d(-451px, 382px, -357px) rotate3d(-1.1,0.3,0,-155deg);
           transition: transform 3929ms ease-in-out;  
  
  }  .cl-259.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4137ms ease-in-out;
  
  }
  .cl-259.reverse {
  
        transform: translate3d(126px, -372px, 23px) rotate3d(-1.1,-1.9,1.9,-263deg);
  
           transition: transform 5262ms ease-in-out;
  }.cl-260 {
  
        transform: translate3d(-96px, -183px, -463px) rotate3d(-1.5,0.9,0.1,-99deg);
           transition: transform 5658ms ease-in-out;  
  
  }  .cl-260.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1724ms ease-in-out;
  
  }
  .cl-260.reverse {
  
        transform: translate3d(-237px, 242px, 487px) rotate3d(1.8,0.8,0.7,-6deg);
  
           transition: transform 430ms ease-in-out;
  }.cl-261 {
  
        transform: translate3d(-496px, 381px, -18px) rotate3d(-1.8,1.7,1.5,356deg);
           transition: transform 760ms ease-in-out;  
  
  }  .cl-261.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4181ms ease-in-out;
  
  }
  .cl-261.reverse {
  
        transform: translate3d(-458px, -434px, -115px) rotate3d(-0.3,-0.3,0.6,318deg);
  
           transition: transform 252ms ease-in-out;
  }.cl-262 {
  
        transform: translate3d(412px, 353px, 211px) rotate3d(0.8,-0.7,-1.7,-132deg);
           transition: transform 5960ms ease-in-out;  
  
  }  .cl-262.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7097ms ease-in-out;
  
  }
  .cl-262.reverse {
  
        transform: translate3d(445px, 440px, 244px) rotate3d(-1.5,-0.9,-1.7,-264deg);
  
           transition: transform 2715ms ease-in-out;
  }.cl-263 {
  
        transform: translate3d(330px, 37px, 401px) rotate3d(-0.7,0.6,1.7,-143deg);
           transition: transform 2519ms ease-in-out;  
  
  }  .cl-263.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 699ms ease-in-out;
  
  }
  .cl-263.reverse {
  
        transform: translate3d(-180px, 282px, 307px) rotate3d(2,-1.4,-1.7,-154deg);
  
           transition: transform 513ms ease-in-out;
  }.cl-264 {
  
        transform: translate3d(-402px, -151px, -421px) rotate3d(1,0.1,0.4,-133deg);
           transition: transform 6665ms ease-in-out;  
  
  }  .cl-264.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2617ms ease-in-out;
  
  }
  .cl-264.reverse {
  
        transform: translate3d(488px, 305px, -223px) rotate3d(-1.8,-0.5,-1.3,-291deg);
  
           transition: transform 2896ms ease-in-out;
  }.cl-265 {
  
        transform: translate3d(433px, 277px, 109px) rotate3d(0,-1.1,1.7,350deg);
           transition: transform 2458ms ease-in-out;  
  
  }  .cl-265.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 796ms ease-in-out;
  
  }
  .cl-265.reverse {
  
        transform: translate3d(242px, 210px, 394px) rotate3d(0,-1,-0.7,-314deg);
  
           transition: transform 652ms ease-in-out;
  }.cl-266 {
  
        transform: translate3d(189px, -24px, -198px) rotate3d(1.5,-0.4,0.2,-327deg);
           transition: transform 5628ms ease-in-out;  
  
  }  .cl-266.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6103ms ease-in-out;
  
  }
  .cl-266.reverse {
  
        transform: translate3d(10px, 365px, -349px) rotate3d(0,-1.8,-0.2,-210deg);
  
           transition: transform 2773ms ease-in-out;
  }.cl-267 {
  
        transform: translate3d(-409px, 380px, 99px) rotate3d(-1.9,-2,-1.4,-146deg);
           transition: transform 2271ms ease-in-out;  
  
  }  .cl-267.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4826ms ease-in-out;
  
  }
  .cl-267.reverse {
  
        transform: translate3d(-408px, 183px, -185px) rotate3d(-1.5,-1.3,1.7,102deg);
  
           transition: transform 2816ms ease-in-out;
  }.cl-268 {
  
        transform: translate3d(-392px, 434px, -301px) rotate3d(0.5,0.6,-1,-232deg);
           transition: transform 7719ms ease-in-out;  
  
  }  .cl-268.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2359ms ease-in-out;
  
  }
  .cl-268.reverse {
  
        transform: translate3d(-107px, -121px, 47px) rotate3d(-1.1,-0.3,1.7,74deg);
  
           transition: transform 1722ms ease-in-out;
  }.cl-269 {
  
        transform: translate3d(167px, 439px, -272px) rotate3d(-0.4,0.3,0.7,201deg);
           transition: transform 3742ms ease-in-out;  
  
  }  .cl-269.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5797ms ease-in-out;
  
  }
  .cl-269.reverse {
  
        transform: translate3d(438px, 380px, -271px) rotate3d(0,-1.4,0.9,-86deg);
  
           transition: transform 6991ms ease-in-out;
  }.cl-270 {
  
        transform: translate3d(13px, 176px, 174px) rotate3d(-1,1.4,1.6,299deg);
           transition: transform 2294ms ease-in-out;  
  
  }  .cl-270.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1368ms ease-in-out;
  
  }
  .cl-270.reverse {
  
        transform: translate3d(135px, 375px, 404px) rotate3d(1.4,-1,-0.5,235deg);
  
           transition: transform 2543ms ease-in-out;
  }.cl-271 {
  
        transform: translate3d(441px, -128px, -190px) rotate3d(0.2,1.2,-1.1,-315deg);
           transition: transform 6732ms ease-in-out;  
  
  }  .cl-271.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2722ms ease-in-out;
  
  }
  .cl-271.reverse {
  
        transform: translate3d(-362px, 496px, -328px) rotate3d(-1.9,0.8,-1.4,69deg);
  
           transition: transform 6560ms ease-in-out;
  }.cl-272 {
  
        transform: translate3d(-334px, 25px, -73px) rotate3d(0.7,1.9,1.9,-65deg);
           transition: transform 1112ms ease-in-out;  
  
  }  .cl-272.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1526ms ease-in-out;
  
  }
  .cl-272.reverse {
  
        transform: translate3d(207px, 343px, 389px) rotate3d(1.4,0,1.1,-302deg);
  
           transition: transform 5593ms ease-in-out;
  }.cl-273 {
  
        transform: translate3d(46px, -122px, -109px) rotate3d(1,-1.8,1.6,166deg);
           transition: transform 7375ms ease-in-out;  
  
  }  .cl-273.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4488ms ease-in-out;
  
  }
  .cl-273.reverse {
  
        transform: translate3d(-482px, 122px, -300px) rotate3d(-0.7,1.4,-1.2,-196deg);
  
           transition: transform 7879ms ease-in-out;
  }.cl-274 {
  
        transform: translate3d(396px, 493px, 185px) rotate3d(-0.3,1.4,1.8,-144deg);
           transition: transform 7933ms ease-in-out;  
  
  }  .cl-274.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2246ms ease-in-out;
  
  }
  .cl-274.reverse {
  
        transform: translate3d(-195px, 254px, -336px) rotate3d(-1.1,-0.5,1.5,-67deg);
  
           transition: transform 5679ms ease-in-out;
  }.cl-275 {
  
        transform: translate3d(-436px, -63px, 241px) rotate3d(0.3,0.3,-0.9,23deg);
           transition: transform 2122ms ease-in-out;  
  
  }  .cl-275.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 510ms ease-in-out;
  
  }
  .cl-275.reverse {
  
        transform: translate3d(-294px, 342px, -26px) rotate3d(-0.8,-0.8,1.4,31deg);
  
           transition: transform 2784ms ease-in-out;
  }.cl-276 {
  
        transform: translate3d(219px, 201px, 227px) rotate3d(1.5,1.7,-1.3,-60deg);
           transition: transform 7657ms ease-in-out;  
  
  }  .cl-276.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7468ms ease-in-out;
  
  }
  .cl-276.reverse {
  
        transform: translate3d(-265px, -19px, 249px) rotate3d(1.7,1.7,1.5,30deg);
  
           transition: transform 7302ms ease-in-out;
  }.cl-277 {
  
        transform: translate3d(-434px, 310px, -295px) rotate3d(1.7,0.1,-0.1,212deg);
           transition: transform 4557ms ease-in-out;  
  
  }  .cl-277.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2057ms ease-in-out;
  
  }
  .cl-277.reverse {
  
        transform: translate3d(288px, -99px, -16px) rotate3d(0.3,-1.8,0.3,-325deg);
  
           transition: transform 2127ms ease-in-out;
  }.cl-278 {
  
        transform: translate3d(-159px, -354px, 280px) rotate3d(0,1.5,-1.5,341deg);
           transition: transform 1102ms ease-in-out;  
  
  }  .cl-278.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 716ms ease-in-out;
  
  }
  .cl-278.reverse {
  
        transform: translate3d(123px, -64px, -465px) rotate3d(0.2,-0.6,1.5,136deg);
  
           transition: transform 6146ms ease-in-out;
  }.cl-279 {
  
        transform: translate3d(117px, 37px, -391px) rotate3d(0,1.3,-1.9,77deg);
           transition: transform 4854ms ease-in-out;  
  
  }  .cl-279.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3255ms ease-in-out;
  
  }
  .cl-279.reverse {
  
        transform: translate3d(144px, -159px, 368px) rotate3d(-1.2,-0.7,0.8,350deg);
  
           transition: transform 2656ms ease-in-out;
  }.cl-280 {
  
        transform: translate3d(-129px, -130px, 264px) rotate3d(-1,0.4,-0.9,268deg);
           transition: transform 1638ms ease-in-out;  
  
  }  .cl-280.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2527ms ease-in-out;
  
  }
  .cl-280.reverse {
  
        transform: translate3d(63px, -305px, 101px) rotate3d(1.1,0.6,-1.5,-140deg);
  
           transition: transform 6918ms ease-in-out;
  }.cl-281 {
  
        transform: translate3d(77px, -431px, 383px) rotate3d(1.1,1.4,-1.2,51deg);
           transition: transform 7058ms ease-in-out;  
  
  }  .cl-281.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4371ms ease-in-out;
  
  }
  .cl-281.reverse {
  
        transform: translate3d(273px, -463px, 286px) rotate3d(-0.2,1.2,-1.9,343deg);
  
           transition: transform 6208ms ease-in-out;
  }.cl-282 {
  
        transform: translate3d(314px, -183px, 312px) rotate3d(1,1,-1.6,-152deg);
           transition: transform 3198ms ease-in-out;  
  
  }  .cl-282.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 874ms ease-in-out;
  
  }
  .cl-282.reverse {
  
        transform: translate3d(331px, 269px, 169px) rotate3d(1.7,-1.8,-1.3,49deg);
  
           transition: transform 3214ms ease-in-out;
  }.cl-283 {
  
        transform: translate3d(-452px, -124px, 342px) rotate3d(-1.9,0.3,-1.6,-14deg);
           transition: transform 1109ms ease-in-out;  
  
  }  .cl-283.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3455ms ease-in-out;
  
  }
  .cl-283.reverse {
  
        transform: translate3d(379px, 316px, -208px) rotate3d(-1.2,-1.4,-0.8,-250deg);
  
           transition: transform 2555ms ease-in-out;
  }.cl-284 {
  
        transform: translate3d(-295px, -401px, -454px) rotate3d(0.6,0.7,0.9,308deg);
           transition: transform 5155ms ease-in-out;  
  
  }  .cl-284.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2658ms ease-in-out;
  
  }
  .cl-284.reverse {
  
        transform: translate3d(-356px, 110px, -345px) rotate3d(-1.2,1.3,-0.2,123deg);
  
           transition: transform 5575ms ease-in-out;
  }.cl-285 {
  
        transform: translate3d(-377px, 323px, -105px) rotate3d(-0.2,0.4,0.4,-153deg);
           transition: transform 128ms ease-in-out;  
  
  }  .cl-285.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3469ms ease-in-out;
  
  }
  .cl-285.reverse {
  
        transform: translate3d(367px, 260px, -64px) rotate3d(-1.2,1.9,1.8,-205deg);
  
           transition: transform 5608ms ease-in-out;
  }.cl-286 {
  
        transform: translate3d(-182px, -279px, 165px) rotate3d(-1,1,1.7,-218deg);
           transition: transform 5055ms ease-in-out;  
  
  }  .cl-286.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6168ms ease-in-out;
  
  }
  .cl-286.reverse {
  
        transform: translate3d(156px, 287px, 396px) rotate3d(0.9,-1.1,0.6,158deg);
  
           transition: transform 5527ms ease-in-out;
  }.cl-287 {
  
        transform: translate3d(462px, 327px, -133px) rotate3d(0.5,0,0.4,-79deg);
           transition: transform 6845ms ease-in-out;  
  
  }  .cl-287.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8265ms ease-in-out;
  
  }
  .cl-287.reverse {
  
        transform: translate3d(-341px, -177px, 428px) rotate3d(-1.8,-1.1,1.1,84deg);
  
           transition: transform 8154ms ease-in-out;
  }.cl-288 {
  
        transform: translate3d(190px, -2px, 303px) rotate3d(0,1.7,-1.8,-46deg);
           transition: transform 4106ms ease-in-out;  
  
  }  .cl-288.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 128ms ease-in-out;
  
  }
  .cl-288.reverse {
  
        transform: translate3d(-44px, -496px, 146px) rotate3d(-0.2,-1,0.1,34deg);
  
           transition: transform 510ms ease-in-out;
  }.cl-289 {
  
        transform: translate3d(294px, -61px, 249px) rotate3d(1.5,-1,0,-251deg);
           transition: transform 2199ms ease-in-out;  
  
  }  .cl-289.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1761ms ease-in-out;
  
  }
  .cl-289.reverse {
  
        transform: translate3d(-136px, -19px, -307px) rotate3d(-1.9,1.3,0.3,29deg);
  
           transition: transform 589ms ease-in-out;
  }.cl-290 {
  
        transform: translate3d(-435px, -124px, -351px) rotate3d(0.5,1.7,-1.2,49deg);
           transition: transform 754ms ease-in-out;  
  
  }  .cl-290.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3505ms ease-in-out;
  
  }
  .cl-290.reverse {
  
        transform: translate3d(328px, 245px, -495px) rotate3d(2,0.9,1.3,139deg);
  
           transition: transform 1790ms ease-in-out;
  }.cl-291 {
  
        transform: translate3d(-43px, -309px, 46px) rotate3d(-0.9,0.9,-0.7,258deg);
           transition: transform 2181ms ease-in-out;  
  
  }  .cl-291.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6051ms ease-in-out;
  
  }
  .cl-291.reverse {
  
        transform: translate3d(-168px, -94px, -231px) rotate3d(0.3,0.3,0.1,-254deg);
  
           transition: transform 453ms ease-in-out;
  }.cl-292 {
  
        transform: translate3d(-400px, 311px, 387px) rotate3d(-0.2,1.5,-1.9,-54deg);
           transition: transform 4526ms ease-in-out;  
  
  }  .cl-292.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1345ms ease-in-out;
  
  }
  .cl-292.reverse {
  
        transform: translate3d(-415px, 50px, 467px) rotate3d(1.4,-1.4,0.8,313deg);
  
           transition: transform 4365ms ease-in-out;
  }.cl-293 {
  
        transform: translate3d(118px, 392px, -451px) rotate3d(0.3,-0.1,0,-340deg);
           transition: transform 5475ms ease-in-out;  
  
  }  .cl-293.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7939ms ease-in-out;
  
  }
  .cl-293.reverse {
  
        transform: translate3d(76px, -293px, -379px) rotate3d(1,-1.2,-0.3,218deg);
  
           transition: transform 694ms ease-in-out;
  }.cl-294 {
  
        transform: translate3d(251px, 233px, -117px) rotate3d(-0.5,0.9,0.5,-305deg);
           transition: transform 3936ms ease-in-out;  
  
  }  .cl-294.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7055ms ease-in-out;
  
  }
  .cl-294.reverse {
  
        transform: translate3d(449px, -367px, 329px) rotate3d(0.3,-1.6,-0.7,-329deg);
  
           transition: transform 2212ms ease-in-out;
  }.cl-295 {
  
        transform: translate3d(411px, 258px, -414px) rotate3d(1.3,-0.4,-1.5,307deg);
           transition: transform 2780ms ease-in-out;  
  
  }  .cl-295.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4465ms ease-in-out;
  
  }
  .cl-295.reverse {
  
        transform: translate3d(-379px, 333px, 67px) rotate3d(0.4,-1.7,-0.4,100deg);
  
           transition: transform 807ms ease-in-out;
  }.cl-296 {
  
        transform: translate3d(344px, 223px, -19px) rotate3d(1.2,1.7,1.3,-331deg);
           transition: transform 8332ms ease-in-out;  
  
  }  .cl-296.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1034ms ease-in-out;
  
  }
  .cl-296.reverse {
  
        transform: translate3d(53px, 153px, -358px) rotate3d(-1,0.3,1.4,341deg);
  
           transition: transform 7374ms ease-in-out;
  }.cl-297 {
  
        transform: translate3d(-299px, -106px, 296px) rotate3d(-0.9,1,1.4,209deg);
           transition: transform 5677ms ease-in-out;  
  
  }  .cl-297.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8134ms ease-in-out;
  
  }
  .cl-297.reverse {
  
        transform: translate3d(-368px, 90px, 495px) rotate3d(1.6,1.9,0.4,56deg);
  
           transition: transform 1203ms ease-in-out;
  }.cl-298 {
  
        transform: translate3d(438px, -187px, 337px) rotate3d(1.6,-0.1,0.4,181deg);
           transition: transform 2231ms ease-in-out;  
  
  }  .cl-298.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3065ms ease-in-out;
  
  }
  .cl-298.reverse {
  
        transform: translate3d(260px, 459px, -137px) rotate3d(0.5,0,0.9,-175deg);
  
           transition: transform 1005ms ease-in-out;
  }.cl-299 {
  
        transform: translate3d(408px, -344px, 335px) rotate3d(-1.2,1.9,1.4,-80deg);
           transition: transform 7283ms ease-in-out;  
  
  }  .cl-299.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2064ms ease-in-out;
  
  }
  .cl-299.reverse {
  
        transform: translate3d(427px, 297px, -236px) rotate3d(0.4,-1.5,1.6,-299deg);
  
           transition: transform 3857ms ease-in-out;
  }.cl-300 {
  
        transform: translate3d(181px, -393px, -31px) rotate3d(-0.8,0.3,-1.3,349deg);
           transition: transform 4557ms ease-in-out;  
  
  }  .cl-300.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7889ms ease-in-out;
  
  }
  .cl-300.reverse {
  
        transform: translate3d(-237px, -89px, 198px) rotate3d(1.8,-0.9,0.8,297deg);
  
           transition: transform 813ms ease-in-out;
  }.cl-301 {
  
        transform: translate3d(-408px, -22px, 354px) rotate3d(1.5,-1.8,-0.5,-281deg);
           transition: transform 5931ms ease-in-out;  
  
  }  .cl-301.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2202ms ease-in-out;
  
  }
  .cl-301.reverse {
  
        transform: translate3d(463px, -38px, 39px) rotate3d(0.4,1,1.7,-322deg);
  
           transition: transform 5602ms ease-in-out;
  }.cl-302 {
  
        transform: translate3d(175px, -457px, -46px) rotate3d(-1.2,-1.8,0.7,-232deg);
           transition: transform 5337ms ease-in-out;  
  
  }  .cl-302.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7236ms ease-in-out;
  
  }
  .cl-302.reverse {
  
        transform: translate3d(-232px, 332px, 390px) rotate3d(-1.4,-0.7,0.3,-320deg);
  
           transition: transform 3218ms ease-in-out;
  }.cl-303 {
  
        transform: translate3d(358px, -119px, 12px) rotate3d(0.7,-0.8,-1.7,253deg);
           transition: transform 5523ms ease-in-out;  
  
  }  .cl-303.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5030ms ease-in-out;
  
  }
  .cl-303.reverse {
  
        transform: translate3d(-150px, 460px, 368px) rotate3d(1.7,-1.1,1,127deg);
  
           transition: transform 5783ms ease-in-out;
  }.cl-304 {
  
        transform: translate3d(-189px, 268px, -87px) rotate3d(-1.6,-0.4,-1.8,-67deg);
           transition: transform 6768ms ease-in-out;  
  
  }  .cl-304.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8299ms ease-in-out;
  
  }
  .cl-304.reverse {
  
        transform: translate3d(337px, 447px, 413px) rotate3d(-1.4,-0.3,-1.7,-281deg);
  
           transition: transform 5138ms ease-in-out;
  }.cl-305 {
  
        transform: translate3d(-211px, -438px, 270px) rotate3d(-1.8,-0.7,1.5,-296deg);
           transition: transform 5504ms ease-in-out;  
  
  }  .cl-305.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4061ms ease-in-out;
  
  }
  .cl-305.reverse {
  
        transform: translate3d(480px, -352px, 101px) rotate3d(1.6,1.7,0.8,68deg);
  
           transition: transform 5543ms ease-in-out;
  }.cl-306 {
  
        transform: translate3d(-149px, 345px, 366px) rotate3d(-1.3,0.1,-1.9,17deg);
           transition: transform 5989ms ease-in-out;  
  
  }  .cl-306.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5745ms ease-in-out;
  
  }
  .cl-306.reverse {
  
        transform: translate3d(353px, 23px, -308px) rotate3d(0.1,-0.2,-0.4,109deg);
  
           transition: transform 6232ms ease-in-out;
  }.cl-307 {
  
        transform: translate3d(-252px, 289px, -245px) rotate3d(-0.6,-0.4,0.9,-152deg);
           transition: transform 7081ms ease-in-out;  
  
  }  .cl-307.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6790ms ease-in-out;
  
  }
  .cl-307.reverse {
  
        transform: translate3d(188px, -454px, -396px) rotate3d(1.7,-1.5,1.2,-252deg);
  
           transition: transform 3325ms ease-in-out;
  }.cl-308 {
  
        transform: translate3d(161px, 324px, -269px) rotate3d(0.9,-0.9,0.5,-277deg);
           transition: transform 4869ms ease-in-out;  
  
  }  .cl-308.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1040ms ease-in-out;
  
  }
  .cl-308.reverse {
  
        transform: translate3d(-365px, -341px, 456px) rotate3d(0.2,0.3,1.5,-32deg);
  
           transition: transform 1167ms ease-in-out;
  }.cl-309 {
  
        transform: translate3d(-271px, 227px, -98px) rotate3d(2,-0.2,-1.7,-331deg);
           transition: transform 8071ms ease-in-out;  
  
  }  .cl-309.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8192ms ease-in-out;
  
  }
  .cl-309.reverse {
  
        transform: translate3d(378px, -379px, -375px) rotate3d(-0.5,0.2,0.6,-357deg);
  
           transition: transform 8451ms ease-in-out;
  }.cl-310 {
  
        transform: translate3d(-221px, -300px, -233px) rotate3d(1.9,0,-1.3,302deg);
           transition: transform 1738ms ease-in-out;  
  
  }  .cl-310.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4819ms ease-in-out;
  
  }
  .cl-310.reverse {
  
        transform: translate3d(-161px, -253px, 210px) rotate3d(0.4,0.9,-0.8,189deg);
  
           transition: transform 6538ms ease-in-out;
  }.cl-311 {
  
        transform: translate3d(419px, -382px, 68px) rotate3d(1.5,0.4,-0.9,228deg);
           transition: transform 8194ms ease-in-out;  
  
  }  .cl-311.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5854ms ease-in-out;
  
  }
  .cl-311.reverse {
  
        transform: translate3d(-160px, 152px, -445px) rotate3d(-1.9,-0.5,0.5,203deg);
  
           transition: transform 8927ms ease-in-out;
  }.cl-312 {
  
        transform: translate3d(163px, -250px, 293px) rotate3d(-0.1,1.8,0.4,-212deg);
           transition: transform 6795ms ease-in-out;  
  
  }  .cl-312.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9031ms ease-in-out;
  
  }
  .cl-312.reverse {
  
        transform: translate3d(32px, 44px, -399px) rotate3d(0.6,1,-0.8,-263deg);
  
           transition: transform 7441ms ease-in-out;
  }.cl-313 {
  
        transform: translate3d(428px, 381px, -369px) rotate3d(-1.9,0,1.3,293deg);
           transition: transform 4296ms ease-in-out;  
  
  }  .cl-313.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4311ms ease-in-out;
  
  }
  .cl-313.reverse {
  
        transform: translate3d(394px, -37px, 465px) rotate3d(0.8,1.6,-0.6,215deg);
  
           transition: transform 2683ms ease-in-out;
  }.cl-314 {
  
        transform: translate3d(336px, 344px, 495px) rotate3d(1.8,0.7,-0.1,-238deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-314.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8822ms ease-in-out;
  
  }
  .cl-314.reverse {
  
        transform: translate3d(338px, -307px, -242px) rotate3d(0.4,-0.2,0.6,-231deg);
  
           transition: transform 5463ms ease-in-out;
  }.cl-315 {
  
        transform: translate3d(160px, -237px, -221px) rotate3d(-1.9,0.2,0.1,12deg);
           transition: transform 3785ms ease-in-out;  
  
  }  .cl-315.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7853ms ease-in-out;
  
  }
  .cl-315.reverse {
  
        transform: translate3d(-267px, 242px, -200px) rotate3d(-0.9,0.8,-0.4,82deg);
  
           transition: transform 3338ms ease-in-out;
  }.cl-316 {
  
        transform: translate3d(308px, -386px, -424px) rotate3d(0.4,0.5,-0.3,54deg);
           transition: transform 7623ms ease-in-out;  
  
  }  .cl-316.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3945ms ease-in-out;
  
  }
  .cl-316.reverse {
  
        transform: translate3d(-203px, -49px, 435px) rotate3d(1.2,0.3,-0.6,-298deg);
  
           transition: transform 1031ms ease-in-out;
  }.cl-317 {
  
        transform: translate3d(-435px, -199px, -149px) rotate3d(0.3,-0.8,0.4,239deg);
           transition: transform 8785ms ease-in-out;  
  
  }  .cl-317.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2907ms ease-in-out;
  
  }
  .cl-317.reverse {
  
        transform: translate3d(-444px, 221px, 234px) rotate3d(1.4,0.9,0.6,55deg);
  
           transition: transform 7185ms ease-in-out;
  }.cl-318 {
  
        transform: translate3d(329px, -212px, 359px) rotate3d(1.8,-1.7,-0.2,112deg);
           transition: transform 9201ms ease-in-out;  
  
  }  .cl-318.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2880ms ease-in-out;
  
  }
  .cl-318.reverse {
  
        transform: translate3d(-402px, -321px, -172px) rotate3d(0,-0.1,0.2,207deg);
  
           transition: transform 2973ms ease-in-out;
  }.cl-319 {
  
        transform: translate3d(249px, -88px, 119px) rotate3d(0.8,0.1,-0.2,49deg);
           transition: transform 2971ms ease-in-out;  
  
  }  .cl-319.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8844ms ease-in-out;
  
  }
  .cl-319.reverse {
  
        transform: translate3d(-146px, 35px, -202px) rotate3d(0.2,-0.8,1.7,-330deg);
  
           transition: transform 4010ms ease-in-out;
  }.cl-320 {
  
        transform: translate3d(184px, 139px, 399px) rotate3d(1.1,-1.4,-0.6,136deg);
           transition: transform 3833ms ease-in-out;  
  
  }  .cl-320.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2564ms ease-in-out;
  
  }
  .cl-320.reverse {
  
        transform: translate3d(318px, -166px, 177px) rotate3d(1.2,-0.2,-0.2,73deg);
  
           transition: transform 7259ms ease-in-out;
  }.cl-321 {
  
        transform: translate3d(-299px, 95px, 493px) rotate3d(0.9,1.2,-1.1,55deg);
           transition: transform 8235ms ease-in-out;  
  
  }  .cl-321.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8199ms ease-in-out;
  
  }
  .cl-321.reverse {
  
        transform: translate3d(438px, 428px, -495px) rotate3d(-1.2,1.5,0.2,-335deg);
  
           transition: transform 4039ms ease-in-out;
  }.cl-322 {
  
        transform: translate3d(-113px, -321px, 295px) rotate3d(-0.5,-0.5,-1.2,-155deg);
           transition: transform 8553ms ease-in-out;  
  
  }  .cl-322.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4086ms ease-in-out;
  
  }
  .cl-322.reverse {
  
        transform: translate3d(64px, -255px, -230px) rotate3d(-0.6,-1,0.8,44deg);
  
           transition: transform 2266ms ease-in-out;
  }.cl-323 {
  
        transform: translate3d(-188px, 1px, 304px) rotate3d(-0.8,-0.8,1,-128deg);
           transition: transform 7827ms ease-in-out;  
  
  }  .cl-323.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5205ms ease-in-out;
  
  }
  .cl-323.reverse {
  
        transform: translate3d(219px, -344px, 345px) rotate3d(-0.6,2,-0.8,320deg);
  
           transition: transform 4518ms ease-in-out;
  }.cl-324 {
  
        transform: translate3d(-342px, -246px, 346px) rotate3d(-0.2,-0.7,-1,-324deg);
           transition: transform 6804ms ease-in-out;  
  
  }  .cl-324.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7905ms ease-in-out;
  
  }
  .cl-324.reverse {
  
        transform: translate3d(-271px, 154px, -480px) rotate3d(-0.2,-0.3,1.6,337deg);
  
           transition: transform 1958ms ease-in-out;
  }.cl-325 {
  
        transform: translate3d(-408px, 255px, 373px) rotate3d(-1.5,-1.7,1.5,-256deg);
           transition: transform 9124ms ease-in-out;  
  
  }  .cl-325.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2084ms ease-in-out;
  
  }
  .cl-325.reverse {
  
        transform: translate3d(14px, 55px, 456px) rotate3d(-0.9,0.6,-0.2,46deg);
  
           transition: transform 5966ms ease-in-out;
  }.cl-326 {
  
        transform: translate3d(408px, 387px, 306px) rotate3d(-1.3,0.2,1.2,-311deg);
           transition: transform 627ms ease-in-out;  
  
  }  .cl-326.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3659ms ease-in-out;
  
  }
  .cl-326.reverse {
  
        transform: translate3d(376px, 78px, -39px) rotate3d(0.4,-0.8,-0.6,-56deg);
  
           transition: transform 2815ms ease-in-out;
  }.cl-327 {
  
        transform: translate3d(124px, 66px, -331px) rotate3d(-1.7,-1.5,-0.1,-290deg);
           transition: transform 8884ms ease-in-out;  
  
  }  .cl-327.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6206ms ease-in-out;
  
  }
  .cl-327.reverse {
  
        transform: translate3d(-161px, 320px, -475px) rotate3d(-1.3,0.3,1.7,144deg);
  
           transition: transform 8567ms ease-in-out;
  }.cl-328 {
  
        transform: translate3d(-461px, 348px, 244px) rotate3d(-1.2,1.3,-1.6,-249deg);
           transition: transform 222ms ease-in-out;  
  
  }  .cl-328.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 311ms ease-in-out;
  
  }
  .cl-328.reverse {
  
        transform: translate3d(497px, 271px, 230px) rotate3d(-0.4,1.5,-0.2,122deg);
  
           transition: transform 1859ms ease-in-out;
  }.cl-329 {
  
        transform: translate3d(-342px, 189px, 57px) rotate3d(1.6,1.1,-0.6,247deg);
           transition: transform 2450ms ease-in-out;  
  
  }  .cl-329.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1084ms ease-in-out;
  
  }
  .cl-329.reverse {
  
        transform: translate3d(-297px, -492px, -105px) rotate3d(-0.8,-1.8,1.6,89deg);
  
           transition: transform 7261ms ease-in-out;
  }.cl-330 {
  
        transform: translate3d(291px, -305px, -379px) rotate3d(-1,-1,0.2,68deg);
           transition: transform 7067ms ease-in-out;  
  
  }  .cl-330.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1176ms ease-in-out;
  
  }
  .cl-330.reverse {
  
        transform: translate3d(-249px, -290px, -73px) rotate3d(1.8,-0.3,1.1,-201deg);
  
           transition: transform 7379ms ease-in-out;
  }.cl-331 {
  
        transform: translate3d(123px, 118px, -492px) rotate3d(0.3,1.1,-0.5,-283deg);
           transition: transform 2469ms ease-in-out;  
  
  }  .cl-331.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3828ms ease-in-out;
  
  }
  .cl-331.reverse {
  
        transform: translate3d(-231px, -462px, -310px) rotate3d(-1.9,-1.3,1.6,-129deg);
  
           transition: transform 1624ms ease-in-out;
  }.cl-332 {
  
        transform: translate3d(347px, -70px, 438px) rotate3d(1.2,0.8,0.9,33deg);
           transition: transform 1794ms ease-in-out;  
  
  }  .cl-332.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4551ms ease-in-out;
  
  }
  .cl-332.reverse {
  
        transform: translate3d(228px, 270px, -255px) rotate3d(-0.2,-1.7,-0.5,-4deg);
  
           transition: transform 3097ms ease-in-out;
  }.cl-333 {
  
        transform: translate3d(-382px, 312px, -3px) rotate3d(1.7,0.6,-1.6,-282deg);
           transition: transform 4527ms ease-in-out;  
  
  }  .cl-333.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4949ms ease-in-out;
  
  }
  .cl-333.reverse {
  
        transform: translate3d(-371px, -404px, -482px) rotate3d(0.5,-0.7,0.9,309deg);
  
           transition: transform 6640ms ease-in-out;
  }.cl-334 {
  
        transform: translate3d(294px, 50px, 231px) rotate3d(-1.3,-0.3,-0.2,-249deg);
           transition: transform 1938ms ease-in-out;  
  
  }  .cl-334.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6158ms ease-in-out;
  
  }
  .cl-334.reverse {
  
        transform: translate3d(27px, 4px, -426px) rotate3d(1.1,-0.4,2,48deg);
  
           transition: transform 1201ms ease-in-out;
  }.cl-335 {
  
        transform: translate3d(-390px, 315px, -145px) rotate3d(1.8,-1.1,0.1,-124deg);
           transition: transform 2256ms ease-in-out;  
  
  }  .cl-335.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5026ms ease-in-out;
  
  }
  .cl-335.reverse {
  
        transform: translate3d(-179px, 449px, 233px) rotate3d(0.5,-1.8,-1.2,-78deg);
  
           transition: transform 9462ms ease-in-out;
  }.cl-336 {
  
        transform: translate3d(483px, -258px, -204px) rotate3d(-2,0,0.1,357deg);
           transition: transform 9497ms ease-in-out;  
  
  }  .cl-336.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9395ms ease-in-out;
  
  }
  .cl-336.reverse {
  
        transform: translate3d(-346px, -198px, 166px) rotate3d(-1.8,-0.1,-0.6,-128deg);
  
           transition: transform 1610ms ease-in-out;
  }.cl-337 {
  
        transform: translate3d(345px, -261px, 468px) rotate3d(0.9,-0.5,0.1,349deg);
           transition: transform 7382ms ease-in-out;  
  
  }  .cl-337.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1149ms ease-in-out;
  
  }
  .cl-337.reverse {
  
        transform: translate3d(300px, -489px, 379px) rotate3d(0.7,1.6,-1.6,84deg);
  
           transition: transform 3155ms ease-in-out;
  }.cl-338 {
  
        transform: translate3d(-191px, 84px, -217px) rotate3d(0.7,1.1,0.4,62deg);
           transition: transform 1494ms ease-in-out;  
  
  }  .cl-338.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9584ms ease-in-out;
  
  }
  .cl-338.reverse {
  
        transform: translate3d(-328px, 248px, 19px) rotate3d(2,0,-1.5,-301deg);
  
           transition: transform 8150ms ease-in-out;
  }.cl-339 {
  
        transform: translate3d(402px, 476px, -89px) rotate3d(0.5,-0.5,1.1,105deg);
           transition: transform 3534ms ease-in-out;  
  
  }  .cl-339.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4849ms ease-in-out;
  
  }
  .cl-339.reverse {
  
        transform: translate3d(284px, 131px, -454px) rotate3d(1.3,-1.5,-0.5,165deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-340 {
  
        transform: translate3d(-213px, -169px, 456px) rotate3d(0.5,1.5,1.5,226deg);
           transition: transform 3423ms ease-in-out;  
  
  }  .cl-340.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4190ms ease-in-out;
  
  }
  .cl-340.reverse {
  
        transform: translate3d(223px, -288px, 425px) rotate3d(-1,-1.2,1.8,213deg);
  
           transition: transform 4760ms ease-in-out;
  }.cl-341 {
  
        transform: translate3d(-116px, 444px, 456px) rotate3d(0,-1.9,1.5,-126deg);
           transition: transform 4470ms ease-in-out;  
  
  }  .cl-341.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 481ms ease-in-out;
  
  }
  .cl-341.reverse {
  
        transform: translate3d(-450px, 437px, -417px) rotate3d(1.9,0.2,-0.8,-338deg);
  
           transition: transform 5674ms ease-in-out;
  }.cl-342 {
  
        transform: translate3d(-133px, 202px, 108px) rotate3d(0.1,1.4,0.5,252deg);
           transition: transform 4049ms ease-in-out;  
  
  }  .cl-342.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3361ms ease-in-out;
  
  }
  .cl-342.reverse {
  
        transform: translate3d(251px, 471px, -297px) rotate3d(-0.5,0.3,-0.7,-339deg);
  
           transition: transform 7211ms ease-in-out;
  }.cl-343 {
  
        transform: translate3d(20px, 495px, 410px) rotate3d(1,-0.8,1.6,-277deg);
           transition: transform 2610ms ease-in-out;  
  
  }  .cl-343.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8372ms ease-in-out;
  
  }
  .cl-343.reverse {
  
        transform: translate3d(-440px, -27px, 428px) rotate3d(-0.4,-0.5,0.8,207deg);
  
           transition: transform 1914ms ease-in-out;
  }.cl-344 {
  
        transform: translate3d(109px, 462px, 378px) rotate3d(1.6,0.5,1.6,-324deg);
           transition: transform 5238ms ease-in-out;  
  
  }  .cl-344.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9017ms ease-in-out;
  
  }
  .cl-344.reverse {
  
        transform: translate3d(394px, -119px, -379px) rotate3d(-1.6,0.3,-1.9,-332deg);
  
           transition: transform 3601ms ease-in-out;
  }.cl-345 {
  
        transform: translate3d(-279px, 243px, -500px) rotate3d(-0.2,-0.2,-0.3,-23deg);
           transition: transform 5910ms ease-in-out;  
  
  }  .cl-345.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4136ms ease-in-out;
  
  }
  .cl-345.reverse {
  
        transform: translate3d(-189px, -206px, 397px) rotate3d(1.1,0.5,-1,-195deg);
  
           transition: transform 2936ms ease-in-out;
  }.cl-346 {
  
        transform: translate3d(264px, -136px, -382px) rotate3d(1.6,-1.4,0.3,-263deg);
           transition: transform 2963ms ease-in-out;  
  
  }  .cl-346.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9748ms ease-in-out;
  
  }
  .cl-346.reverse {
  
        transform: translate3d(250px, -318px, 115px) rotate3d(-1.8,-0.4,-1,-319deg);
  
           transition: transform 7882ms ease-in-out;
  }.cl-347 {
  
        transform: translate3d(-284px, 158px, 426px) rotate3d(1.1,-0.8,0.8,270deg);
           transition: transform 4937ms ease-in-out;  
  
  }  .cl-347.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4678ms ease-in-out;
  
  }
  .cl-347.reverse {
  
        transform: translate3d(449px, -27px, 358px) rotate3d(-1.3,1.5,-1.6,-84deg);
  
           transition: transform 6161ms ease-in-out;
  }.cl-348 {
  
        transform: translate3d(-43px, 221px, 130px) rotate3d(-0.5,-1.1,-0.6,88deg);
           transition: transform 2000ms ease-in-out;  
  
  }  .cl-348.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7506ms ease-in-out;
  
  }
  .cl-348.reverse {
  
        transform: translate3d(362px, -318px, 62px) rotate3d(1.4,-1,1.5,-57deg);
  
           transition: transform 4948ms ease-in-out;
  }.cl-349 {
  
        transform: translate3d(356px, -434px, -479px) rotate3d(1.2,-1.6,-1.3,261deg);
           transition: transform 4125ms ease-in-out;  
  
  }  .cl-349.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1648ms ease-in-out;
  
  }
  .cl-349.reverse {
  
        transform: translate3d(272px, -91px, -13px) rotate3d(-1.5,-1,1.4,120deg);
  
           transition: transform 3962ms ease-in-out;
  }.cl-350 {
  
        transform: translate3d(-479px, 74px, -461px) rotate3d(0.6,-1.4,1.8,142deg);
           transition: transform 8795ms ease-in-out;  
  
  }  .cl-350.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7651ms ease-in-out;
  
  }
  .cl-350.reverse {
  
        transform: translate3d(134px, 36px, -169px) rotate3d(-1.7,0.1,-1.6,-139deg);
  
           transition: transform 6988ms ease-in-out;
  }.cl-351 {
  
        transform: translate3d(139px, 61px, 317px) rotate3d(-1.2,1.6,-1.4,241deg);
           transition: transform 1405ms ease-in-out;  
  
  }  .cl-351.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8224ms ease-in-out;
  
  }
  .cl-351.reverse {
  
        transform: translate3d(219px, 230px, -273px) rotate3d(-0.3,1.7,-1.4,-129deg);
  
           transition: transform 4331ms ease-in-out;
  }.cl-352 {
  
        transform: translate3d(256px, 64px, 495px) rotate3d(1.3,-0.4,-2,191deg);
           transition: transform 7099ms ease-in-out;  
  
  }  .cl-352.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9680ms ease-in-out;
  
  }
  .cl-352.reverse {
  
        transform: translate3d(255px, -435px, 108px) rotate3d(0.8,-0.7,0.4,-312deg);
  
           transition: transform 4874ms ease-in-out;
  }.cl-353 {
  
        transform: translate3d(-142px, -438px, 185px) rotate3d(0.5,-1.6,-1.5,278deg);
           transition: transform 3950ms ease-in-out;  
  
  }  .cl-353.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8229ms ease-in-out;
  
  }
  .cl-353.reverse {
  
        transform: translate3d(-140px, -435px, 414px) rotate3d(-1.6,-0.9,1.9,-17deg);
  
           transition: transform 315ms ease-in-out;
  }.cl-354 {
  
        transform: translate3d(-447px, 68px, -403px) rotate3d(-0.2,-0.1,0.4,110deg);
           transition: transform 8490ms ease-in-out;  
  
  }  .cl-354.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 914ms ease-in-out;
  
  }
  .cl-354.reverse {
  
        transform: translate3d(401px, -389px, -134px) rotate3d(-0.9,-0.5,0.7,130deg);
  
           transition: transform 5451ms ease-in-out;
  }.cl-355 {
  
        transform: translate3d(321px, 394px, 47px) rotate3d(-1.4,0,-0.9,-298deg);
           transition: transform 4898ms ease-in-out;  
  
  }  .cl-355.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4799ms ease-in-out;
  
  }
  .cl-355.reverse {
  
        transform: translate3d(452px, -20px, -123px) rotate3d(1.2,-1.7,-1.6,-85deg);
  
           transition: transform 8496ms ease-in-out;
  }.cl-356 {
  
        transform: translate3d(423px, -289px, -202px) rotate3d(-0.8,-1.5,-0.7,204deg);
           transition: transform 1198ms ease-in-out;  
  
  }  .cl-356.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7830ms ease-in-out;
  
  }
  .cl-356.reverse {
  
        transform: translate3d(98px, 280px, -337px) rotate3d(-1.3,-1.1,-1.9,359deg);
  
           transition: transform 1137ms ease-in-out;
  }.cl-357 {
  
        transform: translate3d(-364px, 194px, 152px) rotate3d(-1.4,0.5,-0.2,145deg);
           transition: transform 2277ms ease-in-out;  
  
  }  .cl-357.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3203ms ease-in-out;
  
  }
  .cl-357.reverse {
  
        transform: translate3d(-120px, 11px, 280px) rotate3d(0.4,-0.5,-0.8,211deg);
  
           transition: transform 10049ms ease-in-out;
  }.cl-358 {
  
        transform: translate3d(150px, -427px, -146px) rotate3d(1.2,-1.2,-0.1,267deg);
           transition: transform 391ms ease-in-out;  
  
  }  .cl-358.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1516ms ease-in-out;
  
  }
  .cl-358.reverse {
  
        transform: translate3d(-389px, -496px, -356px) rotate3d(-1.3,0.5,0.7,-116deg);
  
           transition: transform 3417ms ease-in-out;
  }.cl-359 {
  
        transform: translate3d(462px, -65px, 45px) rotate3d(-0.7,1.1,1.1,325deg);
           transition: transform 7227ms ease-in-out;  
  
  }  .cl-359.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5835ms ease-in-out;
  
  }
  .cl-359.reverse {
  
        transform: translate3d(42px, -388px, 92px) rotate3d(0.7,-0.7,0.3,208deg);
  
           transition: transform 5435ms ease-in-out;
  }.cl-360 {
  
        transform: translate3d(-45px, -381px, -182px) rotate3d(-2,1,-0.5,-69deg);
           transition: transform 5026ms ease-in-out;  
  
  }  .cl-360.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5654ms ease-in-out;
  
  }
  .cl-360.reverse {
  
        transform: translate3d(194px, -499px, 107px) rotate3d(0.8,-0.5,1.8,48deg);
  
           transition: transform 4772ms ease-in-out;
  }.cl-361 {
  
        transform: translate3d(468px, 368px, 300px) rotate3d(-0.5,-0.8,-1.2,9deg);
           transition: transform 6703ms ease-in-out;  
  
  }  .cl-361.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3097ms ease-in-out;
  
  }
  .cl-361.reverse {
  
        transform: translate3d(-57px, -262px, 152px) rotate3d(-0.2,-1.6,-1.7,352deg);
  
           transition: transform 9564ms ease-in-out;
  }.cl-362 {
  
        transform: translate3d(319px, 198px, 316px) rotate3d(1.6,0.5,-0.8,315deg);
           transition: transform 5643ms ease-in-out;  
  
  }  .cl-362.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8157ms ease-in-out;
  
  }
  .cl-362.reverse {
  
        transform: translate3d(-264px, 317px, -410px) rotate3d(0.6,-0.3,-1.4,-279deg);
  
           transition: transform 3544ms ease-in-out;
  }.cl-363 {
  
        transform: translate3d(374px, 457px, 209px) rotate3d(-0.1,-1.4,0.7,-297deg);
           transition: transform 100ms ease-in-out;  
  
  }  .cl-363.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1880ms ease-in-out;
  
  }
  .cl-363.reverse {
  
        transform: translate3d(-85px, -384px, 428px) rotate3d(0.6,1.4,0.8,-302deg);
  
           transition: transform 9929ms ease-in-out;
  }.cl-364 {
  
        transform: translate3d(-19px, -175px, -341px) rotate3d(1.4,0.7,0.1,-240deg);
           transition: transform 1682ms ease-in-out;  
  
  }  .cl-364.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8307ms ease-in-out;
  
  }
  .cl-364.reverse {
  
        transform: translate3d(-378px, -8px, 285px) rotate3d(-1.5,-1.1,-1.8,-274deg);
  
           transition: transform 6063ms ease-in-out;
  }.cl-365 {
  
        transform: translate3d(348px, -313px, -254px) rotate3d(-0.4,0.9,-1,119deg);
           transition: transform 1865ms ease-in-out;  
  
  }  .cl-365.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4848ms ease-in-out;
  
  }
  .cl-365.reverse {
  
        transform: translate3d(47px, 296px, -15px) rotate3d(1.8,-1.3,1.6,-208deg);
  
           transition: transform 6540ms ease-in-out;
  }.cl-366 {
  
        transform: translate3d(-402px, 455px, -254px) rotate3d(0.6,1.2,-0.9,-216deg);
           transition: transform 4882ms ease-in-out;  
  
  }  .cl-366.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9256ms ease-in-out;
  
  }
  .cl-366.reverse {
  
        transform: translate3d(440px, -275px, -107px) rotate3d(0.7,-0.7,1.3,-232deg);
  
           transition: transform 1539ms ease-in-out;
  }.cl-367 {
  
        transform: translate3d(398px, -180px, -272px) rotate3d(-1.8,-0.2,-1.9,306deg);
           transition: transform 9294ms ease-in-out;  
  
  }  .cl-367.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3608ms ease-in-out;
  
  }
  .cl-367.reverse {
  
        transform: translate3d(-63px, -165px, 158px) rotate3d(-1.5,-1.9,1.3,-239deg);
  
           transition: transform 5009ms ease-in-out;
  }.cl-368 {
  
        transform: translate3d(-316px, -196px, -72px) rotate3d(1.2,-1.2,-0.2,-292deg);
           transition: transform 408ms ease-in-out;  
  
  }  .cl-368.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4252ms ease-in-out;
  
  }
  .cl-368.reverse {
  
        transform: translate3d(-479px, 385px, 319px) rotate3d(0.1,-1.5,0,-194deg);
  
           transition: transform 4190ms ease-in-out;
  }.cl-369 {
  
        transform: translate3d(254px, -201px, 276px) rotate3d(0,1.1,-1,-122deg);
           transition: transform 3710ms ease-in-out;  
  
  }  .cl-369.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3945ms ease-in-out;
  
  }
  .cl-369.reverse {
  
        transform: translate3d(35px, -16px, -299px) rotate3d(1.1,0.5,0.3,-319deg);
  
           transition: transform 8407ms ease-in-out;
  }.cl-370 {
  
        transform: translate3d(-44px, -24px, -404px) rotate3d(0,0.2,-1.6,-148deg);
           transition: transform 490ms ease-in-out;  
  
  }  .cl-370.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5729ms ease-in-out;
  
  }
  .cl-370.reverse {
  
        transform: translate3d(356px, 120px, 302px) rotate3d(-0.9,-1.9,0,-316deg);
  
           transition: transform 1896ms ease-in-out;
  }.cl-371 {
  
        transform: translate3d(463px, -89px, -272px) rotate3d(1.8,0.2,-0.8,238deg);
           transition: transform 2646ms ease-in-out;  
  
  }  .cl-371.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5998ms ease-in-out;
  
  }
  .cl-371.reverse {
  
        transform: translate3d(382px, -413px, -429px) rotate3d(1.3,1.5,-0.5,-296deg);
  
           transition: transform 9546ms ease-in-out;
  }.cl-372 {
  
        transform: translate3d(279px, -479px, -208px) rotate3d(0,0.8,-0.7,62deg);
           transition: transform 10554ms ease-in-out;  
  
  }  .cl-372.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1402ms ease-in-out;
  
  }
  .cl-372.reverse {
  
        transform: translate3d(326px, 169px, -321px) rotate3d(0.7,-0.3,-1.8,9deg);
  
           transition: transform 4785ms ease-in-out;
  }.cl-373 {
  
        transform: translate3d(-241px, -49px, -175px) rotate3d(0,2,-0.7,292deg);
           transition: transform 8835ms ease-in-out;  
  
  }  .cl-373.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8358ms ease-in-out;
  
  }
  .cl-373.reverse {
  
        transform: translate3d(-233px, 133px, 293px) rotate3d(1.8,-1,0.8,-292deg);
  
           transition: transform 3625ms ease-in-out;
  }.cl-374 {
  
        transform: translate3d(-170px, -28px, -364px) rotate3d(-1.5,-1.2,0.7,327deg);
           transition: transform 8030ms ease-in-out;  
  
  }  .cl-374.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8279ms ease-in-out;
  
  }
  .cl-374.reverse {
  
        transform: translate3d(211px, 332px, -45px) rotate3d(0.1,0.6,-0.2,264deg);
  
           transition: transform 3287ms ease-in-out;
  }.cl-375 {
  
        transform: translate3d(33px, 275px, -268px) rotate3d(-0.8,1.4,0,187deg);
           transition: transform 5450ms ease-in-out;  
  
  }  .cl-375.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 989ms ease-in-out;
  
  }
  .cl-375.reverse {
  
        transform: translate3d(-403px, 412px, -143px) rotate3d(1.6,1.4,1.9,172deg);
  
           transition: transform 7244ms ease-in-out;
  }.cl-376 {
  
        transform: translate3d(190px, -298px, 144px) rotate3d(0.2,-0.8,-0.6,-273deg);
           transition: transform 1813ms ease-in-out;  
  
  }  .cl-376.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6735ms ease-in-out;
  
  }
  .cl-376.reverse {
  
        transform: translate3d(499px, -226px, 115px) rotate3d(-0.3,1.9,1.1,354deg);
  
           transition: transform 5771ms ease-in-out;
  }.cl-377 {
  
        transform: translate3d(155px, -152px, 252px) rotate3d(-1.6,0.6,1.2,220deg);
           transition: transform 10603ms ease-in-out;  
  
  }  .cl-377.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5617ms ease-in-out;
  
  }
  .cl-377.reverse {
  
        transform: translate3d(216px, -290px, 57px) rotate3d(-0.9,1.7,1.6,-143deg);
  
           transition: transform 9496ms ease-in-out;
  }.cl-378 {
  
        transform: translate3d(-218px, 328px, -306px) rotate3d(1.4,-0.7,-1.1,-176deg);
           transition: transform 378ms ease-in-out;  
  
  }  .cl-378.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10659ms ease-in-out;
  
  }
  .cl-378.reverse {
  
        transform: translate3d(-315px, -356px, -244px) rotate3d(-0.9,-1.8,-1.6,-185deg);
  
           transition: transform 6607ms ease-in-out;
  }.cl-379 {
  
        transform: translate3d(418px, 91px, -3px) rotate3d(1.4,0.3,-0.8,-109deg);
           transition: transform 9818ms ease-in-out;  
  
  }  .cl-379.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1524ms ease-in-out;
  
  }
  .cl-379.reverse {
  
        transform: translate3d(-307px, -134px, 41px) rotate3d(1.3,-1.5,-1.2,13deg);
  
           transition: transform 6089ms ease-in-out;
  }.cl-380 {
  
        transform: translate3d(-273px, -26px, -152px) rotate3d(-0.6,-0.8,-0.8,267deg);
           transition: transform 11400ms ease-in-out;  
  
  }  .cl-380.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3812ms ease-in-out;
  
  }
  .cl-380.reverse {
  
        transform: translate3d(-37px, -456px, 118px) rotate3d(-0.1,1.4,1.5,159deg);
  
           transition: transform 3174ms ease-in-out;
  }.cl-381 {
  
        transform: translate3d(-61px, -313px, -475px) rotate3d(-1.9,-1.2,1.7,235deg);
           transition: transform 2580ms ease-in-out;  
  
  }  .cl-381.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9143ms ease-in-out;
  
  }
  .cl-381.reverse {
  
        transform: translate3d(-329px, -291px, -270px) rotate3d(-2,-0.9,-1.5,200deg);
  
           transition: transform 2988ms ease-in-out;
  }.cl-382 {
  
        transform: translate3d(468px, -404px, 352px) rotate3d(-1.1,0.7,1.1,159deg);
           transition: transform 1405ms ease-in-out;  
  
  }  .cl-382.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2451ms ease-in-out;
  
  }
  .cl-382.reverse {
  
        transform: translate3d(13px, 49px, -25px) rotate3d(1.2,-1.6,1.6,302deg);
  
           transition: transform 180ms ease-in-out;
  }.cl-383 {
  
        transform: translate3d(425px, -30px, 471px) rotate3d(0.2,-1.3,1.4,-27deg);
           transition: transform 407ms ease-in-out;  
  
  }  .cl-383.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6619ms ease-in-out;
  
  }
  .cl-383.reverse {
  
        transform: translate3d(-419px, -463px, -490px) rotate3d(-0.5,-1.1,-0.3,158deg);
  
           transition: transform 5890ms ease-in-out;
  }.cl-384 {
  
        transform: translate3d(131px, 275px, -224px) rotate3d(-1.2,-0.2,-1.2,-43deg);
           transition: transform 6151ms ease-in-out;  
  
  }  .cl-384.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6774ms ease-in-out;
  
  }
  .cl-384.reverse {
  
        transform: translate3d(292px, -279px, -367px) rotate3d(0,-1.7,-1.3,321deg);
  
           transition: transform 9683ms ease-in-out;
  }.cl-385 {
  
        transform: translate3d(72px, 116px, -31px) rotate3d(-1.7,1.4,1.8,-8deg);
           transition: transform 10177ms ease-in-out;  
  
  }  .cl-385.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3924ms ease-in-out;
  
  }
  .cl-385.reverse {
  
        transform: translate3d(-306px, 490px, -158px) rotate3d(-0.4,0.1,-1.3,186deg);
  
           transition: transform 4953ms ease-in-out;
  }.cl-386 {
  
        transform: translate3d(-309px, -182px, 340px) rotate3d(-1.1,-1.4,-0.7,189deg);
           transition: transform 8081ms ease-in-out;  
  
  }  .cl-386.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 11354ms ease-in-out;
  
  }
  .cl-386.reverse {
  
        transform: translate3d(126px, 346px, 254px) rotate3d(0.3,-1.6,-0.1,-276deg);
  
           transition: transform 9319ms ease-in-out;
  }.cl-387 {
  
        transform: translate3d(-233px, 5px, 229px) rotate3d(-0.6,-1.2,-0.2,-39deg);
           transition: transform 6300ms ease-in-out;  
  
  }  .cl-387.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4850ms ease-in-out;
  
  }
  .cl-387.reverse {
  
        transform: translate3d(424px, 61px, -6px) rotate3d(-0.9,-0.2,-1.4,96deg);
  
           transition: transform 8088ms ease-in-out;
  }.cl-388 {
  
        transform: translate3d(-361px, 408px, 338px) rotate3d(-1,0.9,1.2,263deg);
           transition: transform 8187ms ease-in-out;  
  
  }  .cl-388.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 404ms ease-in-out;
  
  }
  .cl-388.reverse {
  
        transform: translate3d(-266px, -10px, 12px) rotate3d(1.6,-0.3,-1.1,-209deg);
  
           transition: transform 8329ms ease-in-out;
  }.cl-389 {
  
        transform: translate3d(396px, -490px, 18px) rotate3d(-1.8,-1.6,-1.8,250deg);
           transition: transform 10340ms ease-in-out;  
  
  }  .cl-389.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5941ms ease-in-out;
  
  }
  .cl-389.reverse {
  
        transform: translate3d(-463px, -263px, 108px) rotate3d(0.7,-0.4,-0.2,211deg);
  
           transition: transform 892ms ease-in-out;
  }.cl-390 {
  
        transform: translate3d(414px, -97px, -360px) rotate3d(-0.7,0.1,-1.6,-94deg);
           transition: transform 2666ms ease-in-out;  
  
  }  .cl-390.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6420ms ease-in-out;
  
  }
  .cl-390.reverse {
  
        transform: translate3d(313px, -80px, -196px) rotate3d(1.1,-1.8,-1.6,-190deg);
  
           transition: transform 3570ms ease-in-out;
  }.cl-391 {
  
        transform: translate3d(446px, -407px, -368px) rotate3d(1.1,0.5,1.7,54deg);
           transition: transform 2349ms ease-in-out;  
  
  }  .cl-391.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7013ms ease-in-out;
  
  }
  .cl-391.reverse {
  
        transform: translate3d(-247px, 224px, 161px) rotate3d(-1.6,-1.9,1.7,-42deg);
  
           transition: transform 380ms ease-in-out;
  }.cl-392 {
  
        transform: translate3d(424px, -70px, -187px) rotate3d(-1.7,1.9,-1.7,-89deg);
           transition: transform 550ms ease-in-out;  
  
  }  .cl-392.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5665ms ease-in-out;
  
  }
  .cl-392.reverse {
  
        transform: translate3d(-444px, 228px, -258px) rotate3d(1,1.6,0.7,-320deg);
  
           transition: transform 8685ms ease-in-out;
  }.cl-393 {
  
        transform: translate3d(55px, -392px, 132px) rotate3d(0.6,1.7,1.6,286deg);
           transition: transform 4555ms ease-in-out;  
  
  }  .cl-393.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 127ms ease-in-out;
  
  }
  .cl-393.reverse {
  
        transform: translate3d(-13px, -403px, -402px) rotate3d(1.2,2,1.1,306deg);
  
           transition: transform 7075ms ease-in-out;
  }.cl-394 {
  
        transform: translate3d(301px, 168px, -52px) rotate3d(1.3,0.8,-0.9,234deg);
           transition: transform 11627ms ease-in-out;  
  
  }  .cl-394.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3951ms ease-in-out;
  
  }
  .cl-394.reverse {
  
        transform: translate3d(-370px, -184px, 360px) rotate3d(-1.8,1.9,0.4,-147deg);
  
           transition: transform 7866ms ease-in-out;
  }.cl-395 {
  
        transform: translate3d(-495px, -317px, -319px) rotate3d(-1.8,1.4,-1.1,-12deg);
           transition: transform 1233ms ease-in-out;  
  
  }  .cl-395.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2692ms ease-in-out;
  
  }
  .cl-395.reverse {
  
        transform: translate3d(-454px, -138px, -233px) rotate3d(-0.2,0.8,0.4,249deg);
  
           transition: transform 9063ms ease-in-out;
  }.cl-396 {
  
        transform: translate3d(-403px, -44px, -242px) rotate3d(-1.3,-0.9,-1.5,196deg);
           transition: transform 9139ms ease-in-out;  
  
  }  .cl-396.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4003ms ease-in-out;
  
  }
  .cl-396.reverse {
  
        transform: translate3d(-37px, 319px, -131px) rotate3d(-1.5,-1.4,1.2,182deg);
  
           transition: transform 2514ms ease-in-out;
  }.cl-397 {
  
        transform: translate3d(-243px, -186px, -339px) rotate3d(1.9,-1.6,-0.4,126deg);
           transition: transform 1148ms ease-in-out;  
  
  }  .cl-397.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3950ms ease-in-out;
  
  }
  .cl-397.reverse {
  
        transform: translate3d(383px, 0px, 285px) rotate3d(-0.8,-0.4,1.6,104deg);
  
           transition: transform 10666ms ease-in-out;
  }.cl-398 {
  
        transform: translate3d(56px, 485px, 323px) rotate3d(1.5,-0.6,0.1,-332deg);
           transition: transform 7114ms ease-in-out;  
  
  }  .cl-398.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 453ms ease-in-out;
  
  }
  .cl-398.reverse {
  
        transform: translate3d(457px, 467px, -449px) rotate3d(0.5,-0.6,-0.1,54deg);
  
           transition: transform 7597ms ease-in-out;
  }.cl-399 {
  
        transform: translate3d(-108px, -356px, 15px) rotate3d(-0.1,0.4,0.1,49deg);
           transition: transform 11418ms ease-in-out;  
  
  }  .cl-399.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7108ms ease-in-out;
  
  }
  .cl-399.reverse {
  
        transform: translate3d(75px, 346px, -94px) rotate3d(1.3,0.3,-0.7,178deg);
  
           transition: transform 9523ms ease-in-out;
  }.cl-400 {
  
        transform: translate3d(-431px, -305px, -182px) rotate3d(-0.7,1.7,0.8,-189deg);
           transition: transform 10525ms ease-in-out;  
  
  }  .cl-400.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5501ms ease-in-out;
  
  }
  .cl-400.reverse {
  
        transform: translate3d(-411px, -330px, 472px) rotate3d(-1.1,0.9,1.9,344deg);
  
           transition: transform 4935ms ease-in-out;
  }.cl-401 {
  
        transform: translate3d(414px, 191px, 370px) rotate3d(-1.9,-1.8,1,-70deg);
           transition: transform 10055ms ease-in-out;  
  
  }  .cl-401.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1352ms ease-in-out;
  
  }
  .cl-401.reverse {
  
        transform: translate3d(238px, 421px, 285px) rotate3d(-0.5,-1.9,1.6,117deg);
  
           transition: transform 4764ms ease-in-out;
  }.cl-402 {
  
        transform: translate3d(73px, 365px, -34px) rotate3d(0.3,1.1,-1.4,286deg);
           transition: transform 4691ms ease-in-out;  
  
  }  .cl-402.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10208ms ease-in-out;
  
  }
  .cl-402.reverse {
  
        transform: translate3d(115px, 229px, 393px) rotate3d(-0.2,-1.6,1.4,-6deg);
  
           transition: transform 9094ms ease-in-out;
  }.cl-403 {
  
        transform: translate3d(-355px, 176px, 285px) rotate3d(-0.2,-0.4,0.7,87deg);
           transition: transform 8035ms ease-in-out;  
  
  }  .cl-403.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8884ms ease-in-out;
  
  }
  .cl-403.reverse {
  
        transform: translate3d(-184px, -281px, -235px) rotate3d(1,-0.4,1.7,-250deg);
  
           transition: transform 2157ms ease-in-out;
  }.cl-404 {
  
        transform: translate3d(-132px, -42px, 325px) rotate3d(-1.5,1.8,1.3,112deg);
           transition: transform 9652ms ease-in-out;  
  
  }  .cl-404.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2809ms ease-in-out;
  
  }
  .cl-404.reverse {
  
        transform: translate3d(124px, -361px, 291px) rotate3d(1.1,-0.2,-1.7,93deg);
  
           transition: transform 4081ms ease-in-out;
  }.cl-405 {
  
        transform: translate3d(-135px, 18px, 16px) rotate3d(-0.3,-1.5,-0.5,132deg);
           transition: transform 1946ms ease-in-out;  
  
  }  .cl-405.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3434ms ease-in-out;
  
  }
  .cl-405.reverse {
  
        transform: translate3d(-258px, 220px, 279px) rotate3d(-1.2,-0.3,-0.4,-255deg);
  
           transition: transform 1087ms ease-in-out;
  }.cl-406 {
  
        transform: translate3d(12px, 144px, -353px) rotate3d(-1.3,0.1,0.4,-61deg);
           transition: transform 11146ms ease-in-out;  
  
  }  .cl-406.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7893ms ease-in-out;
  
  }
  .cl-406.reverse {
  
        transform: translate3d(-89px, 435px, 377px) rotate3d(-1.3,0.8,0.9,-337deg);
  
           transition: transform 8999ms ease-in-out;
  }.cl-407 {
  
        transform: translate3d(199px, 10px, 295px) rotate3d(-1.7,1.5,1.9,-148deg);
           transition: transform 5782ms ease-in-out;  
  
  }  .cl-407.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10838ms ease-in-out;
  
  }
  .cl-407.reverse {
  
        transform: translate3d(-255px, 16px, -426px) rotate3d(-0.7,1.3,0.2,-149deg);
  
           transition: transform 11796ms ease-in-out;
  }.cl-408 {
  
        transform: translate3d(365px, 50px, 430px) rotate3d(-0.6,1.6,1.2,-81deg);
           transition: transform 9188ms ease-in-out;  
  
  }  .cl-408.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10775ms ease-in-out;
  
  }
  .cl-408.reverse {
  
        transform: translate3d(-432px, -61px, -37px) rotate3d(0.8,0.5,-1.7,-297deg);
  
           transition: transform 11230ms ease-in-out;
  }.cl-409 {
  
        transform: translate3d(19px, -394px, -198px) rotate3d(1.8,-0.1,-0.2,-260deg);
           transition: transform 3198ms ease-in-out;  
  
  }  .cl-409.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7829ms ease-in-out;
  
  }
  .cl-409.reverse {
  
        transform: translate3d(-82px, -237px, -87px) rotate3d(-0.2,-1.5,1.6,-11deg);
  
           transition: transform 9303ms ease-in-out;
  }.cl-410 {
  
        transform: translate3d(393px, 49px, -122px) rotate3d(1.2,1.9,-0.5,126deg);
           transition: transform 3932ms ease-in-out;  
  
  }  .cl-410.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 188ms ease-in-out;
  
  }
  .cl-410.reverse {
  
        transform: translate3d(197px, -349px, -276px) rotate3d(0.1,-1.8,-1.1,-60deg);
  
           transition: transform 6393ms ease-in-out;
  }.cl-411 {
  
        transform: translate3d(-46px, -153px, -365px) rotate3d(0.5,0.7,-1.4,169deg);
           transition: transform 482ms ease-in-out;  
  
  }  .cl-411.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6476ms ease-in-out;
  
  }
  .cl-411.reverse {
  
        transform: translate3d(-227px, -372px, -45px) rotate3d(0.9,1.5,-0.8,-192deg);
  
           transition: transform 8135ms ease-in-out;
  }.cl-412 {
  
        transform: translate3d(-156px, 88px, 262px) rotate3d(1.9,-2,1,-230deg);
           transition: transform 11011ms ease-in-out;  
  
  }  .cl-412.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8027ms ease-in-out;
  
  }
  .cl-412.reverse {
  
        transform: translate3d(211px, -294px, -83px) rotate3d(1.5,0.4,0.5,-51deg);
  
           transition: transform 7369ms ease-in-out;
  }.cl-413 {
  
        transform: translate3d(297px, 91px, 160px) rotate3d(-0.6,-1.1,-0.3,158deg);
           transition: transform 3471ms ease-in-out;  
  
  }  .cl-413.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-413.reverse {
  
        transform: translate3d(67px, 476px, 129px) rotate3d(0.7,-0.5,-0.9,320deg);
  
           transition: transform 2461ms ease-in-out;
  }.cl-414 {
  
        transform: translate3d(-129px, -436px, -140px) rotate3d(0.5,0,1.5,228deg);
           transition: transform 1217ms ease-in-out;  
  
  }  .cl-414.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5622ms ease-in-out;
  
  }
  .cl-414.reverse {
  
        transform: translate3d(448px, -390px, 3px) rotate3d(0.8,0.5,-0.2,281deg);
  
           transition: transform 6904ms ease-in-out;
  }.cl-415 {
  
        transform: translate3d(-408px, 210px, -123px) rotate3d(-0.7,-1.2,1.2,-196deg);
           transition: transform 11838ms ease-in-out;  
  
  }  .cl-415.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8839ms ease-in-out;
  
  }
  .cl-415.reverse {
  
        transform: translate3d(473px, -349px, 208px) rotate3d(0.7,-0.9,1.1,124deg);
  
           transition: transform 7950ms ease-in-out;
  }.cl-416 {
  
        transform: translate3d(-101px, 225px, -1px) rotate3d(-1.4,-0.1,0.4,-20deg);
           transition: transform 10441ms ease-in-out;  
  
  }  .cl-416.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 11339ms ease-in-out;
  
  }
  .cl-416.reverse {
  
        transform: translate3d(131px, -483px, -75px) rotate3d(0.5,-1.2,-1.7,-213deg);
  
           transition: transform 12180ms ease-in-out;
  }.cl-417 {
  
        transform: translate3d(482px, -142px, 43px) rotate3d(-1.9,-1,-1.3,-286deg);
           transition: transform 12459ms ease-in-out;  
  
  }  .cl-417.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6455ms ease-in-out;
  
  }
  .cl-417.reverse {
  
        transform: translate3d(-472px, -400px, 105px) rotate3d(1.4,0.8,-0.7,-61deg);
  
           transition: transform 5575ms ease-in-out;
  }.cl-418 {
  
        transform: translate3d(321px, -443px, -105px) rotate3d(0.1,-1.5,-0.4,122deg);
           transition: transform 5981ms ease-in-out;  
  
  }  .cl-418.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1579ms ease-in-out;
  
  }
  .cl-418.reverse {
  
        transform: translate3d(80px, -498px, -430px) rotate3d(-1.9,-0.8,0.1,-15deg);
  
           transition: transform 6163ms ease-in-out;
  }.cl-419 {
  
        transform: translate3d(-102px, -196px, 391px) rotate3d(-1.5,0.2,-1.5,-139deg);
           transition: transform 10174ms ease-in-out;  
  
  }  .cl-419.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 11002ms ease-in-out;
  
  }
  .cl-419.reverse {
  
        transform: translate3d(266px, -443px, 383px) rotate3d(1.7,1.8,1.8,-176deg);
  
           transition: transform 3620ms ease-in-out;
  }.cl-420 {
  
        transform: translate3d(313px, -420px, 273px) rotate3d(-1.7,1.7,-0.6,-280deg);
           transition: transform 3463ms ease-in-out;  
  
  }  .cl-420.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8901ms ease-in-out;
  
  }
  .cl-420.reverse {
  
        transform: translate3d(234px, -315px, 458px) rotate3d(-1,-0.2,-0.6,322deg);
  
           transition: transform 8197ms ease-in-out;
  }.cl-421 {
  
        transform: translate3d(-232px, 175px, -358px) rotate3d(-0.8,0.9,0.8,-282deg);
           transition: transform 5849ms ease-in-out;  
  
  }  .cl-421.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 11137ms ease-in-out;
  
  }
  .cl-421.reverse {
  
        transform: translate3d(427px, 137px, -425px) rotate3d(1.2,0.3,0.2,-26deg);
  
           transition: transform 9128ms ease-in-out;
  }.cl-422 {
  
        transform: translate3d(-438px, 461px, 290px) rotate3d(-1.6,0.7,2,253deg);
           transition: transform 7708ms ease-in-out;  
  
  }  .cl-422.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2703ms ease-in-out;
  
  }
  .cl-422.reverse {
  
        transform: translate3d(173px, -315px, -258px) rotate3d(1.9,-0.8,-2,-345deg);
  
           transition: transform 2640ms ease-in-out;
  }.cl-423 {
  
        transform: translate3d(156px, -452px, 358px) rotate3d(-1.1,0.5,2,115deg);
           transition: transform 8105ms ease-in-out;  
  
  }  .cl-423.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4512ms ease-in-out;
  
  }
  .cl-423.reverse {
  
        transform: translate3d(-419px, 275px, 239px) rotate3d(-0.8,-0.9,-1.4,-174deg);
  
           transition: transform 404ms ease-in-out;
  }.cl-424 {
  
        transform: translate3d(-257px, 465px, -355px) rotate3d(-0.2,0.3,0.9,-284deg);
           transition: transform 5196ms ease-in-out;  
  
  }  .cl-424.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8512ms ease-in-out;
  
  }
  .cl-424.reverse {
  
        transform: translate3d(-260px, -427px, -216px) rotate3d(-1.3,0.1,0,-203deg);
  
           transition: transform 12313ms ease-in-out;
  }.cl-425 {
  
        transform: translate3d(-208px, 252px, 9px) rotate3d(-0.4,1.7,-0.3,281deg);
           transition: transform 2741ms ease-in-out;  
  
  }  .cl-425.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4987ms ease-in-out;
  
  }
  .cl-425.reverse {
  
        transform: translate3d(-492px, -463px, 420px) rotate3d(-1.9,1.2,-0.6,-1deg);
  
           transition: transform 573ms ease-in-out;
  }.cl-426 {
  
        transform: translate3d(157px, 47px, 317px) rotate3d(-1.8,0.4,-0.9,211deg);
           transition: transform 1739ms ease-in-out;  
  
  }  .cl-426.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 11399ms ease-in-out;
  
  }
  .cl-426.reverse {
  
        transform: translate3d(396px, 297px, 74px) rotate3d(-1.7,-0.5,-1.3,-87deg);
  
           transition: transform 7858ms ease-in-out;
  }.cl-427 {
  
        transform: translate3d(-374px, 70px, 72px) rotate3d(1.9,-0.1,0.2,-246deg);
           transition: transform 5500ms ease-in-out;  
  
  }  .cl-427.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2813ms ease-in-out;
  
  }
  .cl-427.reverse {
  
        transform: translate3d(113px, 30px, 132px) rotate3d(-0.9,-1.1,1.7,354deg);
  
           transition: transform 10778ms ease-in-out;
  }.cl-428 {
  
        transform: translate3d(-36px, 36px, -73px) rotate3d(-0.4,-1.7,0.5,1deg);
           transition: transform 12282ms ease-in-out;  
  
  }  .cl-428.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 11385ms ease-in-out;
  
  }
  .cl-428.reverse {
  
        transform: translate3d(462px, -311px, -267px) rotate3d(-0.9,-0.7,-1,-7deg);
  
           transition: transform 9186ms ease-in-out;
  }.cl-429 {
  
        transform: translate3d(-497px, -426px, -131px) rotate3d(1.5,0.2,0.9,48deg);
           transition: transform 8812ms ease-in-out;  
  
  }  .cl-429.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 241ms ease-in-out;
  
  }
  .cl-429.reverse {
  
        transform: translate3d(473px, -17px, -317px) rotate3d(0.6,-1.3,0.7,64deg);
  
           transition: transform 6815ms ease-in-out;
  }.cl-430 {
  
        transform: translate3d(-76px, -183px, -483px) rotate3d(1.8,-0.8,0.9,103deg);
           transition: transform 1341ms ease-in-out;  
  
  }  .cl-430.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 11388ms ease-in-out;
  
  }
  .cl-430.reverse {
  
        transform: translate3d(-425px, 356px, 53px) rotate3d(1.8,-0.4,-0.6,-309deg);
  
           transition: transform 5317ms ease-in-out;
  }.cl-431 {
  
        transform: translate3d(457px, 280px, -268px) rotate3d(1.2,-1,-0.2,197deg);
           transition: transform 11946ms ease-in-out;  
  
  }  .cl-431.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 11604ms ease-in-out;
  
  }
  .cl-431.reverse {
  
        transform: translate3d(-15px, 181px, -356px) rotate3d(1,-1,-0.6,224deg);
  
           transition: transform 643ms ease-in-out;
  }.cl-432 {
  
        transform: translate3d(108px, -329px, 81px) rotate3d(1.3,-1.5,-1.5,-202deg);
           transition: transform 2181ms ease-in-out;  
  
  }  .cl-432.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4095ms ease-in-out;
  
  }
  .cl-432.reverse {
  
        transform: translate3d(492px, 483px, 493px) rotate3d(1.5,1,0.9,84deg);
  
           transition: transform 10807ms ease-in-out;
  }.cl-433 {
  
        transform: translate3d(-337px, -89px, -476px) rotate3d(1.3,-0.7,1.5,-12deg);
           transition: transform 11192ms ease-in-out;  
  
  }  .cl-433.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1796ms ease-in-out;
  
  }
  .cl-433.reverse {
  
        transform: translate3d(-206px, -490px, 386px) rotate3d(0.5,-0.9,1,-25deg);
  
           transition: transform 9346ms ease-in-out;
  }.cl-434 {
  
        transform: translate3d(-145px, 294px, 49px) rotate3d(-1.9,0.1,-0.9,-199deg);
           transition: transform 11712ms ease-in-out;  
  
  }  .cl-434.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 12653ms ease-in-out;
  
  }
  .cl-434.reverse {
  
        transform: translate3d(198px, 338px, -345px) rotate3d(0.7,-1.3,-1.6,-289deg);
  
           transition: transform 4109ms ease-in-out;
  }.cl-435 {
  
        transform: translate3d(484px, -37px, -316px) rotate3d(-0.1,-1.8,0.9,-231deg);
           transition: transform 4526ms ease-in-out;  
  
  }  .cl-435.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10697ms ease-in-out;
  
  }
  .cl-435.reverse {
  
        transform: translate3d(-85px, 238px, 101px) rotate3d(-1.2,1.6,-0.3,92deg);
  
           transition: transform 4687ms ease-in-out;
  }.cl-436 {
  
        transform: translate3d(-47px, -7px, 164px) rotate3d(-0.8,-1,0.4,261deg);
           transition: transform 9734ms ease-in-out;  
  
  }  .cl-436.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9590ms ease-in-out;
  
  }
  .cl-436.reverse {
  
        transform: translate3d(446px, -56px, 10px) rotate3d(1.8,0.1,-0.7,160deg);
  
           transition: transform 3136ms ease-in-out;
  }.cl-437 {
  
        transform: translate3d(237px, -299px, 169px) rotate3d(-0.9,0.1,0.3,-298deg);
           transition: transform 1829ms ease-in-out;  
  
  }  .cl-437.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1304ms ease-in-out;
  
  }
  .cl-437.reverse {
  
        transform: translate3d(-89px, -454px, -127px) rotate3d(0.6,-1.6,1.3,142deg);
  
           transition: transform 13086ms ease-in-out;
  }.cl-438 {
  
        transform: translate3d(380px, 137px, -138px) rotate3d(0.3,0.9,1.7,214deg);
           transition: transform 5150ms ease-in-out;  
  
  }  .cl-438.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1438ms ease-in-out;
  
  }
  .cl-438.reverse {
  
        transform: translate3d(467px, 102px, -48px) rotate3d(0.9,1.8,1.8,110deg);
  
           transition: transform 6600ms ease-in-out;
  }.cl-439 {
  
        transform: translate3d(39px, 21px, 321px) rotate3d(-1.8,0.4,-1.7,88deg);
           transition: transform 12849ms ease-in-out;  
  
  }  .cl-439.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5943ms ease-in-out;
  
  }
  .cl-439.reverse {
  
        transform: translate3d(228px, -12px, 182px) rotate3d(-0.9,-0.4,0.2,-303deg);
  
           transition: transform 7834ms ease-in-out;
  }.cl-440 {
  
        transform: translate3d(-65px, 323px, 126px) rotate3d(-0.2,0.5,-1.6,-143deg);
           transition: transform 1776ms ease-in-out;  
  
  }  .cl-440.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9966ms ease-in-out;
  
  }
  .cl-440.reverse {
  
        transform: translate3d(227px, -210px, 364px) rotate3d(-0.3,-0.6,-1.4,35deg);
  
           transition: transform 5793ms ease-in-out;
  }.cl-441 {
  
        transform: translate3d(-130px, 496px, -249px) rotate3d(0.1,-0.8,-0.4,-314deg);
           transition: transform 12233ms ease-in-out;  
  
  }  .cl-441.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10152ms ease-in-out;
  
  }
  .cl-441.reverse {
  
        transform: translate3d(333px, 309px, -243px) rotate3d(-0.3,-0.2,0.1,145deg);
  
           transition: transform 13152ms ease-in-out;
  }.cl-442 {
  
        transform: translate3d(-159px, 52px, -312px) rotate3d(-0.6,-0.8,0.6,-81deg);
           transition: transform 9915ms ease-in-out;  
  
  }  .cl-442.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10938ms ease-in-out;
  
  }
  .cl-442.reverse {
  
        transform: translate3d(263px, 75px, -73px) rotate3d(-1.4,-0.9,1.6,-93deg);
  
           transition: transform 10152ms ease-in-out;
  }.cl-443 {
  
        transform: translate3d(-428px, 258px, -397px) rotate3d(1.3,-1.9,-0.3,-305deg);
           transition: transform 1850ms ease-in-out;  
  
  }  .cl-443.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4720ms ease-in-out;
  
  }
  .cl-443.reverse {
  
        transform: translate3d(-199px, 422px, 209px) rotate3d(1.5,-0.5,-0.4,279deg);
  
           transition: transform 6517ms ease-in-out;
  }.cl-444 {
  
        transform: translate3d(186px, -391px, 112px) rotate3d(-2,-1.1,1,-237deg);
           transition: transform 1229ms ease-in-out;  
  
  }  .cl-444.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10638ms ease-in-out;
  
  }
  .cl-444.reverse {
  
        transform: translate3d(-477px, 239px, 44px) rotate3d(0.6,0,1.6,-236deg);
  
           transition: transform 3372ms ease-in-out;
  }.cl-445 {
  
        transform: translate3d(444px, -266px, 456px) rotate3d(-1.8,0,-1.6,-163deg);
           transition: transform 3959ms ease-in-out;  
  
  }  .cl-445.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7530ms ease-in-out;
  
  }
  .cl-445.reverse {
  
        transform: translate3d(-382px, -59px, 450px) rotate3d(-0.6,0,1.7,-323deg);
  
           transition: transform 11715ms ease-in-out;
  }.cl-446 {
  
        transform: translate3d(366px, -83px, 397px) rotate3d(0.7,1,1,-37deg);
           transition: transform 10427ms ease-in-out;  
  
  }  .cl-446.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7130ms ease-in-out;
  
  }
  .cl-446.reverse {
  
        transform: translate3d(481px, -73px, 468px) rotate3d(1.4,-1.7,-0.2,-331deg);
  
           transition: transform 482ms ease-in-out;
  }.cl-447 {
  
        transform: translate3d(-307px, 467px, 389px) rotate3d(1.1,0.2,-1.9,60deg);
           transition: transform 6634ms ease-in-out;  
  
  }  .cl-447.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1389ms ease-in-out;
  
  }
  .cl-447.reverse {
  
        transform: translate3d(82px, 402px, 367px) rotate3d(-1.3,-1.7,-1.3,-268deg);
  
           transition: transform 3344ms ease-in-out;
  }.cl-448 {
  
        transform: translate3d(459px, -302px, -133px) rotate3d(-0.8,-0.4,1.2,225deg);
           transition: transform 4178ms ease-in-out;  
  
  }  .cl-448.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8335ms ease-in-out;
  
  }
  .cl-448.reverse {
  
        transform: translate3d(139px, 189px, 297px) rotate3d(-0.6,-0.6,-1.1,-305deg);
  
           transition: transform 3298ms ease-in-out;
  }.cl-449 {
  
        transform: translate3d(334px, -402px, -355px) rotate3d(0,0.2,-0.3,-322deg);
           transition: transform 9682ms ease-in-out;  
  
  }  .cl-449.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 12295ms ease-in-out;
  
  }
  .cl-449.reverse {
  
        transform: translate3d(367px, 180px, -465px) rotate3d(0.8,0.1,1.5,292deg);
  
           transition: transform 11179ms ease-in-out;
  }.cl-450 {
  
        transform: translate3d(-314px, -220px, 325px) rotate3d(-1.5,0.1,1.5,-130deg);
           transition: transform 6827ms ease-in-out;  
  
  }  .cl-450.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3278ms ease-in-out;
  
  }
  .cl-450.reverse {
  
        transform: translate3d(2px, -29px, 473px) rotate3d(1.7,0.6,0.8,116deg);
  
           transition: transform 10836ms ease-in-out;
  }.cl-451 {
  
        transform: translate3d(138px, -384px, 315px) rotate3d(-1.7,-1.2,0.5,-5deg);
           transition: transform 8699ms ease-in-out;  
  
  }  .cl-451.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9566ms ease-in-out;
  
  }
  .cl-451.reverse {
  
        transform: translate3d(-30px, 209px, 441px) rotate3d(1.9,1.7,0,-268deg);
  
           transition: transform 3928ms ease-in-out;
  }.cl-452 {
  
        transform: translate3d(-184px, -93px, -219px) rotate3d(0.2,-0.2,1.5,-358deg);
           transition: transform 3225ms ease-in-out;  
  
  }  .cl-452.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8858ms ease-in-out;
  
  }
  .cl-452.reverse {
  
        transform: translate3d(-37px, 178px, 76px) rotate3d(-1.2,1.5,-1.5,35deg);
  
           transition: transform 6665ms ease-in-out;
  }.cl-453 {
  
        transform: translate3d(-491px, -338px, -91px) rotate3d(1.5,1.2,-0.5,-213deg);
           transition: transform 935ms ease-in-out;  
  
  }  .cl-453.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7779ms ease-in-out;
  
  }
  .cl-453.reverse {
  
        transform: translate3d(-367px, -106px, 465px) rotate3d(-0.9,-1,1.1,-299deg);
  
           transition: transform 10472ms ease-in-out;
  }.cl-454 {
  
        transform: translate3d(46px, 120px, -34px) rotate3d(0.5,0.8,-0.2,-357deg);
           transition: transform 9407ms ease-in-out;  
  
  }  .cl-454.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5192ms ease-in-out;
  
  }
  .cl-454.reverse {
  
        transform: translate3d(-453px, -58px, -294px) rotate3d(1.1,0.2,0.7,-176deg);
  
           transition: transform 886ms ease-in-out;
  }.cl-455 {
  
        transform: translate3d(-457px, -62px, -291px) rotate3d(-0.2,-1.6,-1.1,-93deg);
           transition: transform 12783ms ease-in-out;  
  
  }  .cl-455.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4022ms ease-in-out;
  
  }
  .cl-455.reverse {
  
        transform: translate3d(14px, -69px, 407px) rotate3d(1.6,-1.3,0.7,-333deg);
  
           transition: transform 10057ms ease-in-out;
  }.cl-456 {
  
        transform: translate3d(423px, 278px, 48px) rotate3d(-1.8,-1.3,0.7,74deg);
           transition: transform 4347ms ease-in-out;  
  
  }  .cl-456.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7349ms ease-in-out;
  
  }
  .cl-456.reverse {
  
        transform: translate3d(-96px, 142px, 186px) rotate3d(0.8,-0.7,-0.6,-263deg);
  
           transition: transform 4553ms ease-in-out;
  }.cl-457 {
  
        transform: translate3d(455px, 427px, -143px) rotate3d(0.3,1,1.7,-181deg);
           transition: transform 8473ms ease-in-out;  
  
  }  .cl-457.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 11689ms ease-in-out;
  
  }
  .cl-457.reverse {
  
        transform: translate3d(372px, -396px, 335px) rotate3d(0,1.7,-0.6,-160deg);
  
           transition: transform 10762ms ease-in-out;
  }.cl-458 {
  
        transform: translate3d(165px, -380px, 243px) rotate3d(0.5,1.3,-1.1,-167deg);
           transition: transform 7817ms ease-in-out;  
  
  }  .cl-458.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5931ms ease-in-out;
  
  }
  .cl-458.reverse {
  
        transform: translate3d(476px, -206px, -447px) rotate3d(-1.4,-0.3,0.1,235deg);
  
           transition: transform 6073ms ease-in-out;
  }.cl-459 {
  
        transform: translate3d(316px, 49px, 144px) rotate3d(0.6,-0.3,-1.6,-157deg);
           transition: transform 681ms ease-in-out;  
  
  }  .cl-459.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10188ms ease-in-out;
  
  }
  .cl-459.reverse {
  
        transform: translate3d(-396px, -142px, 457px) rotate3d(-0.7,0.5,-1.9,-6deg);
  
           transition: transform 9159ms ease-in-out;
  }.cl-460 {
  
        transform: translate3d(220px, -239px, 307px) rotate3d(-1.7,-1.6,-0.8,269deg);
           transition: transform 11902ms ease-in-out;  
  
  }  .cl-460.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7654ms ease-in-out;
  
  }
  .cl-460.reverse {
  
        transform: translate3d(48px, 61px, -479px) rotate3d(1.2,-0.5,-2,-251deg);
  
           transition: transform 3496ms ease-in-out;
  }.cl-461 {
  
        transform: translate3d(35px, 467px, -365px) rotate3d(-0.3,1.6,0.7,-9deg);
           transition: transform 1960ms ease-in-out;  
  
  }  .cl-461.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9889ms ease-in-out;
  
  }
  .cl-461.reverse {
  
        transform: translate3d(-376px, 14px, 444px) rotate3d(-0.5,1.7,1,293deg);
  
           transition: transform 3500ms ease-in-out;
  }.cl-462 {
  
        transform: translate3d(-314px, -363px, -230px) rotate3d(0.7,-0.6,1.6,323deg);
           transition: transform 13637ms ease-in-out;  
  
  }  .cl-462.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5225ms ease-in-out;
  
  }
  .cl-462.reverse {
  
        transform: translate3d(-33px, -61px, 268px) rotate3d(1.2,1.4,-1.7,122deg);
  
           transition: transform 12743ms ease-in-out;
  }.cl-463 {
  
        transform: translate3d(131px, -106px, 309px) rotate3d(0.3,-1,1.9,323deg);
           transition: transform 8484ms ease-in-out;  
  
  }  .cl-463.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 12474ms ease-in-out;
  
  }
  .cl-463.reverse {
  
        transform: translate3d(384px, -311px, 380px) rotate3d(0.6,0,-1.3,289deg);
  
           transition: transform 9273ms ease-in-out;
  }.cl-464 {
  
        transform: translate3d(-313px, -75px, 17px) rotate3d(-1.5,1.2,-1.8,84deg);
           transition: transform 239ms ease-in-out;  
  
  }  .cl-464.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1282ms ease-in-out;
  
  }
  .cl-464.reverse {
  
        transform: translate3d(-281px, -97px, 132px) rotate3d(-0.1,1.9,-2,-22deg);
  
           transition: transform 13210ms ease-in-out;
  }.cl-465 {
  
        transform: translate3d(494px, 369px, 289px) rotate3d(1,1.6,2,-186deg);
           transition: transform 2248ms ease-in-out;  
  
  }  .cl-465.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 568ms ease-in-out;
  
  }
  .cl-465.reverse {
  
        transform: translate3d(491px, -88px, -328px) rotate3d(-1.1,0.6,1.5,343deg);
  
           transition: transform 8820ms ease-in-out;
  }.cl-466 {
  
        transform: translate3d(-277px, 171px, -442px) rotate3d(1,0.1,-0.1,39deg);
           transition: transform 7076ms ease-in-out;  
  
  }  .cl-466.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9620ms ease-in-out;
  
  }
  .cl-466.reverse {
  
        transform: translate3d(-485px, -183px, 367px) rotate3d(0.7,1.7,0.2,327deg);
  
           transition: transform 6376ms ease-in-out;
  }.cl-467 {
  
        transform: translate3d(366px, -309px, 370px) rotate3d(-1.5,0.8,1.2,157deg);
           transition: transform 9441ms ease-in-out;  
  
  }  .cl-467.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10226ms ease-in-out;
  
  }
  .cl-467.reverse {
  
        transform: translate3d(-33px, -54px, 197px) rotate3d(-0.9,-0.9,-1,-142deg);
  
           transition: transform 1644ms ease-in-out;
  }.cl-468 {
  
        transform: translate3d(-110px, -394px, 23px) rotate3d(-0.3,1.1,-1.2,335deg);
           transition: transform 14035ms ease-in-out;  
  
  }  .cl-468.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 13392ms ease-in-out;
  
  }
  .cl-468.reverse {
  
        transform: translate3d(154px, -419px, 379px) rotate3d(-1.3,-1.8,1.7,236deg);
  
           transition: transform 4711ms ease-in-out;
  }.cl-469 {
  
        transform: translate3d(134px, -370px, -266px) rotate3d(1.8,-1.7,0.9,25deg);
           transition: transform 12087ms ease-in-out;  
  
  }  .cl-469.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8967ms ease-in-out;
  
  }
  .cl-469.reverse {
  
        transform: translate3d(403px, -365px, -497px) rotate3d(-0.8,0.5,-1.7,-45deg);
  
           transition: transform 11848ms ease-in-out;
  }.cl-470 {
  
        transform: translate3d(-458px, -306px, -334px) rotate3d(-1.3,0.4,-0.1,-349deg);
           transition: transform 8235ms ease-in-out;  
  
  }  .cl-470.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 11270ms ease-in-out;
  
  }
  .cl-470.reverse {
  
        transform: translate3d(134px, -416px, -78px) rotate3d(-0.9,1.2,-0.4,6deg);
  
           transition: transform 9196ms ease-in-out;
  }.cl-471 {
  
        transform: translate3d(222px, 186px, -474px) rotate3d(1.4,-1.4,1.8,107deg);
           transition: transform 12156ms ease-in-out;  
  
  }  .cl-471.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5526ms ease-in-out;
  
  }
  .cl-471.reverse {
  
        transform: translate3d(466px, 288px, 208px) rotate3d(0.5,1.4,0.9,320deg);
  
           transition: transform 10783ms ease-in-out;
  }.cl-472 {
  
        transform: translate3d(228px, 170px, 496px) rotate3d(0.6,-0.3,-0.3,279deg);
           transition: transform 13330ms ease-in-out;  
  
  }  .cl-472.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2748ms ease-in-out;
  
  }
  .cl-472.reverse {
  
        transform: translate3d(206px, -125px, 495px) rotate3d(0.2,0.4,1.4,-82deg);
  
           transition: transform 100ms ease-in-out;
  }.cl-473 {
  
        transform: translate3d(-181px, 125px, 41px) rotate3d(-1.7,-0.7,-1.5,293deg);
           transition: transform 10906ms ease-in-out;  
  
  }  .cl-473.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1567ms ease-in-out;
  
  }
  .cl-473.reverse {
  
        transform: translate3d(398px, -441px, -242px) rotate3d(-0.2,1.5,-0.9,223deg);
  
           transition: transform 11378ms ease-in-out;
  }.cl-474 {
  
        transform: translate3d(216px, -491px, -45px) rotate3d(-1.3,-1.5,-1.5,93deg);
           transition: transform 8773ms ease-in-out;  
  
  }  .cl-474.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 3545ms ease-in-out;
  
  }
  .cl-474.reverse {
  
        transform: translate3d(-92px, -146px, -244px) rotate3d(-0.1,0.8,-2,122deg);
  
           transition: transform 996ms ease-in-out;
  }.cl-475 {
  
        transform: translate3d(-228px, -241px, 86px) rotate3d(1.1,1.9,-1.3,209deg);
           transition: transform 5066ms ease-in-out;  
  
  }  .cl-475.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 6786ms ease-in-out;
  
  }
  .cl-475.reverse {
  
        transform: translate3d(276px, -475px, -411px) rotate3d(-1.3,0.6,0.8,-267deg);
  
           transition: transform 670ms ease-in-out;
  }.cl-476 {
  
        transform: translate3d(375px, -112px, 404px) rotate3d(1.5,-0.8,1.3,-14deg);
           transition: transform 5837ms ease-in-out;  
  
  }  .cl-476.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5215ms ease-in-out;
  
  }
  .cl-476.reverse {
  
        transform: translate3d(455px, -464px, -195px) rotate3d(0.8,0.9,0.1,-268deg);
  
           transition: transform 10882ms ease-in-out;
  }.cl-477 {
  
        transform: translate3d(203px, 486px, -360px) rotate3d(1.9,1.3,0.7,-295deg);
           transition: transform 3604ms ease-in-out;  
  
  }  .cl-477.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 14087ms ease-in-out;
  
  }
  .cl-477.reverse {
  
        transform: translate3d(-445px, 61px, -493px) rotate3d(-0.6,-1.2,-0.9,-327deg);
  
           transition: transform 8416ms ease-in-out;
  }.cl-478 {
  
        transform: translate3d(-198px, 222px, -208px) rotate3d(1.1,-1.1,-0.2,-233deg);
           transition: transform 6167ms ease-in-out;  
  
  }  .cl-478.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9282ms ease-in-out;
  
  }
  .cl-478.reverse {
  
        transform: translate3d(-475px, 416px, 405px) rotate3d(1,-1.7,0.9,226deg);
  
           transition: transform 13057ms ease-in-out;
  }.cl-479 {
  
        transform: translate3d(212px, 332px, 102px) rotate3d(0.3,-0.7,0.2,129deg);
           transition: transform 7747ms ease-in-out;  
  
  }  .cl-479.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 5776ms ease-in-out;
  
  }
  .cl-479.reverse {
  
        transform: translate3d(477px, 330px, 490px) rotate3d(1.1,-0.2,-1.3,-242deg);
  
           transition: transform 13809ms ease-in-out;
  }.cl-480 {
  
        transform: translate3d(-15px, -288px, -51px) rotate3d(-0.2,1.4,-0.9,-120deg);
           transition: transform 911ms ease-in-out;  
  
  }  .cl-480.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1944ms ease-in-out;
  
  }
  .cl-480.reverse {
  
        transform: translate3d(178px, -116px, -438px) rotate3d(-0.1,-1.5,-1.5,224deg);
  
           transition: transform 7403ms ease-in-out;
  }.cl-481 {
  
        transform: translate3d(-202px, -176px, 387px) rotate3d(-0.1,-1.1,1.9,-60deg);
           transition: transform 11678ms ease-in-out;  
  
  }  .cl-481.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9727ms ease-in-out;
  
  }
  .cl-481.reverse {
  
        transform: translate3d(385px, 346px, -280px) rotate3d(0,-0.8,1.1,292deg);
  
           transition: transform 13226ms ease-in-out;
  }.cl-482 {
  
        transform: translate3d(-55px, -446px, 268px) rotate3d(1.1,0.1,0.3,333deg);
           transition: transform 2435ms ease-in-out;  
  
  }  .cl-482.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7001ms ease-in-out;
  
  }
  .cl-482.reverse {
  
        transform: translate3d(181px, -168px, 360px) rotate3d(1.9,0.4,1.1,310deg);
  
           transition: transform 10729ms ease-in-out;
  }.cl-483 {
  
        transform: translate3d(199px, 101px, -321px) rotate3d(1.7,-0.2,-0.1,-90deg);
           transition: transform 6337ms ease-in-out;  
  
  }  .cl-483.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 4144ms ease-in-out;
  
  }
  .cl-483.reverse {
  
        transform: translate3d(285px, 194px, 416px) rotate3d(0.6,2,-0.4,189deg);
  
           transition: transform 7137ms ease-in-out;
  }.cl-484 {
  
        transform: translate3d(9px, 113px, 428px) rotate3d(0.4,-0.7,1.8,191deg);
           transition: transform 11111ms ease-in-out;  
  
  }  .cl-484.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 10659ms ease-in-out;
  
  }
  .cl-484.reverse {
  
        transform: translate3d(-149px, -477px, -66px) rotate3d(-0.8,-0.8,-0.6,-107deg);
  
           transition: transform 11282ms ease-in-out;
  }.cl-485 {
  
        transform: translate3d(80px, -470px, -352px) rotate3d(-0.5,1.9,1.6,17deg);
           transition: transform 4464ms ease-in-out;  
  
  }  .cl-485.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 760ms ease-in-out;
  
  }
  .cl-485.reverse {
  
        transform: translate3d(-190px, -75px, 486px) rotate3d(1.2,1.6,-1.6,-178deg);
  
           transition: transform 12709ms ease-in-out;
  }.cl-486 {
  
        transform: translate3d(8px, -375px, -44px) rotate3d(1.1,-1.1,-1.1,-256deg);
           transition: transform 12873ms ease-in-out;  
  
  }  .cl-486.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 9569ms ease-in-out;
  
  }
  .cl-486.reverse {
  
        transform: translate3d(-91px, -332px, -10px) rotate3d(1.6,-0.8,-1.4,-335deg);
  
           transition: transform 8031ms ease-in-out;
  }.cl-487 {
  
        transform: translate3d(-454px, 460px, -335px) rotate3d(1.6,-0.8,0.5,-164deg);
           transition: transform 3318ms ease-in-out;  
  
  }  .cl-487.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 12287ms ease-in-out;
  
  }
  .cl-487.reverse {
  
        transform: translate3d(-223px, 261px, 295px) rotate3d(0.8,-0.7,1.4,142deg);
  
           transition: transform 4199ms ease-in-out;
  }.cl-488 {
  
        transform: translate3d(200px, 347px, -370px) rotate3d(-1.2,0.8,-1.1,115deg);
           transition: transform 5415ms ease-in-out;  
  
  }  .cl-488.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2862ms ease-in-out;
  
  }
  .cl-488.reverse {
  
        transform: translate3d(51px, 382px, 479px) rotate3d(1.1,1.9,0.8,54deg);
  
           transition: transform 10532ms ease-in-out;
  }.cl-489 {
  
        transform: translate3d(372px, 34px, -44px) rotate3d(0.5,1.5,-1.3,-122deg);
           transition: transform 4653ms ease-in-out;  
  
  }  .cl-489.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 14593ms ease-in-out;
  
  }
  .cl-489.reverse {
  
        transform: translate3d(276px, 174px, 117px) rotate3d(1.3,1.6,0.4,-113deg);
  
           transition: transform 10687ms ease-in-out;
  }.cl-490 {
  
        transform: translate3d(42px, 288px, 359px) rotate3d(1.1,-1.5,-1.3,-41deg);
           transition: transform 7578ms ease-in-out;  
  
  }  .cl-490.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 272ms ease-in-out;
  
  }
  .cl-490.reverse {
  
        transform: translate3d(-85px, 107px, 6px) rotate3d(1.5,1.9,1.9,205deg);
  
           transition: transform 11322ms ease-in-out;
  }.cl-491 {
  
        transform: translate3d(-486px, -438px, 288px) rotate3d(0.4,1.9,-1.1,-23deg);
           transition: transform 6407ms ease-in-out;  
  
  }  .cl-491.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2399ms ease-in-out;
  
  }
  .cl-491.reverse {
  
        transform: translate3d(113px, 142px, 24px) rotate3d(0.7,-1.6,-0.1,-264deg);
  
           transition: transform 813ms ease-in-out;
  }.cl-492 {
  
        transform: translate3d(-405px, 348px, 265px) rotate3d(1.4,0.1,-0.3,-255deg);
           transition: transform 3241ms ease-in-out;  
  
  }  .cl-492.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 8512ms ease-in-out;
  
  }
  .cl-492.reverse {
  
        transform: translate3d(2px, 55px, -19px) rotate3d(0.6,0.6,0.5,44deg);
  
           transition: transform 7484ms ease-in-out;
  }.cl-493 {
  
        transform: translate3d(-188px, 34px, 471px) rotate3d(-0.4,0.4,-1.1,-309deg);
           transition: transform 2146ms ease-in-out;  
  
  }  .cl-493.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 12824ms ease-in-out;
  
  }
  .cl-493.reverse {
  
        transform: translate3d(-27px, -203px, 261px) rotate3d(-0.9,-0.9,0.6,-40deg);
  
           transition: transform 2189ms ease-in-out;
  }.cl-494 {
  
        transform: translate3d(-130px, 460px, 41px) rotate3d(-1.3,-0.4,1.7,-262deg);
           transition: transform 1322ms ease-in-out;  
  
  }  .cl-494.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1795ms ease-in-out;
  
  }
  .cl-494.reverse {
  
        transform: translate3d(-481px, -425px, -408px) rotate3d(-1.5,1.6,0,171deg);
  
           transition: transform 10284ms ease-in-out;
  }.cl-495 {
  
        transform: translate3d(-426px, -138px, -450px) rotate3d(-1.7,0.5,-1.2,126deg);
           transition: transform 10287ms ease-in-out;  
  
  }  .cl-495.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 100ms ease-in-out;
  
  }
  .cl-495.reverse {
  
        transform: translate3d(-244px, 419px, -494px) rotate3d(-1.8,-0.6,1.1,-23deg);
  
           transition: transform 13896ms ease-in-out;
  }.cl-496 {
  
        transform: translate3d(82px, -357px, 246px) rotate3d(0.2,-0.4,1.7,-286deg);
           transition: transform 2931ms ease-in-out;  
  
  }  .cl-496.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 7612ms ease-in-out;
  
  }
  .cl-496.reverse {
  
        transform: translate3d(-457px, 382px, 34px) rotate3d(0.3,1,1.7,-306deg);
  
           transition: transform 835ms ease-in-out;
  }.cl-497 {
  
        transform: translate3d(-382px, 24px, 482px) rotate3d(-1.5,1.5,-1.5,265deg);
           transition: transform 6152ms ease-in-out;  
  
  }  .cl-497.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 1758ms ease-in-out;
  
  }
  .cl-497.reverse {
  
        transform: translate3d(85px, 420px, -343px) rotate3d(-1,1.2,0.1,290deg);
  
           transition: transform 633ms ease-in-out;
  }.cl-498 {
  
        transform: translate3d(-286px, -468px, 233px) rotate3d(0.3,-1.1,1.3,-19deg);
           transition: transform 449ms ease-in-out;  
  
  }  .cl-498.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 2071ms ease-in-out;
  
  }
  .cl-498.reverse {
  
        transform: translate3d(284px, 188px, 396px) rotate3d(1.8,1.1,0.9,-102deg);
  
           transition: transform 3998ms ease-in-out;
  }.cl-499 {
  
        transform: translate3d(-231px, 151px, 397px) rotate3d(-1.2,0.9,-0.3,-291deg);
           transition: transform 10580ms ease-in-out;  
  
  }  .cl-499.initial {
  
      transform: translate3d(0px, 0px, 0) rotate3d(0,0,0,0);
           transition: transform 12302ms ease-in-out;
  
  }
  .cl-499.reverse {
  
        transform: translate3d(366px, 376px, -241px) rotate3d(-1.1,1.7,-0.9,15deg);
  
           transition: transform 14715ms ease-in-out;
  }